import { NgModule, ModuleWithProviders } from '@angular/core';
import { GlobalsService } from './globals.service';
export {
    GlobalsService
}

@NgModule()
export class ServicesModule {
    static forRoot():ModuleWithProviders{
        return {
            ngModule:ServicesModule,
            providers:[GlobalsService]
        }
    }
}