import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
  selector: 'app-severe-weather',
  templateUrl: './severe-weather.component.html',
  styleUrls: ['./severe-weather.component.scss']
})
export class SevereWeatherComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData()
    }

    getData(){
        const config={
            about_type:'severe-weather'
        };
        this.dataService.getBaseData('about',config).then(resp => {
            if(resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }
}
