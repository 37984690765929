import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {DataService} from "../../../@core/data/data.service";
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Headers, Http} from "@angular/http";
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from "@angular/router";
import {DatePipe} from "@angular/common";

export const EMAILPATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';

@Component({
    selector: 'app-registered-league',
    templateUrl: './registered-league.component.html',
    styleUrls: ['./registered-league.component.scss'],
    providers: [DatePipe]
})


export class RegisteredLeagueComponent implements OnInit, OnDestroy {
    showQR: boolean = false;
    showFrom: boolean = false;
    modalRef: BsModalRef;
    info: any = {
        registration_fee: null,
        city_league_division: null,
        city_league_division_2nd: null,
        team_program: null,
        tykes_beginners_training: null,
        super_skills_training: null,
        //elite_edgework_training: null,
    };
    leagueInfo = '';
    order_id = '';
    loading: boolean = true;
    isDisabled: boolean = false;
    isDisabledStatus: any;
    leagueForm: any;
    is_join_league: any;
    orderStatus: any = '';
    order_sn: any = '';
    Interval: any = '';
    showIntervalPay: boolean = false;
    alipaylink: any = '';
    safeSrc: SafeResourceUrl;
    againRegister: boolean = false;
    cityLeagueDivisionValue: any = '';
    cityLeagueDivision2ndValue: any = '';
    registrationFeeValue: any = '';
    teamProgramValue: any = '';
    tykesBeginnersTrainingValue: any = '';
    superSkillsTrainingValue: any = '';
    //eliteEdgeworkTrainingValue: any = '';
    positionValue: any = '';
    shootValue: any = '';
    jerseysizeValue: any = '';
    studyingGradeValue: any = '';
    nationalityValue: any = '';
    playerInfo = [];

    show_player_register: boolean = false;

    showpaySuccessCode = false;

    city_league_division = [];
    city_league_division_2nd = [];
    registration_fee = [];
    team_program = [];
    tykes_beginners_training = [];
    super_skills_training = [];
    ///elite_edgework_training = [];
    choiceId = [];
    otherData = [];
    position_played = [];
    shoot = [];
    jerseysize = [];
    studying_grade = [];
    nationality = [];
    allpirce = 0;
    remarks = '';

    player_name = '';

    @ViewChild('template', {static: true}) templateView: ElementRef;
    @ViewChild('template_wechat', {static: true}) template_wechat: ElementRef;


    constructor(private modalService: BsModalService,
                public dataService: DataService,
                private formBuilder: FormBuilder,
                private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private httpClient: HttpClient,
                private http: Http,
                private datePipe: DatePipe) {
    }

    ngOnInit() {

        if (localStorage.getItem("user") == null) {
            this.router.navigate(['/login']);
            return;
        }
        this.getRegisteredLeague();


    }

    openModal(template) {
        this.modalRef = this.modalService.show(template);
    }

    submitInfo() {
        // this.showQR = true;
        // window.scrollTo(0, 300)
    }

    getRegisteredLeague() {
        const config = {
            "table": "setting",
            // "select":"player_name",
            "where": {},
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                resp.data.forEach((val) => {
                    if (val.setting_key === 'show_player_register') {
                        this.show_player_register = val.setting_value === 'Y';
                    }
                });
                //console.log('show_player_register',this.show_player_register);
                if (this.show_player_register) {
                    this.getSelect('city_league_division');
                    this.getSelect('city_league_division', 1);
                    this.getSelect('team_program');
                    this.getSelect('tykes_beginners_training');
                    this.getSelect('super_skills_training');
                   // this.getSelect('elite_edgework_training');
                    this.getPlayerDataSelect('position_played');
                    this.getPlayerDataSelect('shoot');
                    this.getPlayerDataSelect('jerseysize');
                    this.getPlayerDataSelect('studying_grade');
                    this.getPlayerDataSelect('nationality');
                }
        this.getLeague();
   
            }
        })
    }

    /**
     * thisIsFunction
     * 获取今年联赛资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    getLeague() {
        this.loading = true;
  //console.log("getLeague");
        const config = {
            "table": "league",
            "where": {"league_is_disabled": "N", "league_type": "city_league"},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                resp.data.forEach((val) => {
                    //console.log("getLeaguegetCommonData");
                    // const starttime = val.league_starttime.slice(0, 10);
                    // const year = new Date(starttime).getFullYear();
                    const endtime = val.league_endtime.slice(0, 10);
                    const year = new Date(endtime).getFullYear();
                    const thisYear = new Date().getFullYear();
                    if (year >= thisYear) {
                        this.leagueInfo = val;
                        //console.log("getLeaguegetCommonData::", this.show_player_register);
                        if (this.show_player_register) {
                            this.getpayInfo();
                        } else {
                            this.showFrom = false
                        }
                    }
                });
                this.loading = false;
            }
        })
    }

    /**
     * thisIsFunction
     * 获取今年联赛资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    getpayInfo() {
        this.loading = true;
      //  console.log("getpayInfo");
        let userData = localStorage.getItem("user");

        if (localStorage.getItem("user") == null) {
            this.router.navigate(['/login']);
            return;
        }
        userData = JSON.parse(userData);
        const config = {
            type: 'league',
            league_id: this.leagueInfo['league_id'],
            player_id: userData['player_id'],
        };
        this.dataService.postBaseData('Pay/checkout', config).then(resp => {

            if (resp != undefined) {
                if (resp.code === 200) {
                    if (!resp.data.is_join_league) {
                        this.registration_fee.push({
                            'name': '$' + resp.data.amount,
                            'id': resp.data.league_id,
                            'price': resp.data.amount
                        });
                        const returnArray = {
                            'name': '$' + resp.data.amount,
                            'id': resp.data.league_id,
                            'price': resp.data.amount
                        };
                        this.getRegistrationFeeValue(returnArray)

                    } else {
                        this.is_join_league = '1';
                    }
                    this.getPlayerOrderList();

                    this.loading = false;

                }
            }
        })
    }

    /**
     * thisIsFunction
     * 注册联赛
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    onSubmit(): void {
        this.leagueForm.value['type'] = 'league';
        this.leagueForm.value['league_id'] = this.leagueInfo['league_id'];
        this.leagueForm.value['player_id'] = this.playerInfo['player_id'];
        this.leagueForm.value['registration_fee'] = this.registrationFeeValue;
        this.leagueForm.value['data_of_birth'] = this.datePipe.transform(this.leagueForm.value['data_of_birth'], 'yyyy-MM-dd HH:mm:ss');

        const value = JSON.stringify(this.leagueForm.value);

        console.log("sssss",this.cityLeagueDivisionValue);


        if (!this.cityLeagueDivisionValue && !this.tykesBeginnersTrainingValue && !this.superSkillsTrainingValue) {
            alert('Please select - "City League Division" or "Tykes Beginners Training" or "Elite Skills Training"');
            return;
        }
        if (!this.cityLeagueDivisionValue && (this.teamProgramValue || this.cityLeagueDivision2ndValue)) {
            alert('Please select - "City League Division" before adding "City League - 2nd/Play Up Division (Optional) " or "HK Typhoons Selects Team Program"');
            return;
        }


        if (this.choiceId.length == 0) {
            alert('Total amount cannot be 0');
            return;
        }
        if (!this.leagueForm.valid) {
            alert('Please complete the information');
            this.openDirtyControl(this.leagueForm);
            return;
        }
        this.remarks = this.leagueForm.value.remarks;
        this.dataService.postBaseData('Pay/addPlayerOrder', value).then(resp => {
            if (resp.code === 200) {
                this.order_sn = resp.data.order_sn;
                this.order_id = resp.data.order_id;
                this.getProfile();
                this.dyncOrder();
                window.scrollTo(0, 0);
                
this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("/paypal/?pay=league&order_sn=" + this.order_sn);

            }
        })
    }

    openDirtyControl(data) {
        for (const i in data.controls) {
            this.leagueForm.controls[i].markAsDirty();
            this.leagueForm.controls[i].updateValueAndValidity();
        }
    }


    /**
     * thisIsFunction
     * 添加订单并跳转到paypal,alipay相关页面进行支付
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    payment(status) {
        this.isDisabled = true;
        this.isDisabledStatus = status;
        let is_mobile = this.isSmartDevice();
        const config = {
            type: 'league',
            league_id: this.leagueInfo['league_id'],
            player_id: this.playerInfo['player_id'],
            order_id: this.order_id,
            order_sn: this.order_sn,
            status: status,
            is_mobile: is_mobile,
        };
        this.dataService.postBaseData('Pay/payment', config).then(resp => {
            if (resp != undefined) {
                if (resp.code === 200) {
                    if (resp.data.pay_url) {
                        this.order_sn = resp.data.order_sn;
                        // clearInterval();

                        if (status == 'alipay') {
                            if (!is_mobile) {
                                this.alipaylink = this.sanitizer.bypassSecurityTrustResourceUrl(resp.data.pay_url);
                                this.openModal(this.templateView);
                                this.isDisabled = false;
                                return;
                            }
                        }
                        if (status == 'wechat') {
                            if (!is_mobile) {
                                this.alipaylink = this.sanitizer.bypassSecurityTrustResourceUrl(resp.data.pay_url);
                                this.openModal(this.template_wechat);
                                this.isDisabled = false;
                                return;
                            }
                        }
                        window.location.href = resp.data.pay_url;
                    }
                }
            }
            this.isDisabled = false;
        })

    }


    /**
     * thisIsFunction
     * 获得 city_league_division list
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date        2020-04-09
     */
    getSelect(tableName, type = 0) {

        this.loading = true;

        let where = {};

        if (type != 0) {
            where = {"city_league_division_type": "both"};
        }


        const config = {
            "table": tableName,
            "where": where,
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            var returnData = [];
            if (resp.code === 200) {
                resp.data.forEach((val, key) => {

                    if (tableName == 'city_league_division' && type == 0) {
                        returnData.push({
                            'name': val[tableName + "_name"] + " ... $" + val[tableName + "_price"],
                            'id': val[tableName + "_id"],
                            'price': val[tableName + "_price"],
                            'tip': '1'
                        });
                    } else {


                        returnData.push({
                            'name': val[tableName + "_name"] + " ... $" + val[tableName + "_price"],
                            'id': val[tableName + "_id"],
                            'price': val[tableName + "_price"]
                        });
                    }

                })

                if (type == 0) {

                    this[tableName] = returnData;

                } else {
                    // returnData.splice(0,1);
                    this[tableName + '_2nd'] = returnData;

                }
                this.loading = false;


            }

        })
    }

    getPlayerDataSelect(tableName) {

        this.loading = true;


        const config = {
            "table": tableName,
            "where": {},
            "page": "1",
            "perPage": "10000"
        };

        this.dataService.getCommonData(config).then(resp => {


            let returnData = [];

            if (resp.code === 200) {
                resp.data.forEach((val, key) => {
                    returnData.push({'name': val[tableName + '_name'], 'id': val[tableName + '_id']});
                })
                this[tableName] = returnData;
                this.loading = false;

            }
        })
    }

    getTeamProgramValue(event) {
        this.teamProgramValue = event;
        this.countTotal();

    }

    getcityLeagueDivisionValue(event) {
        this.cityLeagueDivisionValue = event;

        // let config = [];
        // this.city_league_division.forEach((val, key) => {

        //     if (val.id == this.cityLeagueDivisionValue.id && val.tip == 1) {
        //         val.disabled = true;
        //         config.push(val);
        //     } else {
        //         val.disabled = false;
        //         config.push(val);
        //     }
        // })
        // this.city_league_division = [];
        // this.city_league_division = config;
        // this.city_league_division_2nd = [];
        // this.city_league_division_2nd = config;
        this.countTotal();

    }

    getcityLeagueDivision2ndValue(event) {

        this.cityLeagueDivision2ndValue = event;
        // let config = [];

        // let values = '';
        // if (this.cityLeagueDivisionValue.id == null) {
        //     values = this.cityLeagueDivision2ndValue.id;
        // } else {
        //     values = this.cityLeagueDivisionValue.id;
        // }
        // this.city_league_division.forEach((val, key) => {
        //     if (val.id == values && val.tip == 1) {
        //         val.disabled = true;
        //         config.push(val);
        //     } else {
        //         val.disabled = false;
        //         config.push(val);
        //     }
        // })
        // this.city_league_division = [];
        // this.city_league_division = config;
        // this.city_league_division_2nd = [];
        // this.city_league_division_2nd = config;

        this.countTotal();

    }

    gettykesBeginnersTrainingValue(event) {
        this.tykesBeginnersTrainingValue = event;
        this.countTotal();

    }

    getsuperSkillsTrainingValue(event) {
        this.superSkillsTrainingValue = event;
        this.countTotal();

    }

    // geteliteEdgeworkTrainingValue(event) {
    //     this.eliteEdgeworkTrainingValue = event;
    //     this.countTotal();

    // }

    getRegistrationFeeValue(event) {
        this.registrationFeeValue = event;
        this.countTotal();

    }

    getNationalityValue(event) {

        if (event != null) {

            this.nationalityValue = this.arrayLookup(this.nationality, 'id', event);


        }

    }

    getStudyingGradeValue(event) {

        if (event != null) {

            this.studyingGradeValue = this.arrayLookup(this.studying_grade, 'id', event);

        }
    }

    getJerseysizeValue(event) {

        if (event != null) {
            this.jerseysizeValue = this.arrayLookup(this.jerseysize, 'id', event);

        }

    }

    getShootValue(event) {
        if (event != null) {
            // this.shootValue = event;
            this.shootValue = this.arrayLookup(this.shoot, 'id', event);


        }

    }

    getPositionPlayedValue(event) {
        if (event != null) {
            // this.positionValue = event;
            this.positionValue = this.arrayLookup(this.position_played, 'id', event);

        }
    }


    countTotal() {

        let choiceId = [];
        let count = 0;
        if (this.teamProgramValue) {
            choiceId.push({'team_program_id': this.teamProgramValue.id});
            count = count + Number(this.teamProgramValue.price);
        }

        if (this.cityLeagueDivisionValue) {
            choiceId.push({'city_league_division_id': this.cityLeagueDivisionValue.id});
            count = count + Number(this.cityLeagueDivisionValue.price);
        }
        if (this.cityLeagueDivision2ndValue) {
            choiceId.push({'city_league_division_id_2nd': this.cityLeagueDivision2ndValue.id});
            count = count + Number(this.cityLeagueDivision2ndValue.price);
        }
        if (this.tykesBeginnersTrainingValue) {
            choiceId.push({'tykes_beginners_training_id': this.tykesBeginnersTrainingValue.id});
            count = count + Number(this.tykesBeginnersTrainingValue.price);
        }
        if (this.superSkillsTrainingValue) {
            choiceId.push({'super_skills_training_id': this.superSkillsTrainingValue.id});
            count = count + Number(this.superSkillsTrainingValue.price);
        }
        // if (this.eliteEdgeworkTrainingValue) {
        //     choiceId.push({'elite_edgework_training_id': this.eliteEdgeworkTrainingValue.id});
        //     count = count + Number(this.eliteEdgeworkTrainingValue.price);
        // }
        if (this.registrationFeeValue) {
            choiceId.push({'league_id': this.registrationFeeValue.id});
            count = count + Number(this.registrationFeeValue.price);
        }
        this.choiceId = choiceId;
        this.allpirce = count;
    }

    /**
     * thisIsFunction
     * 查询订单接口
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    getOrderStatus() {
        const config = {
            order_id: this.order_id
        };
        if (this.order_id == undefined) {
            return;
        }
        this.dataService.getBaseData('Order/getOrderStatusold', config).then(resp => {
            if (resp != undefined) {
                if (resp.code === 200) {
                    this.orderStatus = resp.data.order_status;
                    if (resp.data.order_status != 'pending') {
                        this.showpaySuccessCode = true;
                        if (this.Interval) {
                            clearInterval(this.Interval);
                        }
                        this.router.navigate(['/personal/register']);
                    }
                } else {
                    this.router.navigate(['/home']);
                }
            } else {
                this.router.navigate(['/home']);
            }
        })
    }

    /**
     * thisIsFunction
     * 动态获取订单数据
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    dyncOrder() {
        this.getOrderStatus();
        this.Interval = setInterval((val) => {
            this.getOrderStatus();
        }, 5000)
    }

    // //
    ngOnDestroy() {
        if (this.Interval) {
            clearInterval(this.Interval);
        }
    }

    /**
     * thisIsFunction
     * 动态获取订单数据
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    getPlayerOrderList() {
       // console.log('getPlayerOrderList');
        this.loading = true;
        const config = {
            player_order_type: 'league',
            player_order_status: 'pending',
            // league_id: this.leagueInfo['league_id'],
        };
        this.dataService.getBaseData('player/order', config).then(resp => {
            if (resp != undefined) {
         //        console.log('resp',resp);
                if (resp.code === 200 && resp.data.length > 0) {
                    this.order_sn = resp.data[0].player_order_sn;
                    this.order_id = resp.data[0].player_order_id;
                    this.remarks = resp.data[0].player_order_remark;

                    this.player_name = resp.data[0].player_order_player_name;

                    //console.log('this.remarks',this.remarks);
                    this.showQR = true;
                    this.dyncOrder();



this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("/paypal/?pay=league&order_sn=" + this.order_sn);





                    if (resp.data[0].z_order_options.length > 0) {

                        let z_order_optionData = resp.data[0].z_order_options;


                        z_order_optionData.forEach((val) => {

                            // if (val.z_order_options_key == "elite_edgework_training_id") {

                            //     let returnArray = this.arrayLookup(this.elite_edgework_training, 'id', val.z_order_options_value);
                            //     this.geteliteEdgeworkTrainingValue(returnArray);
                            // }
                            if (val.z_order_options_key == "city_league_division_id") {
                                let returnArray = this.arrayLookup(this.city_league_division, 'id', val.z_order_options_value);
                                this.getcityLeagueDivisionValue(returnArray);
                            }
                            if (val.z_order_options_key == "city_league_division_id_2nd") {
                                let returnArray = this.arrayLookup(this.city_league_division_2nd, 'id', val.z_order_options_value);
                                this.getcityLeagueDivision2ndValue(returnArray);
                            }

                            if (val.z_order_options_key == "team_program_id") {
                                let returnArray = this.arrayLookup(this.team_program, 'id', val.z_order_options_value);
                                this.getTeamProgramValue(returnArray);
                            }
                            if (val.z_order_options_key == "super_skills_training_id") {
                                let returnArray = this.arrayLookup(this.super_skills_training, 'id', val.z_order_options_value);
                                this.getsuperSkillsTrainingValue(returnArray);
                            }

                            if (val.z_order_options_key == "tykes_beginners_training_id") {
                                let returnArray = this.arrayLookup(this.tykes_beginners_training, 'id', val.z_order_options_value);
                                this.gettykesBeginnersTrainingValue(returnArray);
                            }
                            if (val.z_order_options_key == "league_id") {
                                let returnArray = this.arrayLookup(this.registration_fee, 'id', val.z_order_options_value);
                                this.getRegistrationFeeValue(returnArray);
                            }

                        });
                        this.loading = false;
                        this.unregistered(1);
                    }
                } else {
                    this.unregistered();

                }
            }
        })
    }


    isSmartDevice() {
        var userAgentInfo = navigator.userAgent;
        var mobileAgents = ["Android", "iPhone", "iPad", "iPod", "Silk", "BlackBerry", "Opera Mini", "IEMobile"];
        var flag = false;
        //根据userAgent判断是否是手机
        for (var v = 0; v < mobileAgents.length; v++) {
            if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                flag = true;
                break;
            }
        }
        return flag;
    }


    arrayLookup(data, key, value) {


        var targetValue = "";
        for (var i = 0; i < data.length; i++) {

            if (parseInt(data[i][key]) == parseInt(value)) {
                return data[i];
            }
        }

    }

    /**
     * thisIsFunction
     * 未注册状态下，关于本地存储数据的相关判断
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    unregistered(type?) {
        this.loading = true;

        let fee_value = '';
        if (this.is_join_league) {
            fee_value = this.is_join_league;
        }

        let userData = localStorage.getItem("user");
        if (userData == null) {
            return;
        }

        this.playerInfo = JSON.parse(userData);

        this.getNationalityValue(this.playerInfo['player_nationality_id']);
        this.getStudyingGradeValue(this.playerInfo['player_studying_grade_id']);
        this.getJerseysizeValue(this.playerInfo['player_jerseysize_id']);
        this.getShootValue(this.playerInfo['player_shoot_id']);
        this.getPositionPlayedValue(this.playerInfo['player_position_id']);

        if (this.playerInfo['player_birth'] == '-0001-11-30') {
            this.playerInfo['player_birth'] = '';
        }

        if(!this.order_id){
            this.player_name = this.playerInfo['player_first_name']+''+this.playerInfo['player_last_name'];
        }

        this.leagueForm = new FormGroup({
            // last_name: new FormControl(this.playerInfo['player_last_name'], [Validators.required]),
            // fisrt_name: new FormControl(this.playerInfo['player_first_name'], [Validators.required]),
            player_name: new FormControl(this.player_name,[Validators.required]),
            data_of_birth: new FormControl(this.playerInfo['player_birth'], [Validators.required]),
            gender: new FormControl(this.playerInfo['player_gender'], [Validators.required]),
            height: new FormControl(this.playerInfo['player_height'], [Validators.required]),
            weight: new FormControl(this.playerInfo['player_weight'], [Validators.required]),
            position_played: new FormControl(this.playerInfo['player_position_id'], [Validators.required]),
            shoot: new FormControl(this.playerInfo['player_shoot_id'], [Validators.required]),
            jerseysize: new FormControl(this.playerInfo['player_jerseysize_id'], [Validators.required]),
            studying_grade: new FormControl(this.playerInfo['player_studying_grade_id'], [Validators.required]),
            attending_school: new FormControl(this.playerInfo['player_attending_school'], [Validators.required]),
            nationality: new FormControl(this.playerInfo['player_nationality_id'], [Validators.required]),
            email: new FormControl(this.playerInfo['player_email'], [Validators.required, Validators.pattern(EMAILPATTERN)]),
            player_maillng_address: new FormControl(this.playerInfo['player_maillng_address'], [Validators.required]),
            mother_name: new FormControl(this.playerInfo['mother_name'], [Validators.required]),
            mother_contact: new FormControl(this.playerInfo['mother_contact'], [Validators.required]),
            motherEmail: new FormControl(this.playerInfo['mother_email'], [Validators.required]),
            father_name: new FormControl(this.playerInfo['father_name'], [Validators.required]),
            father_contact: new FormControl(this.playerInfo['father_contact'], [Validators.required]),
            fatherEmail: new FormControl(this.playerInfo['father_email'], [Validators.required]),
            cityLeagueDivision: new FormControl(),
            cityLeagueDivision2nd: new FormControl(),
            registration_fee: new FormControl(fee_value),
            teamProgram: new FormControl(),
            tykesBeginnersTraining: new FormControl(),
            superSkillsTraining: new FormControl(),
            eliteEdgeworkTraining: new FormControl(),
            accpet_chose: new FormControl(null, [Validators.required]),
            remarks: new FormControl(this.remarks),
            guardianName: new FormControl(this.playerInfo['player_guardian_name'], [Validators.required]),
        });
        this.loading = false;
        this.countTotal();

    }

    /**
     * thisIsFunction
     * 获取个人资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    getProfile() {
        this.loading = true;

        this.dataService.getBaseData('player/getProfile').then(resp => {
            if (resp != undefined) {
                if (resp.code === 200) {

                    localStorage.removeItem("user");
                    localStorage.setItem("user", JSON.stringify(resp.data));
                    this.playerInfo = resp.data;
                    this.showQR = true;

                    this.loading = false;
                    //console.log('loading',this.loading);

                }
            }
        })
    }

    onPhoneup(event) {
        let input = event.target;
        let value = input.value.replace(/[^0-9-]/ig, '');
        var arr = value.split('');
        input.value = arr.join('');
        // 输完11位之后
        let phone = event.target.value.replace(/\s+/g, '');
    }

    onPhonepress(event) {

        // 判断是否为数字
        let inputStr = String.fromCharCode(event.keyCode);
        let re = /^[0-9-]*$/; //判断字符串是否为数字
        if (!re.test(inputStr)) {
            return false;
        }
    }

}
