import {Component, OnInit} from '@angular/core';
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';
import {DataService} from "../../../@core/data/data.service";
import {HttpProvider} from "../../../@core/utils/http.provider";
import {Router, ActivatedRoute} from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import {interval} from 'rxjs';
import {take} from 'rxjs/operators';
import {GlobalsService} from "../../../@core/data/globals.service";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    providers:[DatePipe]
})
export class RegistrationComponent implements OnInit {
    setForm: FormGroup;
    player_logintype = 'email';
    phoneAreaList = [];
    player_phone_area = '';
    player_facebook = '';
    player_google = '';
    position = [];
    shoot = [];
    jerseysize = [];
    studyingGrade = [];
    nationality = [];
    showCode: boolean = false;
    paracontEmail = 'Code';
    paracontPhone = 'Code';
    disabledEmail = false;
    disabledPhone = false;

    constructor(public dataService: DataService,
                public httpProvider: HttpProvider,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private toastrService: ToastrService,
                public globals: GlobalsService,
                public datePipe: DatePipe) {

        let type = this.activatedRoute.snapshot.queryParams.type;
        let id = this.activatedRoute.snapshot.queryParams.id;
        if (type == 'google') {
            this.player_google = id;
        } else {
            this.player_facebook = id;
        }
    }

    ngOnInit() {
        this.setForm = new FormGroup({
            player_email: new FormControl('', []),
            player_phone: new FormControl('', []),
            code_verification_code: new FormControl('', [Validators.required]),
            player_password: new FormControl('', [Validators.required]),
            player_comfirmpassword: new FormControl('', [Validators.required]),
            player_first_name: new FormControl('', [Validators.required]),
            player_last_name: new FormControl('', [Validators.required]),
            data_of_birth: new FormControl('', [Validators.required]),
            player_phone_area: new FormControl('852', [Validators.required]),
            player_position: new FormControl("", [Validators.required]),
            player_shoot: new FormControl('', [Validators.required]),
            player_jerseysize: new FormControl('', [Validators.required]),
            player_attending_school: new FormControl('', [Validators.required]),
            player_studying_grade: new FormControl('', [Validators.required]),
            player_nationality: new FormControl('', [Validators.required]),
            player_mother_name: new FormControl('', [Validators.required]),
            player_mother_contact_no: new FormControl('', [Validators.required]),
            player_father_name: new FormControl('', [Validators.required]),
            player_father_contact_no: new FormControl('', [Validators.required]),
            // player_photo: new FormControl('', [Validators.required]),
        });
        this.getPhoneArea();
        this.getposition();
        this.getshoot();
        this.getjerseysize();
        this.getstudyingGrade();
        this.getnationality();
    }

    /**
     * thisIsFunction
     * 获取手机号码区号列表
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    getPhoneArea() {
        const config = {
            setting_key: 'phone_area'
        };
        this.dataService.getBaseData('setting', config).then(resp => {
            this.phoneAreaList = resp.data;
        })
    }

    /**
     * thisIsFunction
     * 获取position
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getposition() {
        const config = {
            "table": "position_played",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            this.position = resp.data;
        })
    }


    /**
     * thisIsFunction
     * 获取position
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getjerseysize() {
        const config = {
            "table": "jerseysize",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            this.jerseysize = resp.data;
        })
    }


    getnationality() {
        const config = {
            "table": "nationality",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            this.nationality = resp.data;
        })
    }


    /**
     * thisIsFunction
     * 获取shoot
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getstudyingGrade() {
        const config = {
            "table": "studying_grade",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            this.studyingGrade = resp.data;
        })
    }

    /**
     * thisIsFunction
     * 获取shoot
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date      2020-04-16
     */
    getshoot() {
        const config = {
            "table": "shoot",
            "where": {},
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            this.shoot = resp.data;
        })
    }


    /**
     * thisIsFunction
     * 注册
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    postRegister() {
        if (this.setForm.invalid) {
            this.toastrService.warning('', 'Please complete the information');
            this.openDirtyControl(this.setForm);
            return;
        }
        let config = this.setForm.value;
        if (this.player_facebook) {
            config['player_facebook'] = this.player_facebook
        }
        if (this.player_google) {
            config['player_google'] = this.player_google
        }
        config['player_logintype'] = this.player_logintype;
        config['data_of_birth'] = this.datePipe.transform(this.setForm.value['data_of_birth'], 'yyyy-MM-dd HH:mm:ss');
        if (this.player_logintype == 'email') {
            delete config.player_phone;
            delete config.player_phone_area;
            if (!config.player_email) {
                alert('Please fill in the email');
                return;
            }
        } else {
            delete config.player_email;
            if (!config.player_phone) {
                alert('Please fill in the phone');
                return;
            }
            if (!config.player_phone_area) {
                alert('Please fill in the phone_area');
                return;
            }
        }
        this.dataService.postBaseData('login/register', config).then(resp => {
            localStorage.setItem('token', resp.token);
            this.httpProvider.publishSessionId(resp.token);
            this.router.navigate(['/home']);
            this.globals.globalVar.next([{getInfo: true}]);
            this.toastrService.success('Success', 'Login was successful');
        })
    }

    /**
     * thisIsFunction
     * 发送注册邮箱code
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    postMailCode() {
        this.disabledEmail = true;
        let config = {
            player_email: this.setForm.value.player_email,
            type: 'register'
        };
        this.dataService.postBaseData('code/sentMail', config).then(resp => {
            // alert('成功');
            if (resp && resp.code == 200) {
                this.paracont('paracontEmail', 'disabledEmail')
                this.toastrService.success('Success', resp.message);
            } else {
                this.disabledEmail = false;
            }
        })
    }


    postPhoneCode() {
        this.disabledPhone = true;
        let config = {
            player_phone: this.setForm.value.player_phone,
            player_phone_area: this.setForm.value.player_phone_area,
            type: 'register'
        };
        this.dataService.postBaseData('code/sentPhone', config).then(resp => {
            if (resp && resp.code == 200) {
                this.paracont('paracontPhone', 'disabledPhone')
                this.toastrService.success('Success', resp.message);
            } else {
                this.disabledPhone = false;
            }
        })
    }


    /**
     * thisIsFunction
     * 60s倒计时
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-03-12
     */
    paracont(paracont, disabledClick) {
        const numbers = interval(1000);
        const takeFourNumbers = numbers.pipe(take(60));
        takeFourNumbers.subscribe(
            x => {
                this[paracont] = (60 - x) + "s";
                // this[disabledClick] = true;
            },
            error => {
            },
            () => {
                this[paracont] = "Code";
                this[disabledClick] = false;
            });
    }

    func_disabledEmail() {
        let dis = true;
        if (!this.disabledEmail && this.setForm.value.player_email) {
            dis = false;
        }
        return dis;
    }

    func_disabledPhone() {
        let dis = true;
        if (!this.disabledPhone && this.setForm.value.player_phone) {
            dis = false;
        }
        return dis;
    }


    onPhoneup(event) {
        let input = event.target;
        let value = input.value.replace(/[^0-9-]/ig, '');
        var arr = value.split('');
        input.value = arr.join('');
        // 输完11位之后
        let phone = event.target.value.replace(/\s+/g, '');
    }

    onPhonepress(event) {
        // 判断是否为数字
        let inputStr = String.fromCharCode(event.keyCode);
        let re = /^[0-9-]*$/; //判断字符串是否为数字
        if (!re.test(inputStr)) {
            return false;
        }
    }

    // 打开脏检验
    openDirtyControl(data) {
        for (const i in data.controls) {
            this.setForm.controls[i].markAsDirty();
            this.setForm.controls[i].updateValueAndValidity();
        }
    }

    // 关闭脏校验
    closeDirtyControl(data) {
        for (const i in data.controls) {
            this.setForm.controls[i].clearValidators();
            this.setForm.controls[i].updateValueAndValidity();
        }
    }

}
