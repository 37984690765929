/**
 * @copyright   2016-2019 Dreamover-Studio
 * @description 'description'
 * @author      jianhui.huang@dreamover-studio.com
 * @version     0.0.1
 * @date        2019-05-16
 */
/**
 *  Must go to app.module config the provider
 **/
import {Injectable} from '@angular/core';
import {Http, RequestOptions, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
// import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalsService} from "../data/globals.service";

export interface XRequestOptions {
    params?: any;
    data?: any;
}

@Injectable({
    providedIn: 'root'
})
export class HttpProvider {
    sessionId: string = '';
    public apiPrefix = '';

    static searchParamsFormat(searchParams: any): string {
        if (typeof searchParams === 'string') {
            return searchParams;
        }
        if (typeof searchParams === 'object') {
            let arr = [];
            for (let key in searchParams) {
                if (searchParams.hasOwnProperty(key) && searchParams[key] !== undefined && searchParams[key] !== null) {
                    arr.push(key + '=' + encodeURIComponent(searchParams[key]));
                }
            }
            return arr.join('&');
        }
        return '';
    }

    constructor(private http: Http, public globals: GlobalsService) {
        this.apiPrefix = this.globals.apiUrl + '/api/'
    }

    get(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.get(api, this.getRequestOptions());
        return this.responseHandle(result);
    }

    //create
    post(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.post(api, options.data, this.getRequestOptions());
        return this.responseHandle(result);
    }

    //update
    put(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.put(api, options.data, this.getRequestOptions());
        return this.responseHandle(result);
    }

    //delete
    delete(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.delete(api, this.getRequestOptions(options.data));
        return this.responseHandle(result);
    }

    upload(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.post(api, options.data);
        return this.responseHandle(result);
    }

    publishSessionId(id: string) {
        this.sessionId = id;
    }

    private joinApi(url: string, options?: XRequestOptions): string {
        let requestUrl = this.apiPrefix + url;
        if (!options) {
            options = {
                params: {
                    // t: Date.now()
                }
            };
        } else {
            if (options.params) {
                // options.params.t = Date.now();
            } else {
                options.params = {
                    // t: Date.now()
                };
            }
        }
        const requestParams = HttpProvider.searchParamsFormat(options.params);
        if (requestUrl.indexOf('?') === -1) {
            let questionMark = /\\?$/.test(requestUrl) ? '?' : '';
            return requestUrl + questionMark + requestParams;
        }
        if (!requestParams) { //20190516 修復by jianhui ：如果 requestParams 為空，請求鏈接中會多一個 &空 例如 http://vivagame.g3asiagaming.com:8089/api/v1/games/ArcticSky?language=en-US&
            return requestUrl;
        }
        return requestUrl + '&' + requestParams;
    }

    public getRequestOptions(body?: any): RequestOptions {
        let headers: any = new Headers({
            'Content-type': 'application/json; charset=UTF-8',
            // 'Content-type': 'application/x-www-form-urlencoded'
            'Accept': 'application/json',
            // 'ds-auth-api': 'hubbis+dreamover'
        });
        this.sessionId = localStorage.getItem('token');
        if (this.sessionId) {
            headers = new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                // 'Content-type': 'application/x-www-form-urlencoded'
                'Accept': 'application/json',
                // 'ds-auth-api': 'hubbis+dreamover',
                'Authorization': this.sessionId
            });
        }
        return new RequestOptions({
            headers: headers,
            withCredentials: false,
            body
        });
    }

    public responseHandle(response: Observable<Response>): Promise<any> {
        return new Promise((resolve, reject) => {
            return response.toPromise().then(response => {
                if (response && response.status) {
                    if (response.status === 200) {
                        let result = response.json();
                        // if (!result.success && result.message === 'NO_LOGIN') {
                        //     if(!skipLoginPop) this.permissionsService.publishLoginState(false);
                        // }
                        resolve(result);
                    } else {
                        reject(response.text());
                    }
                }
            }, error => {
                reject(error);
            });
        });
    }
}

