import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {NgxXLSXService} from '@notadd/ngx-xlsx';
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-league-info-team',
    templateUrl: './league-info-team.component.html',
    styleUrls: ['./league-info.component.scss'],
    providers: [DatePipe]
})
export class LeagueInfoTeamComponent implements OnInit {
    loading: boolean = true;
    datalist: any;
    cityLeague = [];
    gameSchedule = [];
    searchForm: FormGroup;
    type: any = 'season';
    selectList: any = [
        {
            name: 'BY SEASON',
            id: 'season',
            value: '1',
        },
        {
            name: 'BY GAME',
            id: 'game',
            value: '2',
        },
        {
            name: 'ALL-TIME',
            id: 'all',
            value: '3',
        },
    ];
    selectListId = 'season';
    selectListIdNum = '1';

    seasonList: any = [];
    leagueList: any = [];
    temp: Array<any> = [];
    selected: Array<any> = [];
    rows: Array<any> = [];
    offsetNum: number = 0;
    numToPage: number = 1;
    limit: number = 20;

    leagueTypeList: any = [];
    positionList: any = [];
    selectionList: any = [];
    columnsCopy: any;

    defaultColDef = {
        flex: 1,
        flexShrink: 0,
        minWidth: 122,
        resizable: true,
        sortable: true,
        width: 122,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
    };
    private gridApi;
    private gridColumnApi;
    columnDefs = [
        {
            headerName: 'Team',
            field: 'team_name',
            minWidth: 180,
            pinned: 'left',
            lockPinned: true,
            cellClass: 'lock-pinned',
        },
        {
            headerName: 'Season',
            field: 'team_season',
        },
        {
            headerName: 'Game Played',
            field: 'team_game_played',
        },
        {
            headerName: 'Wins',
            field: 'team_wins',
        },
        {
            headerName: 'Losses',
            field: 'team_losses'
        },
        {
            headerName: 'Ties',
            field: 'team_ties',
        },
        {
            headerName: 'Overtime Wins',
            field: 'team_overtime_wins',
        },
        {
            headerName: 'Overtime Lose',
            field: 'team_overtime_lose',
        },
        {
            headerName: 'Overtime Ties',
            field: 'team_overtime_ties',
        },
        {
            headerName: 'Team Points',
            field: 'team_points',
        }
    ];

    constructor(public dataService: DataService, private activatedRoute: ActivatedRoute,
                private xlsxService: NgxXLSXService, public datePipe: DatePipe) {
        this.searchForm = new FormGroup({
            starttime: new FormControl(),
            endtime: new FormControl(),
            date_starttime: new FormControl(),
            date_endtime: new FormControl(),
            league_id: new FormControl(),
            league_type_id: new FormControl(),
            selection_id: new FormControl(),
        });
    }

    ngOnInit() {
        this.getSelectData();
        this.columnsCopy = JSON.stringify(this.columnDefs);
    }

    /**
     * thisIsFunction
     * 获得全部球员信息
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-04-09
     */
    getData() {
        const columnsCopy = JSON.parse(this.columnsCopy), config = this.searchForm.value;
        if (config.starttime !== config.endtime) {
            this.columnDefs = columnsCopy.filter(c => {
                return c.field !== 'team_season';
            });
        }
        if (config.date_starttime) {
            config.date_starttime = this.datePipe.transform(config.date_starttime, 'yyyy-MM-dd');
        } else {
            config.date_endtime = null
        }
        if (config.date_endtime) {
            config.date_endtime = this.datePipe.transform(config.date_endtime, 'yyyy-MM-dd');
        } else {
            config.date_starttime = null
        }
        if (!config.starttime || !config.endtime) {
            config.starttime = null;
            config.endtime = null
        }
        config['type'] = this.selectListIdNum;
        this.dataService.getBaseData('TeamPlayer/getteam', config).then(resp => {
            console.log('getplayer', resp);
            if (resp.code == 200) {
                this.rows = resp.data
            }
        })
    }

    radioChange(event) {
        this.type = event.target.defaultValue;
        if (event.target.defaultValue === 'season') {
            this.searchForm.patchValue({
                date_starttime: null,
                date_endtime: null,
            })
        } else {
            this.searchForm.patchValue({
                starttime: null,
                endtime: null,
            })
        }
    }

    /**
     * thisIsFunction
     * 導出表格數據
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date      2019-11-06
     */
    exportAsXLSXSingle(): void {
        let sheet = [];
        let sheetHeaders = [];
        for (let i = 0; i < this.rows.length; i++) {
            let sheetObj = {};
            this.columnDefs.forEach((val, index) => {
                if (this.rows[i][val.field] || this.rows[i][val.field] == 0) {
                    sheetObj[val.headerName] = this.rows[i][val.field]
                } else {
                    sheetObj[val.headerName] = ''
                }
            });
            sheet.push(sheetObj);
        }
        this.columnDefs.forEach((val, index) => {
            if (val.headerName !== 'portrait') {
                sheetHeaders.push(val.headerName)
            }
        });
        let sheetNames = ['Worksheet 1'];
        let date = new Date();
        const time = this.datePipe.transform(date, 'yyyyMMddHHmmss');
        this.xlsxService.exportAsExcelFile(sheet, {
            fileName: 'league Info_' + time,
            headers: sheetHeaders,
            sheetNames: sheetNames,
        });
    }

    changeType(event) {
        const columnsCopy = JSON.parse(this.columnsCopy);
        if (event.id === 'all') {
            this.searchForm.patchValue({
                starttime: null,
                endtime: null,
                date_starttime: null,
                date_endtime: null,
            });
            this.columnDefs = columnsCopy.filter(c => {
                return c.field !== 'team_season';
            });
        } else if (event.id === 'season') {
            this.searchForm.patchValue({
                date_starttime: null,
                date_endtime: null,
            });
            this.type = 'season';
            this.columnDefs = columnsCopy
        } else {
            this.searchForm.patchValue({
                starttime: null,
                endtime: null,
            });
            this.type = 'date';
            this.columnDefs = columnsCopy.filter(c => {
                return c.field !== 'team_season';
            });
        }
        this.selectListIdNum = event.value;
        this.getData();
    }

    getSelectData() {
        const _ = this;
        const P = new Promise(function (resolve, reject) {
            _.loading = true;
            _.dataService.getBaseData('TeamPlayer/getYear').then(resp => {
                if (resp.code == 200) {
                    _.seasonList = [];
                    if (resp.data.length > 0) {
                        // _.searchForm.patchValue({
                        //     date_starttime: resp.data[0].gametime,
                        //     date_endtime: resp.data[0].gametime,
                        // });
                        for (const item of resp.data) {
                            _.seasonList.push({
                                name: item.gametime,
                                id: item.gametime,
                            })
                        }
                    }
                    resolve(true);
                }
            })
        });

        let league_type = {
            "table": 'league_type',
            "where": {},
            "perPage": "10000"
        };

        const P1 = new Promise(function (resolve, reject) {
            _.dataService.getCommonData(league_type).then(resp => {
                _.leagueTypeList = resp.data;
                // if (resp.data.length) {
                //     _.searchForm.patchValue({
                //         league_type_id: resp.data[0].league_type_id,
                //     });
                // }
                resolve(true);
            });
        });

        let league = {
            "table": 'league',
            "where": {'league_type':'city_league'},
            "select": ['league_id', 'league_name'],
            "perPage": "10000"
        };
        const P2 = new Promise(function (resolve, reject) {
            _.dataService.getCommonData(league).then(resp => {
                _.leagueList = resp.data;
                // if (resp.data.length) {
                //     _.searchForm.patchValue({
                //         league_id: resp.data[0].league_id,
                //     });
                // }
                resolve(true);
            });
        });

        let selection = {
            "table": 'selection',
            "select": ['selection_name', 'selection_id', 'league_name'],
            "where": {
                'z_league_selection_is_deleted': 'N',
                'league_type': 'city_league',
                'selection_is_deleted' : 'N',
                'league_is_deleted' : 'N'
            },
            "join": {
                "0": {"0": "z_league_selection", "1": "selection_id = z_league_selection_selection_id", "2": "left"},
                "1": {"0": "league", "1": "league_id = z_league_selection_league_id", "2": "left"},
            },
            "perPage": "10000"
        };
        const P3 = new Promise(function (resolve, reject) {
            _.dataService.getCommonData(selection).then(resp => {
                // _.selectionList = resp.data;
                // if (resp.data.length) {
                //     _.searchForm.patchValue({
                //         selection_id: resp.data[0].selection_id,
                //     });
                // }
                if (resp.data.length) {
                    for (const item of resp.data) {
                        _.selectionList.push({
                            name: item.league_name + ' - ' + item.selection_name,
                            selection_id: item.selection_id,
                        })
                    }
                }
                resolve(true);
            });
        });

        Promise.all([P, P1, P2, P3]).then(function (results) {
            _.loading = false;
            _.getData();
        });
    }
}
