import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
    selector: 'app-news2',
    templateUrl: './news2.component.html',
    styleUrls: ['./news2.component.scss']
})
export class NewsTwoComponent implements OnInit {

    loading: boolean = true;
    datalist: any = [];

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.dataService.getBaseData('news').then(resp => {
            if (resp.code === 200) {
                resp.data.forEach((val, key) => {
                    if (val.news_type && val.news_type !== 'news') {
                        const content = val.news_content.replace(/<[^<>]+>/g, '').replace(/&nbsp;/ig, '');
                        resp.data[key].news_content = content;
                        this.datalist.push(val)
                    }
                });
                this.loading = false;
            }
        })
    }
}
