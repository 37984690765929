import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params} from "@angular/router";
import {GlobalsService} from "../../../@core/data/globals.service";


@Component({
    selector: 'app-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

    loading: boolean = true;
    datalist: any;
    newId:any;

    constructor(public dataService: DataService,private activatedRoute: ActivatedRoute,public globals: GlobalsService) {
        //获取参数值
        this.newId = this.activatedRoute.snapshot.params['id'];
        //订阅路由参数，路由传递id改变之后执行
        this.activatedRoute.params.subscribe((params:Params)=>{this.newId=params["id"];this.getData();});
    }

    ngOnInit() {
        this.getData();
    }

    getData() {

        let config = {
            'table':'news',
           "where":{
                "news_id": this.newId,
            },
            'return':'result_array',
        };

        this.dataService.getCommonData(config).then(resp => {

            if (resp.code === 200) {
                this.loading = false;
                this.datalist = resp.data;
                
            } else {

            }
        })
    }
}
