import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
    selector: 'app-teams',
    templateUrl: './teams.component.html',
    styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

    loading: boolean = true;
    selectLoading = false;
    datalist: any;
    cityLeague = [];
    gameSchedule = [];
    teamForm: FormGroup;
    selectleagueData = [];
    scheduleId: any;
    Id = '0';


    constructor(public dataService: DataService, private activatedRoute: ActivatedRoute) {


        this.Id = this.activatedRoute.snapshot.params['id'];

        this.activatedRoute.params.subscribe((params: Params) => {
            this.Id = params['id'];
            this.getData();
        });
    }

    ngOnInit() {
        this.getData();
        this.getCityLeague();
        // this.getgameschedule();

        this.teamForm = new FormGroup({
            cityLeague: new FormControl('', [Validators.required]),
            gameSchedule: new FormControl('', [Validators.required]),
            team_name: new FormControl('', []),
        });

    }

    /**
     * thisIsFunction
     * 获得全部队伍
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date        2020-04-09
     */
    getData() {
        this.loading = true;
        let config = {};
        if (this.Id) {
            let ids = this.Id.split("-");
            config = {
                "league_id": ids[0],
                "selection_id": ids[1],
            };
        }
        this.dataService.getBaseData('Team', config).then(resp => {
            if (resp.code === 200) {
                this.loading = false;
                this.datalist = resp.data;
            }
        })
    }


    /**
     * thisIsFunction
     * 获得联赛list
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date        2020-04-09
     */

    getCityLeague() {
        this.selectLoading = true;
        const config = {
            "table": "league",
            "where": {
                "league_is_disabled": 'N'
            },
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            var cityLeagueData = [];
            if (resp.code === 200) {
                resp.data.forEach((val, key) => {
                    cityLeagueData.push({'name': val.league_name, 'id': val.league_id});
                })
                this.cityLeague = cityLeagueData;
                this.selectLoading = false;
            }
        })
    }

    /**
     * thisIsFunction
     * 获得组别list
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date        2020-04-09
     */
    getgameschedule(event) {
        this.selectLoading = true;
        this.scheduleId = '';
        if (event) {

            const config = {
                "table": "selection",
                "where": {
                    "z_league_selection_is_deleted": "N",
                    "z_league_selection_league_id": event.id
                },
                "join": {
                    "0": {
                        "0": "z_league_selection",
                        "1": "selection_id = z_league_selection_selection_id",
                        "2": "left"
                    },

                },
            };
            this.dataService.getCommonData(config).then(resp => {
                var gameScheduleData = [];
                if (resp.code === 200) {
                    resp.data.forEach((val, key) => {
                        gameScheduleData.push({'name': val.selection_name, 'id': val.selection_id});
                    })
                    this.gameSchedule = gameScheduleData;
                    this.selectLoading = false;
                }
            })
        } else {
            this.selectLoading = false;
            this.gameSchedule = [];
        }

    }

    /**
     * thisIsFunction
     * serach
     *
     * @author    yingbo.hu@dreamover-studio.com
     * @return    array
     * @date        2020-04-09
     */
    getSearch() {
        let thisSelect = {};
        if (this.teamForm.value.cityLeague) {
            thisSelect["league_id"] = this.teamForm.value.cityLeague.id;
        }
        if (this.teamForm.value.gameSchedule) {
            thisSelect["selection_id"] = this.teamForm.value.gameSchedule.id;
            thisSelect["team_name_like"] = this.teamForm.value.team_name;
        }

        // if (thisSelect["team_name_like"] || (thisSelect["league_id"] && thisSelect["selection_id"])) {
            this.dataService.postBaseData('Team/select', thisSelect).then(resp => {
                if (resp.code === 200) {
                    this.datalist = resp.data;
                }
            });
        // } else {
        //     this.getData()
        // }

    }
}
