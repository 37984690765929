import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';

@Component({
    selector: 'child-cell',
    template: `<span><button style="height: 20px"
            (click)="invokeParentMethod()"
            class="btn btn-primary">Detail</button></span>`,
    styles: [
            `
            .btn {
                line-height: 0.5;
            }
        `,
    ],
})
export class ClickableComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(public router: Router) {

    }

    agInit(params: any): void {
        this.params = params;
    }

    public invokeParentMethod() {
        this.router.navigate(['/league-detail',this.params.value])
    }

    refresh(): boolean {
        return false;
    }
}
