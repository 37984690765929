import {Component, OnInit} from '@angular/core';
// import Swiper from "swiper"
import { CountUpOptions } from 'countup.js';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface,
    SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    opts: CountUpOptions={
        duration: 2
    };
    activeSlideIndex:any=0;


    // thisSwiper: Swiper;
    slides = [
        {
            title: 'Eheu, compater! Eras prarere in amivadum! Azureus urias ducunt ad lanista. A falsis, equiso albus gluten. Cum liberi mori, omnes nuclear vexatum iacerees examinare pius, emeritis uriaes. Tolerare saepe ducunt ad velox nix.',
            name: 'Joanna Smith',
            img: 'assets/images/about_2.jpg'
        },
        {
            title: 'Vae, azureus barcas! Ubi est fatalis domina? Eheu, germanus adelphis! Cur visus prarere? Turpiss congregabo in dexter rugensis civitas! Pol. Fatalis abnoba satis desideriums fluctus est. Heuretess observare in pius cirpi!',
            name: 'James Williams',
            img: 'assets/images/about_3.jpg'
        },
        {
            title: 'Est rusticus tumultumque, cesaris. Varius ususs ducunt ad secula. Ubi est alter vita? Hydras crescere in brema! Mensa de dexter classis, reperire uria! Azureus, regius tatas nunquam examinare de alter, nobilis vita.',
            name: 'Kate McMillan',
            img: 'assets/images/about_4.jpg'
        },
        {
            title: 'Quadras credere in magnum cella! Nunquam contactus calceus. Manducare vix ducunt ad castus amor. Pol. Ubi est teres repressor? Finiss mori, tanquam audax bubo. Resistere nunquam ducunt ad neuter heuretes.',
            name: 'Peter Wilson',
            img: 'assets/images/about_5.jpg'
        },
    ]

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        var _ = this;
        // this.thisSwiper = new Swiper(".swiper-container", {
        //     direction: 'horizontal',
        //     loop: true,
        //     // pagination: {
        //     //     el: '.swiper-pagination'
        //     // },
        //     pagination: {
        //         el: '.swiper-pagination',
        //         clickable: true,
        //         renderBullet: function (index, className) {
        //             return '<div class="slick-dot-item"><img src="' + _.slides[index]['img'] + '" class="' + className + '"/></div>';
        //         },
        //     },
        //     navigation: {
        //         nextEl: ' .swiper-button-next',
        //         prevEl: '.swiper-button-prev',
        //     }
        // })
    }

}
