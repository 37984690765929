import {Component, OnInit, OnDestroy} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
    selector: 'app-league-group',
    templateUrl: './league-group.component.html',
    styleUrls: ['./league-group.component.scss']
})
export class LeagueGroupComponent implements OnInit, OnDestroy {

    loading: boolean = true;
    datalist: any;
    selectId: any = '';
    title: any = '';
    subscript: any;

    constructor(public dataService: DataService, private activatedRoute: ActivatedRoute, private router: Router) {
        //获取参数值
        this.selectId = this.activatedRoute.snapshot.params['id'];
        //订阅路由参数，路由传递id改变之后执行
        this.subscript = this.activatedRoute.params.subscribe((params: Params) => {
            this.selectId = params["id"];
            this.getData();
        });
    }

    ngOnInit() {
        // this.getData();
    }

    getData() {
        this.loading = true;
        const config = {
            "table": "selection",
            "where": {
                "selection_id": this.selectId,
            },
            "return": "row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            console.log(resp)
            if (resp.code === 200) {
                this.datalist = resp.data['selection_content'];
                this.title = resp.data['selection_name'];
                this.loading = false;
            }
        })

    }

    ngOnDestroy() {
        this.subscript.unsubscribe();
    }
}
