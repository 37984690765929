import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params} from "@angular/router";
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-tournament-type2',
    templateUrl: './tournament-type2.component.html'
})
export class TournamentType2Component implements OnInit {

    loading: boolean = false;
    datalist: any;
    // setForm: FormGroup;
    people = [1];

    constructor(public dataService: DataService, private activatedRoute: ActivatedRoute,) {

    }

    ngOnInit() {

    }

    addPeople(){
        this.people.push(1)
    }
    delPeople(num){
        this.people.splice(num,1)
    }

}
