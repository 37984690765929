import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    PopoverModule,
    ProgressbarModule,
    RatingModule,
    SortableModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    TypeaheadModule
} from 'ngx-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import {NgwWowModule} from 'ngx-wow';
import {HtmlPipe} from './@core/inner-html/innerhtml.pipe';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CountUpModule} from 'countup.js-angular2';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {SWIPER_CONFIG} from 'ngx-swiper-wrapper';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {HttpProvider} from './@core/utils/http.provider';
import {DataService} from './@core/data/data.service';
import {HttpModule} from '@angular/http';
import {FacebookModule} from 'ngx-facebook';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GlobalsService} from './@core/data/globals.service';
import {ToastrModule} from 'ngx-toastr';
import {NgxUploaderModule} from 'ngx-uploader';


import {HeaderComponent} from './page/components/header/header.component';
import {FooterComponent} from './page/components/footer/footer.component';
import {HistoryComponent} from './page/about-list/history/history.component';
import {Code404Component} from './page/code404/code404.component';
import {HomeComponent} from './page/home-list/home/home.component';
import {AboutComponent} from './page/about-list/about/about.component';
import {CoachesComponent} from './page/about-list/coaches/coaches.component';
import {PartnersBoxComponent} from './page/components/partners-box/partners-box.component';
import {PaymentsComponent} from './page/about-list/payments/payments.component';
import {LoginComponent} from './page/maintenance/login/login.component';
import {HeaderBannerComponent} from './page/components/header-banner/header-banner.component';
import {RegistrationComponent} from './page/maintenance/registration/registration.component';
import {ForgetPasswordComponent} from './page/maintenance/forgetPassword/forgetPassword.component';
import {LeaguePoliciesComponent} from './page/about-list/league-policies/league-policies.component';
import {SevereWeatherComponent} from './page/about-list/severe-weather/severe-weather.component';
import {SponsorshipComponent} from './page/about-list/sponsorship/sponsorship.component';
import {ContactComponent} from './page/about-list/contact/contact.component';
import {NewsComponent} from './page/about-list/news/news.component';
import {NewsTwoComponent} from './page/about-list/news/news2.component';
import {NewsDetailComponent} from './page/about-list/news/news-detail.component';
import {PhotoComponent} from './page/about-list/photo/photo.component';
import {PhotoDetailComponent} from './page/about-list/photo/photo-detail.component';
import {SelectComponent} from './page/selects-list/select/select.component';
import {TrainingAndDevelopmentComponent} from './page/training-and-development/training-and-development/training-and-development.component';
import {SyntheticIceComponent} from './page/training-and-development/synthetic-ice/synthetic-ice.component';
import {SemiprivateLessonComponent} from './page/training-and-development/special-training/special-training.component';
import {FitnessComponent} from './page/training-and-development/off-ice/off-ice.component';
import {CampsComponent} from './page/training-and-development/camps/camps.component';
import {CampsDetailComponent} from './page/training-and-development/camps/camps-detail.component';
import {CampsNewComponent} from './page/training-and-development/camps/camps-new.component';
import {CampsDetailNewComponent} from './page/training-and-development/camps/camps-detail-new.component';
import {CityLeagueComponent} from './page/city-league-list/city-league/city-league.component';
import {TeamsComponent} from './page/city-league-list/teams/teams.component';
import {PersonalComponent} from './page/maintenance/personal/personal.component';
import {RegisteredLeagueComponent} from './page/city-league-list/registered-league/registered-league.component';
import {TournamentComponent} from './page/training-and-development/tournament/tournament.component';
import {TournamentType1Component} from './page/training-and-development/tournament/tournament-type1.component';
import {TournamentType2Component} from './page/training-and-development/tournament/tournament-type2.component';
import {BanquetDetailComponent} from './page/training-and-development/banquet/banquet-detail.component';
import {BanquetComponent} from './page/training-and-development/banquet/banquet.component';
import {PrivacyPolicyComponent} from './page/about-list/privacy-policy/privacy-policy.component';
import {TechnicalStatisticsComponent} from './page/city-league-list/technical-statistics/technical-statistics.component';
import {LeagueDetailComponent} from './page/city-league-list/league-detail/league-detail.component';
import {GameScheduleComponent} from './page/city-league-list/game-schedule/game-schedule.component';

import {ModifyLoginAccountComponent} from './page/maintenance/modifyLoginAccount/modifyLoginAccount.component';
import {EventComponent} from './page/training-and-development/event/event.component';

import {SeasonStandingsComponent} from './page/city-league-list/season-standings/season-standings.component';
import {SeasonStatisticsComponent} from './page/city-league-list/season-statistics/season-statistics.component';
import {ResultsComponent} from './page/city-league-list/results/results.component';

import {UpdataPortraitComponent} from './page/components/updata-portrait/updata-portrait.component';
import {ServicesModule} from './@core/data/service.module';
import {LeagueInfoComponent} from './page/city-league-list/league-info/league-info.component';
import {LeagueInfoTeamComponent} from './page/city-league-list/league-info/league-info-team.component';
import {LeagueGroupComponent} from './page/city-league-list/league-group/league-group.component';
import {GridTableComponent} from './page/components/grid-table/grid-table.component';

import { AgGridModule } from 'ag-grid-angular';

import {
    IMqttMessage,
    MqttModule,
    IMqttServiceOptions
} from 'ngx-mqtt';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: 'mqtt.dreamover-studio.cn',  // mqtt 服务器ip
    port: 8084,  // mqtt 服务器端口
    path: '/mqtt',
    protocol: 'wss',
    username: 'admin2',
    password: 'public2'
};

import {ImageCropperModule} from 'ngx-image-cropper';
import {NgxXLSXModule} from '@notadd/ngx-xlsx';
import {ClickableComponent} from "./page/city-league-list/game-schedule/clickable.component";

const PAGES_COMPONENTS = [AppComponent, HeaderComponent, FooterComponent, HistoryComponent, Code404Component, HomeComponent, AboutComponent, CoachesComponent, ModifyLoginAccountComponent,
    PartnersBoxComponent, PaymentsComponent, LoginComponent, HeaderBannerComponent, RegistrationComponent, LeaguePoliciesComponent, SevereWeatherComponent, SponsorshipComponent,
    ContactComponent, NewsComponent, PhotoComponent, SelectComponent, PhotoDetailComponent, TrainingAndDevelopmentComponent, SyntheticIceComponent, SemiprivateLessonComponent, FitnessComponent, NewsTwoComponent,
    NewsDetailComponent, CityLeagueComponent, TeamsComponent, PersonalComponent, RegisteredLeagueComponent, TournamentComponent, EventComponent,
    TournamentType1Component, TournamentType2Component, BanquetDetailComponent, BanquetComponent, PrivacyPolicyComponent, TechnicalStatisticsComponent, LeagueDetailComponent, GameScheduleComponent,
    SeasonStandingsComponent, SeasonStatisticsComponent, ForgetPasswordComponent, UpdataPortraitComponent, ResultsComponent, CampsNewComponent, CampsDetailNewComponent, CampsComponent,
    CampsDetailComponent, LeagueInfoComponent, LeagueInfoTeamComponent,LeagueGroupComponent,GridTableComponent,ClickableComponent];

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
};

@NgModule({
    declarations: [
        ...PAGES_COMPONENTS,
        HtmlPipe,
    ],
    imports: [
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        SortableModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        NgwWowModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgSelectModule,
        NgxDatatableModule,
        CountUpModule,
        SwiperModule,
        HttpModule,
        FacebookModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }), // ToastrModule added
        NgxUploaderModule,
        ImageCropperModule,
        ServicesModule,
        NgxXLSXModule,
        AgGridModule.withComponents([ClickableComponent]),
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        HttpProvider,
        DataService,
        GlobalsService,
        BsModalService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
