import {Component, OnInit, ComponentFactoryResolver, ViewChild, ElementRef, ɵConsole,Renderer2} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

export const EMAILPATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';

@Component({
    selector: 'app-tournament',
    templateUrl: './tournament.component.html',
    styleUrls: ['./tournament.component.scss']
})
export class TournamentComponent implements OnInit {
    hasUser = null;
    loading: boolean = true;
    datalist: any;
    showFrom: boolean = false;
    tournamentForm: FormGroup;
    playerInfo: any = {};
    remarks: any = '';
    tournament_order_position_played_id = [];
    contentDetail = [];
    member_data = ['HK Selects & City League','City League Only','None'];
    member = [];
    subcontent = [];
    tournamentBus = [];
    tournamentHotel = [];
    tournamentSelect = [];
    objSummber = {};
    count = 0;
    showQR: boolean = false;
    safeSrc: SafeResourceUrl;
    tournamentData = [];
    tournament_order_player_id = '';
    tournament_order_id = '';
    tournament_order_sn = '';
    tournament_order_status = '';
    modalRef: BsModalRef;
    tournamentOderIds = [];
    tournamentOderitem = [];
    tournamentOdervalue: boolean = false;
    alipaylink: any = '';
    Interval: any = '';
    SummerListLength: any = '';
    tournamentBusValue = [];
    tournamentExtraHotelValue = [];
    tournamentRoomHotelValue = [];
    tournament_order_hotel_night_number = 0;
    tournament_order_hotel_room_number = 0;
    IstournamentBusValue : boolean = false;
    IstournamentRoomValue : boolean = false;
    IstournamentExtraValue : boolean = false;
    tournamentTypeArray = [];
    tournamentTypeData = [];
    tournament_order_check_in_date = '';
    tournament_order_check_out_date = '';
    tournament_order_hotel_room_tpye = '';
    tournament_order_hotel_room_special_request = '';
    tournament_order_hotel_check_in_name = '';

    show_tournament_form: boolean = false;
    //show_tournament_form: boolean = true;


    tournament_order_member_name = '';
    tournament_title = '2021 Super Cyclone Tournament';

    tournament_order_hotel_id = '';
    
    //是否显示 check info
    tournament_show_check_info = false;

    player_last_name = '';
    player_first_name = '';
    player_birth = '';
    player_position_id ='';
    player_member_id ='';
    player_phone = '';
    mother_email = '';
    father_email = '';

    @ViewChild('template', {static: true}) templateView: ElementRef;
    @ViewChild('template_wechat', {static: true}) template_wechat: ElementRef;

    constructor(
        private renderer2: Renderer2,
        public dataService: DataService,
        private activatedRoute: ActivatedRoute,
        private el:ElementRef,
        private router: Router,
        private modalService: BsModalService,
        private sanitizer: DomSanitizer) {
        this.hasUser = localStorage.getItem("user") || null

    }


    ngOnInit() {
        // 网站tournament页面去掉需要登录才能访问的判断 by wm 20200728
        this.getData();
        const config = {
            "table": "setting",
            // "select":"player_name",
            "where": {},
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {

            if (resp.code === 200) {
                resp.data.forEach((val) => {
                    if (val.setting_key === 'show_tournament_form') {
                        this.show_tournament_form = val.setting_value === 'Y';
                    }
                    if (val.setting_key === 'tournament_title') {
                        this.tournament_title = val.setting_value;
                    }

                });
            }

            if (this.hasUser && this.show_tournament_form) {
                this.getSummerList();
                this.tournamentOrder();
                
                this.getPlayerDataSelect('position_played');
                this.getPlayerDataSelect('tournament_member');
                let userData = localStorage.getItem("user");
                this.playerInfo = JSON.parse(userData) || [];
                console.log('playerInfo',this.playerInfo);
                //console.log('father_email',this.playerInfo['father_email']);

                this.getTournamentData('type');
            }else{
                this.getTournamentBusData();
                this.getTournamentHotelData();
                this.initTournamentData();
            }

            //this.getTournamentBusData();
            //this.getTournamentHotelData();
            console.log('order_id2',this.tournament_order_id);
            
            /*
            if(this.tournament_order_id){
                var player_last_name = this.tournamentTypeData.tournament_order_player_last_name;
                var player_first_name = this.tournamentTypeData.tournament_order_player_first_name;
                var player_birth = this.tournamentTypeData.tournament_order_player_birthdate;
                var player_player_member_id =this.tournamentTypeData.tournament_order_member_id;
                var position_id =this.tournamentTypeData.tournament_order_position_played_id;
                var player_phone = this.tournamentTypeData.tournament_order_phone;
                var mother_email = this.tournamentTypeData.tournament_order_mother_email;
                var father_email =this.tournamentTypeData.tournament_order_father_email;
            }else{
                */
                //新订单使用playerInfo
                console.log('this.playerInfo',this.playerInfo)
                this.player_last_name = this.playerInfo['player_last_name'];
                this.player_first_name = this.playerInfo['player_first_name'];
                this.player_birth = this.playerInfo['player_birth'];
                this.player_position_id =this.playerInfo['player_position_id'];
                this.player_member_id =this.playerInfo['player_member_id'];
                this.player_phone = this.playerInfo['player_phone'];
                this.mother_email = this.playerInfo['mother_email'];
                this.father_email = this.playerInfo['father_email'];

                //}
                
                this.tournamentForm = new FormGroup({
                    tournament_order_player_id: new FormControl(this.playerInfo['player_id'], [Validators.required]),
                    tournament_order_player_last_name: new FormControl(this.player_last_name, [Validators.required]),
                    tournament_order_player_first_name: new FormControl(this.player_first_name, [Validators.required]),
                    tournament_order_player_birthdate: new FormControl(this.player_birth, [Validators.required]),
                    tournament_order_position_played_id: new FormControl(this.player_position_id, [Validators.required]),
                    tournament_order_member_id: new FormControl(this.player_member_id, [Validators.required]),
                    tournament_order_phone: new FormControl(this.player_phone, [Validators.required]),
                    tournament_order_father_email: new FormControl(this.mother_email, [Validators.required, Validators.pattern(EMAILPATTERN)]),
                    tournament_order_mother_email: new FormControl(this.father_email, [Validators.required, Validators.pattern(EMAILPATTERN)]),
                    tournament_order_remarks: new FormControl(this.remarks),
                    tournament_order_cost: new FormControl(this.count, [Validators.required]),
                    tournament: new FormControl(this.tournamentSelect, [Validators.required]),

                    //tom test
                    tournament_order_check_in_date:new FormControl(this.tournament_order_check_in_date),
                    tournament_order_check_out_date:new FormControl(this.tournament_order_check_out_date),
                    tournament_order_hotel_night_number:new FormControl(this.tournament_order_hotel_night_number),
                    tournament_order_hotel_room_number:new FormControl(this.tournament_order_hotel_room_number),
                    tournament_order_hotel_room_tpye:new FormControl(this.tournament_order_hotel_room_tpye),
                    tournament_order_hotel_room_special_request:new FormControl(this.tournament_order_hotel_room_special_request),
                    tournament_order_hotel_check_in_name:new FormControl(this.tournament_order_hotel_check_in_name),
                    tournament_order_hotel_room_set_day:new FormControl(),
                    tournament_bus_value:new FormControl(this.tournamentBusValue),
                    tournament_extra_hotel_value:new FormControl(this.tournamentExtraHotelValue),
                    tournament_room_hotel_value:new FormControl(this.tournamentRoomHotelValue),
                });
            })
}


initTournamentData(data?){
    if(this.tournament_order_id){
            /*
            this.player_last_name = data.tournament_order_player_last_name;
            this.player_first_name = data.tournament_order_player_first_name;
            this.player_birth = data.tournament_order_player_birthdate;
            this.player_member_id =data.tournament_order_member_id;
            this.player_position_id =data.tournament_order_position_played_id;
            this.player_phone = data.tournament_order_phone;
            this.mother_email = data.tournament_order_mother_email;
            this.father_email =data.tournament_order_father_email;
            console.log('mother_email',data,this.mother_email);
            */
            //this.player_id = data.tournament_order_player_id;
            // console.log("sssssssssssssssssssssssssssss");
            this.tournamentForm.patchValue({
                //tournament_order_mother_email: mother_email,
                
                tournament_order_player_id: this.playerInfo['player_id'],
                tournament_order_player_last_name: this.player_last_name,
                tournament_order_player_first_name: this.player_first_name,
                tournament_order_player_birthdate: this.player_birth,
                tournament_order_position_played_id: this.player_position_id,
                tournament_order_member_id: this.player_member_id,
                tournament_order_phone: this.player_phone,
                tournament_order_father_email: this.father_email,
                tournament_order_mother_email: this.mother_email,
                tournament_order_remarks:this.remarks,
                tournament_order_cost: this.count,
                tournament: this.tournamentSelect,

                // tom 修改，以下寫法貌似有問題，會令提交數據變成以下格式，修改後提交正常
                // tournament_bus_value: {validator: null, asyncValidator: null, pristine: true, touched: false, _onDisabledChange: [],…}
                // 修改前
                // tournament_order_check_in_date:new FormControl(this.tournament_order_check_in_date),
                // tournament_order_check_out_date:new FormControl(this.tournament_order_check_out_date),
                // tournament_order_hotel_night_number:new FormControl(this.tournament_order_hotel_night_number),
                // tournament_order_hotel_room_number:new FormControl(this.tournament_order_hotel_room_number),
                // tournament_order_hotel_room_tpye:new FormControl(this.tournament_order_hotel_room_tpye),
                // tournament_order_hotel_room_special_request:new FormControl(this.tournament_order_hotel_room_special_request),
                // tournament_order_hotel_check_in_name:new FormControl(this.tournament_order_hotel_check_in_name),
                // tournament_order_hotel_room_set_day:new FormControl(),
                // tournament_bus_value:new FormControl(this.tournamentBusValue),
                // tournament_extra_hotel_value:new FormControl(this.tournamentExtraHotelValue),
                // tournament_room_hotel_value:new FormControl(this.tournamentRoomHotelValue),
                // 修改後
                tournament_order_check_in_date:this.tournament_order_check_in_date,
                tournament_order_check_out_date:this.tournament_order_check_out_date,
                tournament_order_hotel_night_number:this.tournament_order_hotel_night_number,
                tournament_order_hotel_room_number:this.tournament_order_hotel_room_number,
                tournament_order_hotel_room_tpye:this.tournament_order_hotel_room_tpye,
                tournament_order_hotel_room_special_request:this.tournament_order_hotel_room_special_request,
                tournament_order_hotel_check_in_name:this.tournament_order_hotel_check_in_name,
                tournament_order_hotel_room_set_day:"",//tom 此字段貌似無用過，暫時賦初始值
                tournament_bus_value:this.tournamentBusValue,
                tournament_extra_hotel_value:this.tournamentExtraHotelValue,
                tournament_room_hotel_value:this.tournamentRoomHotelValue,
                
                
            });
        }
    }

    getData() {
        this.loading = true;
        const config = {
            "table": "development",
            // "select":"player_name",
            "where": {
                "development_type": 'tournament-detail'
            },
            "return": "row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }

    onSubmit() {
        this.tournamentSelect = [];
        Object.keys(this.objSummber).forEach(i => {
            if (this.objSummber[i]) {
                this.tournamentSelect.push({
                    tournament_select_id: i,
                    tournament_summer_id: this.objSummber[i]
                });
            }
        });


        
        this.tournamentForm.patchValue({
            "tournament_order_cost": this.count,
            "tournament": this.tournamentSelect,
            // "tournament_bus_value": this.tournamentBusValue,
            // "tournament_extra_hotel_value": this.tournamentExtraHotelValue,
            // "tournament_room_hotel_value": this.tournamentRoomHotelValue,
        })

        if (this.tournamentBusValue.length>0) {
            this.tournamentForm.patchValue({ "tournament_bus_value": this.tournamentBusValue });
        }else{
            this.tournamentBusValue = [];
            this.tournamentForm.patchValue({ "tournament_bus_value": this.tournamentBusValue });
        }
        
        if (this.tournamentExtraHotelValue.length>0) {
            this.tournamentForm.patchValue({ "tournament_extra_hotel_value": this.tournamentExtraHotelValue });
        }else{
            this.tournamentExtraHotelValue = [];
            this.tournamentForm.patchValue({ "tournament_extra_hotel_value": this.tournamentExtraHotelValue });
        }
        
        if (this.tournamentRoomHotelValue.length>0) {
            this.tournamentForm.patchValue({ "tournament_room_hotel_value": this.tournamentRoomHotelValue });
        }else{
            this.tournamentRoomHotelValue = [];
            this.tournamentForm.patchValue({ "tournament_room_hotel_value": this.tournamentRoomHotelValue });
        }
        


        if (!this.tournamentForm.valid) {
            alert('Please complete the information');
            this.openDirtyControl();
            return;
        } else {

            this.uploadData();
        }
    }

    openDirtyControl() {
        for (const i in this.tournamentForm.controls) {
            this.tournamentForm.controls[i].markAsDirty();
            this.tournamentForm.controls[i].updateValueAndValidity();
        }
    }

    getPlayerDataSelect(tableName) {
        const config = {
            "table": tableName,
            "where": {},
            "page": "1",
            "perPage": "10000"
        };


        this.dataService.getCommonData(config).then(resp => {
            let returnData = [];
            if (resp.code === 200) {
                resp.data.forEach((val, key) => {
                    returnData.push({'name': val[tableName + '_name'], 'id': val[tableName + '_id']});
                });
                this[tableName] = returnData;
            }
        })
    }

    uploadData() {

        const config = JSON.stringify(this.tournamentForm.value);
        // console.log(config);
        // return;
        this.dataService.postBaseData('tournament_order/tournament', config).then(resp => {
            if (resp.code === 200) {
                //暂时去掉，不然back时信息会空白
                //this.tournamentForm.reset();
                this.showQR = true
                this.getTournamentData()
                this.tournamentOrder()
            }
        })
    }

    getSummerList() {
        this.dataService.getBaseData('TournamentSummer/getSummerList').then(resp => {
            if (resp.code === 200) {
                this.contentDetail = resp.data
                this.SummerListLength = resp.data.length
                this.subcontent = []
                resp.data.map(item => {
                    this.subcontent.push(item.subcontent)
                })
            }

        })

    }

    TournamentCount(){

        let idlength = this.contentDetail.length;
        this.count = 0;

        let bus=0;
        let room=0;
        let extra=0;
        let summer=0;

        Object.keys(this.objSummber).forEach(i => {
            if (this.objSummber[i]) {
                for (var m = 0; m < idlength; m++) {
                    if (i === this.contentDetail[m].course_tournament_content_id) {
                        for (var n = 0; n < this.contentDetail[m].subcontent.length; n++) {
                            if (this.objSummber[i] === this.contentDetail[m].subcontent[n].course_tournament_summer_id) {
                                this.count = (this.count + Number(this.contentDetail[m].subcontent[n].course_tournament_summer_price))
                                summer+=Number(this.contentDetail[m].subcontent[n].course_tournament_summer_price);
                            }
                        }
                    }
                }

            }
        })
        
        console.log('summer',summer);
        //bus 统计
        console.log('tournamentBusValue:',this.tournamentBusValue);
        if(this.tournamentBusValue.length > 0){

            this.tournamentBusValue.forEach((val, key) => {
                console.log(val.price+"==="+val.number);

                this.count = this.count + Number(val.price*val.number);
                bus+=Number(val.price*val.number);
            })
        }
        console.log('bus',bus);
        //hotel 房间类型,数量,天数
        if(this.tournamentRoomHotelValue.length > 0){

            //console.log(this.tournamentRoomHotelValue);

            this.count = this.count + Number(this.tournamentRoomHotelValue[0].price*this.tournamentRoomHotelValue[0].night*this.tournamentRoomHotelValue[0].number);
            room+=Number(this.tournamentRoomHotelValue[0].price*this.tournamentRoomHotelValue[0].night*this.tournamentRoomHotelValue[0].number);

        }
        console.log('room',room);

        if(this.tournamentExtraHotelValue.length > 0){

            this.tournamentExtraHotelValue.forEach((val, key) => {

                this.count = this.count + Number(val.price*val.number);
                extra += Number(val.price*val.number);
            })
        }
        //console.log('tournamentExtraHotelValue',this.tournamentExtraHotelValue);
        console.log('extra',extra);
        console.log('=======');
    }



    TournamentChange(value, type) {

        this.objSummber[type] = value;

        console.log('objSummber',this.objSummber)
        
        this.TournamentCount();

    }

    getTournamentBusData() {
        const config = {
            "table": "course_tournament_bus",
            "return": "result_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.tournamentBus = resp.data;
            }
        })
    }

    getTournamentHotelData() {
        
        
        this.dataService.getBaseData('TournamentSummer/getHotelList').then(resp => {
            if (resp.code === 200) {
                // console.log(resp.data);
                this.tournamentHotel = resp.data;
            }
        })
    }
    



    getTournamentData(type?) {
        
        
        this.dataService.getBaseData('tournament_order/tournament_data').then(resp => {
            if (resp != undefined) {
                if (resp.code === 200) {
                    
                    this.tournamentOderitem = []

                    this.tournamentData = resp.data.tournament
                    //console.log(this.tournamentData);
                    if(resp.data.tournament != null){
                        if (resp.data.tournament.tournamentCourse.length > 0) {
                            this.tournamentOdervalue = true
                            
                            this.showQR = true;
                            
                             window.scroll({ 
           top: 100, 
           left: 0, 
           behavior: 'smooth' 
    });
                            this.tournamentOderIds = resp.data.tournament.tournamentCourse
                            // console.log('tournamentOderIds',this.tournamentOderIds)
                            // console.log('contentDetail',this.contentDetail)
                            if (this.contentDetail.length==0) {
                                this.getSummerList();
                            }
                            for (let j = 0; j < this.tournamentOderIds.length; j++) {
                                if (resp.data.tournament.tournamentCourse[j]) {

                                    for (let i = 0; i < this.SummerListLength; i++) {
                                        if (this.tournamentOderIds[j].z_tournament_course_tournament_select_id === this.contentDetail[i].course_tournament_content_id) {

                                            for (let n = 0; n < this.contentDetail[i].subcontent.length; n++) {
                                                if (this.tournamentOderIds[j].z_tournament_course_course_tournament_summer_id === this.contentDetail[i].subcontent[n].course_tournament_summer_id) {
                                                    this.tournamentOderitem.push((this.contentDetail[i].subcontent[n].course_tournament_summer_name) + '...' + (this.contentDetail[i].subcontent[n].course_tournament_summer_price))

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // console.log("this.tournamentOderitem",this.tournamentOderitem);
                        if(resp.data.tournament.tournamentTypeData.length > 0){
                            this.tournamentTypeArray = [];
                            let tournamentTypeData = resp.data.tournament.tournamentTypeData;
                            let tournamentBusArray = [];
                            let tournamentExtraArray = [];
                            let tournamentRoomArray = [];
                            

                            for (let k = 0; k < tournamentTypeData.length; k++) {

                                if(tournamentTypeData[k].z_tournament_course_type_type == 'bus'){
                                    this.IstournamentBusValue = true;
                                    tournamentBusArray.push((tournamentTypeData[k].z_tournament_course_type_type_name) + '...' +(tournamentTypeData[k].z_tournament_course_type_type_price));
                                }
                                if(tournamentTypeData[k].z_tournament_course_type_type == 'extra'){
                                    this.IstournamentExtraValue = true;

                                    tournamentExtraArray.push((tournamentTypeData[k].z_tournament_course_type_type_name) + '...' +(tournamentTypeData[k].z_tournament_course_type_type_price));
                                }
                                if(tournamentTypeData[k].z_tournament_course_type_type == 'room'){
                                    this.IstournamentRoomValue = true;

                                    tournamentRoomArray.push((tournamentTypeData[k].z_tournament_course_type_type_name) + '...' +(tournamentTypeData[k].z_tournament_course_type_type_price));
                                }
                            }
                            this.tournamentTypeArray.push({"bus":tournamentBusArray});
                            this.tournamentTypeArray.push({"extra":tournamentExtraArray});
                            this.tournamentTypeArray.push({"room":tournamentRoomArray});
                            
                            // console.log(tournamentTypeArray);
                        }
                        // console.log(resp.data.tournament);
                        this.tournamentTypeData = resp.data.tournament.tournamentTypeData;
                        this.tournament_order_player_id = resp.data.tournament.tournament_order_player_id;
                        this.tournament_order_id = resp.data.tournament.tournament_order_id;
                        this.tournament_order_sn = resp.data.tournament.tournament_order_sn;
                        this.tournament_order_status = resp.data.tournament.tournament_order_status;
                        this.tournament_order_hotel_night_number = resp.data.tournament.tournament_order_hotel_night_number;
                        this.tournament_order_hotel_room_number = resp.data.tournament.tournament_order_hotel_room_number;
                        this.tournament_order_check_in_date = resp.data.tournament.tournament_order_check_in_date;
                        this.tournament_order_check_out_date = resp.data.tournament.tournament_order_check_out_date;
                        this.tournament_order_hotel_check_in_name = resp.data.tournament.tournament_order_hotel_check_in_name;
                        this.tournament_order_hotel_room_special_request = resp.data.tournament.tournament_order_hotel_room_special_request;
                        this.tournament_order_hotel_room_tpye = resp.data.tournament.tournament_order_hotel_room_tpye;

                        this.tournament_order_member_name = resp.data.tournament.tournament_order_member_name;
                        // console.log(this.playerInfo['player_id']);
                        // console.log(resp.data.tournament.tournament_order_member_id);

                        this.player_last_name = resp.data.tournament.tournament_order_player_last_name;
                        this.player_first_name = resp.data.tournament.tournament_order_player_first_name;
                        this.player_birth = resp.data.tournament.tournament_order_player_birthdate;
                        this.player_member_id =resp.data.tournament.tournament_order_member_id;
                        this.player_position_id =resp.data.tournament.tournament_order_position_played_id;
                        this.player_phone = resp.data.tournament.tournament_order_phone;
                        this.mother_email = resp.data.tournament.tournament_order_mother_email;
                        this.father_email =resp.data.tournament.tournament_order_father_email;
                        console.log('player_birth',this.player_birth);
                        this.remarks=resp.data.tournament.tournament_order_remarks;



this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("/paypal/?pay=tournament&order_sn=" + this.tournament_order_sn);




                    }
                    console.log('order_id',this.tournament_order_id);
                    
                    if(type){

                        this.getTournamentBusData();
                        this.getTournamentHotelData();
                        this.initTournamentData(resp.data.tournament);
                        console.log('type');
                    }
                    console.log('getTournamentData')

                }
            }
        })

}

back() {
    this.showQR = false
    this.count = 0

    if (this.Interval) {
        clearInterval(this.Interval);
    }
    
    //console.log(this.tournamentOderIds);
    this.tournamentBusValue = [];
    this.tournamentExtraHotelValue = [];
    this.tournamentRoomHotelValue = [];
    //重新對原本的值賦值
    for (let j = 0; j < this.subcontent.length; j++) {
        let subcontentArray = this.subcontent[j];
        for (let i = 0; i < subcontentArray.length; i++) {
            
            for (let s = 0; s < this.tournamentOderIds.length; s++) {
                
                if(this.tournamentOderIds[s].z_tournament_course_course_tournament_summer_id == subcontentArray[i].course_tournament_summer_id){

                    // this.objSummber[type] = value;
                    this.TournamentChange(this.tournamentOderIds[s].z_tournament_course_course_tournament_summer_id,this.tournamentOderIds[s].z_tournament_course_tournament_select_id);
                    this.subcontent[j][i].course_tournament_is_selected = true;
                }
            }
        }
    }


    if(this.tournamentTypeData.length > 0){


        if(this.tournamentBus.length > 0){

            for (let j = 0; j < this.tournamentBus.length; j++) {

                this.tournamentBus[j].course_tournament_bus_is_checked = "";

                for (let i = 0; i < this.tournamentTypeData.length; i++) {

                    if(this.tournamentTypeData[i].z_tournament_course_type_type == "bus" && (this.tournamentTypeData[i].z_tournament_course_type_type_id == this.tournamentBus[j].course_tournament_bus_id)){


                        let busArray = { "bus_id":this.tournamentBus[j].course_tournament_bus_id,
                        "price":this.tournamentBus[j].course_tournament_bus_price,
                        "number":this.tournamentTypeData[i].z_tournament_course_type_type_number};
                        
                        this.tournamentBusValue.push(busArray);

                        this.tournamentBus[j]['course_tournament_bus_is_checked'] = true;
                        this.tournamentBus[j].course_tournament_bus_number = this.tournamentTypeData[i].z_tournament_course_type_type_number;
                    }
                }
            }

        }
        console.log('tournamentBusValue',this.tournamentBusValue);
        
        if(this.tournamentHotel.length > 0){
            this.tournamentHotel.forEach((hotelData)=>{
                hotelData['course_tournament_hotel_selected']=false;
                //console.log('tournamentHotel',hotelData);
                if(hotelData.hotel_type.length){
                    hotelData.hotel_type.forEach(typeData=>{
                        typeData['course_tournament_hotel_is_checked'] = "";
                        typeData['course_tournament_hotel_number'] = 0;
                        //console.log("I AM FOR =====");
                        if(this.tournamentTypeData.length){
                            this.tournamentTypeData.forEach(tournamentTypeData=>{

                                //extra
                                if(tournamentTypeData.z_tournament_course_type_type == "extra"){
                                    if(tournamentTypeData.z_tournament_course_type_type_id == typeData.course_tournament_hotel_type_id){
                                        let hotelArray = { "hotel_type_id":typeData.course_tournament_hotel_type_id,
                                        "price":typeData.course_tournament_hotel_type_price,
                                        "number":tournamentTypeData.z_tournament_course_type_type_number};

                                        this.tournamentExtraHotelValue.push(hotelArray);
                                        typeData['course_tournament_hotel_is_checked'] = "checked";

                                        //this.tournamentHotel[j].course_tournament_hotel_selected = true;
                                        //console.log('yes',tournamentTypeData.z_tournament_course_type_type_id+"=="+ typeData.course_tournament_hotel_type_id);
                                        
                                        typeData['course_tournament_hotel_number'] = tournamentTypeData.z_tournament_course_type_type_number;
                                        return true;
                                    }else{

                                        //typeData['course_tournament_hotel_number'] = 0;

                                        //console.log('no',tournamentTypeData.z_tournament_course_type_type_id+"=="+ typeData.course_tournament_hotel_type_id);
                                    }
                                    
                                }

                                //room
                                if(tournamentTypeData.z_tournament_course_type_type == "room" ){


                                    if(tournamentTypeData.z_tournament_course_type_type_id == typeData.course_tournament_hotel_type_id){
                                        this.tournamentRoomHotelValue.push({"hotel_type_room_id":typeData.course_tournament_hotel_type_id,
                                            "price":typeData.course_tournament_hotel_type_price,
                                            "night":this.tournament_order_hotel_night_number,
                                            "number":this.tournament_order_hotel_room_number});


                                        //this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_is_checked = "checked";
                                        typeData['course_tournament_hotel_is_checked'] = "checked";
                                        //console.log('yes');
                                        
                                        hotelData['course_tournament_hotel_selected']=true;

                                        this.tournament_order_hotel_id = hotelData.course_tournament_hotel_id;
                                        
                                        typeData.course_tournament_hotel_number = tournamentTypeData.z_tournament_course_type_type_number;


                                        this.tournament_show_check_info = true;
                                    }else{

                                        //typeData['course_tournament_hotel_number'] = 0;


                                        //console.log('no');
                                    }
                                    
                                }




                            })

                        }


                    })
                }
            })
}

//console.log("tournamentHotel2",this.tournamentHotel);return;

//console.log("tournamentTypeData",this.tournamentTypeData);
            /*
            for (let j = 0; j < this.tournamentHotel.length; j++) {

                let tournamentHotelArray = this.tournamentHotel[j].hotel_type;
                for (let s = 0; s < tournamentHotelArray.length; s++) {

                    for (let i = 0; i < this.tournamentTypeData.length; i++) {

                        //this.tournamentHotel[j].course_tournament_hotel_selected = false;

                        //if(this.tournamentTypeData[i].z_tournament_course_type_type == "extra" && (this.tournamentTypeData[i].z_tournament_course_type_type_id == tournamentHotelArray[s].course_tournament_hotel_type_id)){

                        if(this.tournamentTypeData[i].z_tournament_course_type_type == "extra"){

                            if(this.tournamentTypeData[i].z_tournament_course_type_type_id == tournamentHotelArray[s].course_tournament_hotel_type_id){
                                let hotelArray = { "hotel_type_id":tournamentHotelArray[s].course_tournament_hotel_type_id,
                                                "price":tournamentHotelArray[s].course_tournament_hotel_type_price,
                                                "number":this.tournamentTypeData[i].z_tournament_course_type_type_number};
                                this.tournamentExtraHotelValue.push(hotelArray);
                                this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_is_checked = "checked";
                               
                                //this.tournamentHotel[j].course_tournament_hotel_selected = true;
                                console.log('yes');
                                
                                this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_number = this.tournamentTypeData[i].z_tournament_course_type_type_number;
                            }else{
                                //this.tournamentHotel[j].course_tournament_hotel_selected = false;
                                this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_is_checked = "";
                                this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_number = 0;

                                console.log('no');
                            }
                            
                            
                        }

                        //if(this.tournamentTypeData[i].z_tournament_course_type_type == "room" && (this.tournamentTypeData[i].z_tournament_course_type_type_id == tournamentHotelArray[s].course_tournament_hotel_type_id)){
                        console.log(this.tournamentTypeData[i].z_tournament_course_type_type+"==="+this.tournamentTypeData[i].z_tournament_course_type_type_id+"==="+tournamentHotelArray[s].course_tournament_hotel_type_id);


                        if(this.tournamentTypeData[i].z_tournament_course_type_type == "room" ){


                            if(this.tournamentTypeData[i].z_tournament_course_type_type_id == tournamentHotelArray[s].course_tournament_hotel_type_id){
                                this.tournamentRoomHotelValue.push({"hotel_type_room_id":tournamentHotelArray[s].course_tournament_hotel_type_id,
                                                        "price":tournamentHotelArray[s].course_tournament_hotel_type_price,
                                                        "night":this.tournament_order_hotel_night_number,
                                                        "number":this.tournament_order_hotel_room_number});


                                //this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_is_checked = "checked";
                                this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_is_checked = "checked";
                                //console.log('yes');
                               
                                this.tournamentHotel[j]['course_tournament_hotel_selected'] = true;
                               
                                this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_number = this.tournamentTypeData[i].z_tournament_course_type_type_number;
                            }else{
                                this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_is_checked = "";
                                //this.tournamentHotel[j].course_tournament_hotel_selected = false;
                                this.tournamentHotel[j].hotel_type[s].course_tournament_hotel_number = 0;


                                //console.log('no');
                            }
                            
                        }
                    }
                }
            }
            */

            //}
            console.log('tournament_order_hotel_room_number',this.tournament_order_hotel_room_number);
            console.log('player_first_name',this.player_first_name);

        }

        // console.log(this.tournamentExtraHotelValue);
        // console.log(this.tournamentRoomHotelValue);
        // console.log(this.tournamentBusValue);
        

        this.TournamentCount();
        console.log("tournamentHotel",this.tournamentHotel);





    }

    tournamentOrder() {
        //this.getTournamentData();
        // this.Interval = setInterval((val) => {
            //     this.getTournamentData();
            // }, 10000)
            //1000
        }

        ngOnDestroy() {
            if (this.Interval) {
                clearInterval(this.Interval);
            }
        }

        openModal(template) {
            this.modalRef = this.modalService.show(template);
        }

        isSmartDevice() {
            var userAgentInfo = navigator.userAgent;
            var mobileAgents = ["Android", "iPhone", "iPad", "iPod", "Silk", "BlackBerry", "Opera Mini", "IEMobile"];
            var flag = false;
            //根据userAgent判断是否是手机
            for (var v = 0; v < mobileAgents.length; v++) {
                if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                    flag = true;
                    break;
                }
            }
            return flag;
        }


        tournamentPayment(status) {


            let is_mobile = this.isSmartDevice();

            const config = JSON.stringify({
                player_id: this.tournament_order_player_id,
                order_id: this.tournament_order_id,
                order_sn: this.tournament_order_sn,
                status: status,
                is_mobile: is_mobile,
            });

            this.dataService.postBaseData('pay/tournament_payment_before', config).then(resp => {

                if (resp != undefined) {
                    if (resp.code === 200) {
                        if (resp.data.pay_url) {
                            this.tournament_order_sn = resp.data.order_sn;
                            clearInterval();

                            if (status == 'alipay') {
                                if (!is_mobile) {
                                    this.alipaylink = this.sanitizer.bypassSecurityTrustResourceUrl(resp.data.pay_url);
                                    this.openModal(this.templateView);
                                    return;
                                }
                            }
                            if (status == 'wechat') {
                                if (!is_mobile) {
                                    this.alipaylink = this.sanitizer.bypassSecurityTrustResourceUrl(resp.data.pay_url);
                                    this.openModal(this.template_wechat);
                                    return;
                                }
                            }
                            window.open(resp.data.pay_url, '_self');
                        }
                    }
                }
            })

        }

        //bus 多选框
        checkBus(event,bus_id,price){
            
            var busCheck = event.target;
            var busOne = busCheck.parentNode.parentNode.querySelector(".course_tournament_checkbox_number");

            console.log("this.tournamentBusValue",this.tournamentBusValue);
            if(busCheck.checked){
                var busArray = { "bus_id":bus_id,"price":price,"number":1};
                
                this.tournamentBusValue.push(busArray);
                busOne.value = 1;

            }else{

                this.tournamentBusValue.forEach((val, key) => {

                    if(val.bus_id == bus_id){
                        this.tournamentBusValue.splice(key,1);
                    }
                })
                busOne.value = 0;
            }

            this.TournamentCount();

        }

        //bus 填寫數值
        tournamentBusPassenger(event,bus_id,price){

            var bus_passenger = event.target.value;
            
            var busOne = event.target.parentNode.parentNode.querySelector(".course_tournament_checkbox");
            if(!busOne.checked){

                busOne.checked = true;
            }
            if(bus_passenger <= 0 || bus_passenger == ""){
                bus_passenger = 0;
                busOne.checked = false;
            }

            this.tournamentBusValue.forEach((val, key) => {

                if(val.bus_id == bus_id){
                    this.tournamentBusValue[key].number = bus_passenger;
                }
            })
            this.TournamentCount();
        }


        checkExtraHotel(event,hotel_type_id,price,rowData){
            var hotelCheck = event.target;
            //console.log(rowData,event)
            rowData['course_tournament_hotel_is_checked']=event.target.checked?'checked':'';
            var hotelOne = hotelCheck.parentNode.parentNode.querySelector(".course_tournament_checkbox_hotel_number_"+hotel_type_id);
            // console.log(hotelOne);
            if(hotelCheck.checked){
                var hotelArray = { "hotel_type_id":hotel_type_id,"price":price,"number":1};

                this.tournamentExtraHotelValue.push(hotelArray);
                hotelOne.value = 1;
            }else{
                this.tournamentExtraHotelValue.forEach((val, key) => {

                    if(val.hotel_type_id == hotel_type_id){
                        this.tournamentExtraHotelValue.splice(key,1);
                    }
                })   
                hotelOne.value = 0;

            }
            this.TournamentCount();
            console.log('checkExtraHotel',this.tournamentExtraHotelValue)
        }

        changeHotelExtraday(event,hotel_type_id,price){

            var hotel_extraday = event.target.value;

            var hotelOne = event.target.parentNode.parentNode.querySelector(".course_tournament_checkbox_hotel_id_"+hotel_type_id);
            if(!hotelOne.checked){

                hotelOne.checked = true;
            }
            if(hotel_extraday <= 0 || hotel_extraday == ""){
                hotel_extraday = 0;
                hotelOne.checked = false;
            }

            this.tournamentExtraHotelValue.forEach((val, key) => {

                if(val.hotel_type_id == hotel_type_id){
                    this.tournamentExtraHotelValue[key].number = hotel_extraday;
                }
            })
            this.TournamentCount();


        }

        tournamentHotelNumber(event,type){

            var HotelNumber = event.target.value;
            
            //add by alice
            if(HotelNumber<=0){
                event.target.value = 1;
                HotelNumber =1;
            }
            console.log('HotelNumber',HotelNumber);
            if(this.tournamentRoomHotelValue.length > 0){

                if(type == "night"){
                    this.tournamentRoomHotelValue[0].night = HotelNumber;
                }else{
                    this.tournamentRoomHotelValue[0].number = HotelNumber;

                }
            }
            this.TournamentCount();
        }



        checkRoomHotel(hotel_type_id,price,event,type_index,hotel_id){
            //console.log('hotel_id',hotel_id,type_index);
            //console.log('sindex',sindex);

            //console.log('type_index',this.tournamentHotel[type_index].hotel_type,this.tournamentExtraHotelValue)


        /*
        this.tournamentHotel[type_index].hotel_type.forEach((type,num)=>{
            //this.tournamentExtraHotelValue.forEach((value,index)=>{
               //if(type.course_tournament_hotel_type_id)
            //})
            for(let index =this.tournamentExtraHotelValue.length;index>=0,index--){
                if(type.course_tournament_hotel_type_id===this.tournamentExtraHotelValue[index]['hotel_type_id']){
                    var hotelArray = { "hotel_type_id":hotel_type_id,"price":price,"number":1};

                    this.tournamentExtraHotelValue.push(hotelArray);
                }else{
                    this.tournamentExtraHotelValue.splice(key,1);
                }
            }
        })
        */

        var room_type = event.target.checked;

        var all_room  = event.target.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".course_tournament_hotel_type_id");

        var old_hotel_id = this.tournament_order_hotel_id;

        var new_hotel_id = hotel_id;



        
        //取消所有的room type checkbox
        all_room.forEach((obj, key) => {

            obj.checked = false;
            
        })

        event.target.checked = room_type;

        if(room_type){

            //this.tournamentHotel[type_index].course_tournament_hotel_selected = true;
            this.tournament_show_check_info = true;
            //如果之前有选其他room type，则需要移除他们下的extra
            this.hideOtherExtra(type_index);

            this.tournament_order_hotel_id= new_hotel_id;
            //重新计算 extra的值

            //this.recountExtraCount(type_index,1);
            //console.log('true:'+this.tournament_show_check_info);
        }else{

            //this.tournamentHotel[type_index].course_tournament_hotel_selected = false;
            this.tournament_show_check_info = false;
            this.clearCheckInfo();
            //移除所有的extra
            this.hideOtherExtra(-1);

            this.tournament_order_hotel_id='';
            //清空 extra的值
            //this.recountExtraCount(type_index,0);
            //console.log('false:'+this.tournament_show_check_info);
        }
        this.clearAllExtra(old_hotel_id,new_hotel_id);

        console.log('type_index',this.tournamentExtraHotelValue)


        this.tournamentRoomHotelValue = [];

        this.tournament_order_hotel_night_number = Number(this.tournament_order_hotel_night_number);
        this.tournament_order_hotel_room_number = Number(this.tournament_order_hotel_room_number);

        if(this.tournament_order_hotel_night_number == 0  || this.tournament_order_hotel_night_number == null){
            this.tournament_order_hotel_night_number = 1;
        }

        if(this.tournament_order_hotel_room_number == 0  || this.tournament_order_hotel_room_number == null){
            this.tournament_order_hotel_room_number = 1;
        }

        this.tournamentRoomHotelValue.push({"hotel_type_room_id":hotel_type_id,"price":price,"night":this.tournament_order_hotel_night_number,"number":this.tournament_order_hotel_room_number});

        this.TournamentCount();
        
        
    }
    

    //清空check 信息
    clearCheckInfo(){
        this.tournament_order_hotel_check_in_name = "";
        this.tournament_order_check_in_date = "";
        this.tournament_order_check_out_date = "";
        this.tournament_order_hotel_night_number = 0;
        this.tournament_order_hotel_room_number = 0;
        this.tournament_order_hotel_room_special_request = "";
    }

    //隐藏其他的extra
    hideOtherExtra(sindex){
        console.log('hideother');
        if(this.tournamentHotel.length > 0){
            this.tournamentHotel.forEach((hotelData, key)=>{
                if(sindex == key && sindex>=0){
                    hotelData['course_tournament_hotel_selected'] = true;
                }else{
                    hotelData['course_tournament_hotel_selected'] = false;
                }
            })
        }
    }


    clearAllExtra(old_hotel_id,new_hotel_id){

        if(old_hotel_id !== new_hotel_id){
            
            if(this.tournamentHotel.length){
                this.tournamentHotel.forEach((hotelData,num)=>{

                    if(hotelData.hotel_type.length){
                        hotelData.hotel_type.forEach((typeData,num)=>{
                            typeData['course_tournament_hotel_is_checked'] = "";
                            typeData['course_tournament_hotel_number'] = 0;
                        })
                    }
                })
            }

            this.tournamentExtraHotelValue = [];
        }
        


    }
    
    

    //重新计算 extra 的count 值
    /*
    recountExtraCount(type_index,type){
        console.log('recount');
        //this.tournamentExtraHotelValue = [];

        if(type==0){
            //移除所有extra
            this.tournamentExtraHotelValue = [];
            
        }else{

            if(this.tournamentExtraHotelValue.length){

                this.tournamentHotel[type_index].hotel_type.forEach((type,num)=>{

                    for(let index =this.tournamentExtraHotelValue.length;index>=0;index--){
                        if(type.course_tournament_hotel_type_id===this.tournamentExtraHotelValue[index]['hotel_type_id']){

                            if(type.course_tournament_hotel_is_checked=='checked'){
                                var hotelArray = { "hotel_type_id":hotel_type_id,"price":price,"number":1};

                                this.tournamentExtraHotelValue.push(hotelArray);
                            }else{

                                this.tournamentExtraHotelValue.splice(index,1);

                            }

                            
                        }else{
                            this.tournamentExtraHotelValue.splice(index,1);
                        }
                    }

                })

            }

        }

       // console.log('recountExtraCount',this.tournamentExtraHotelValue);

    }
    */


    onPhoneup(event) {
        let input = event.target;
        console.log(input.value);
        let value = input.value;
        let re = /^[1-9]\d*$/;
        if (!re.test(value)) {
            //return false;
            input.value = '';
        }

        //let value = input.value.replace(/^[1-9]\d*$/, '');
        
        //var arr = value.split('');
        //input.value = arr.join('');
        // 输完11位之后
        //let phone = event.target.value.replace(/\s+/g, '');
    }

    /*
    onPhonepress(event) {

        // 判断是否为数字
        let inputStr = String.fromCharCode(event.keyCode);
        console.log('inputStr',inputStr);
        let re = /[1-9]/; //判断字符串是否为数字
        if (!re.test(inputStr)) {
            return false;
        }
    }
    */




}
