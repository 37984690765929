import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

    loading: boolean = true;
    datalist: any;
    iconList=[false,false,false,false];

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();

    }
    getData(){
        const config={
            about_type:'payments'
        };
        this.dataService.getBaseData('about',config).then(resp => {
            this.datalist=resp.data;
            this.loading = false;
        })
    }
}
