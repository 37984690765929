import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();
    }

    getData(){
        this.dataService.getBaseData('photo').then(resp => {
            if(resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }
}
