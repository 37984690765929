import {Component, OnInit, Directive, ViewChild, ElementRef, Output, EventEmitter, HostListener} from '@angular/core';
import {ActivatedRoute, Params, Router, NavigationEnd} from "@angular/router";
import {DataService} from "../../../@core/data/data.service";
import {GlobalsService} from "../../../@core/data/globals.service";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
// @Directive({
//     selector: '[clickOutside]'
// })

export class HeaderComponent implements OnInit {
    isTop: boolean = false;
    showSmRight: boolean = false;
    toTopActive: boolean = false;
    user: any;
    is_open_register_league: boolean = false;
    show_annual: boolean = false;
    show_camps: boolean = false;
    show_tournament_menu: boolean = false;
    show_tournament_form: boolean = false;

    annual_banquet_menu = 'Annual Banquet';
    elite_edgework_memu = 'ELITE EDGEWORK';

    cityLeagueData = [];
    leagueArchive = [];
    selectList = [];
    campTitle: string = 'development';
    routerObj = {
        'home': 'home',
        'tournament': 'tournament',
        'news': 'about',
        'news-archive': 'about',
        'coaches': 'about',
        'history': 'about',
        'payments': 'about',
        'league-policies': 'about',
        'severe-weather': 'about',
        'photo': 'about',
        'photo-detail': 'about',
        'banquet': 'about',
        'contact': 'about',
        'league-info': 'league',
        'select': 'select',
        'city-league': 'league',
        'registered-league': 'league',
        'training-and-development': 'training',
        'synthetic-ice': 'training',
        'camps': 'training',
        'camps-detail': 'training',
    };
    routerActive = 'home';

    constructor(private activatedRoute: ActivatedRoute,
                public dataService: DataService,
                private router: Router,
                private globals: GlobalsService) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let str: any = event['url'];
                str = event['url'].replace('/', '');
                if (str.indexOf('/') > -1) {
                    str = str.replace('/', '>');
                    str = str.match(/(\S*)>/)[1];
                }
                this.routerActive = str;
                // const token = localStorage.getItem('token');
                // if (token && token != 'null') {
                //     const user = JSON.parse(localStorage.getItem("user"));
                //     if (user) {
                //         this.user = user;
                //     } else {
                //         this.getProfile();
                //     }
                // } else {
                //     this.user = '';
                // }
                const W = document.body.clientWidth;
                if (W < 1025) {
                    this.showSmRight = false
                }
            }
        })


    }

    @ViewChild('sidebarSm', {static: false}) sidebarSmView: ElementRef;

    @Output()
    public clickOutside = new EventEmitter();

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const W = document.body.clientWidth;
        if (this.sidebarSmView && W < 1025) {
            const clickedInside = this.sidebarSmView.nativeElement.contains(targetElement);
            if (!clickedInside) {
                this.clickOutside.emit(null);
                this.showSmRight = false
            }
        }
    }

    /**
     * thisIsFunction
     * 页面滚动监听
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date    2018-11-22
     */
    @HostListener('window:scroll', ['$event']) public onScroll = ($event) => {

        //客户端高度
        const clientW = document.documentElement.clientWidth;
        //客户端高度
        const clientH = document.documentElement.clientHeight;
        //body高度
        const bodyH = document.body.clientHeight;
        //滚动的高度
        const scrollTop = document.documentElement.scrollTop || window.pageYOffset;

        //滚动到顶部
        if (clientW > 1025 && scrollTop > 0) {
            this.isTop = true
        } else {
            this.isTop = false
        }
        if (scrollTop > 1300) {
            this.toTopActive = true
        } else {
            this.toTopActive = false
        }
    };


    ngOnInit() {
        this.globals.globalVar.subscribe(d => {
            // this.clumb = d;
            if (d && d.length && d[0].img) {
                this.user.player_photo = d[0].img
            }
            if (d && d.length && d[0].getInfo) {
                const token = localStorage.getItem('token');
                if (token && token != 'null') {
                    this.getProfile();
                } else {
                    this.user = '';
                }
            }
        });
        if (localStorage.getItem("user")) {
            this.user = JSON.parse(localStorage.getItem("user"))
            //console.log('user',this.user);
        }
        this.getcityLeagueData();
        this.getRegisteredLeague();
        this.getSelectList();
        this.getCampsDetail();
        this.getAnnualMenu();
        this.getEliteMenu();
    }



    toTop() {
        window.scrollTo(0, 0)
    }


    getAnnualMenu() {
        let config = {
            "table": "development",
            "where": {
                "development_type": 'annual-banquet-detail'
            },
            "return": "row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                if(resp.data.development_link){
                    this.annual_banquet_menu = resp.data.development_link;
                }
                
            }
        })
   }

    getEliteMenu() {
        let config = {
            "table": "development",
            "where": {
                "development_type": 'typhoons-elite-edgework-training'
            },
            "return": "row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                if(resp.data.development_link){
                    this.elite_edgework_memu = resp.data.development_link;
                }
                
            }
        })
   }

    /**
     * thisIsFunction
     * 获取个人资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    getProfile() {
        this.dataService.getBaseData('player/getProfile').then(resp => {
            if (resp != undefined) {
                if (resp.code === 200) {
                    localStorage.setItem("user", JSON.stringify(resp.data));
                    this.user = resp.data;
                }
            }
        })
    }

    getcityLeagueData() {
        const config = {};
        this.dataService.getBaseData('league/headerLeague').then(resp => {
            if (resp != undefined) {
                if (resp.code == 200) {
                    resp.data.forEach((val) => {
                        if (val.league_type === "league_archive") {
                            this.leagueArchive.push(val)
                        } else {
                            this.cityLeagueData.push(val)
                        }
                    })
                    // this.cityLeagueData = resp.data;
                }
            }
        })
    }

    getRegisteredLeague() {
        const config = {
            "table": "setting",
            // "select":"player_name",
            "where": {},
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                resp.data.forEach((val) => {
                    if (val.setting_key === 'is_open_register_league') {
                        this.is_open_register_league = val.setting_value === 'Y';
                    }
                    if (val.setting_key === 'show_annual') {
                        this.show_annual = val.setting_value === 'Y';
                    }
                    if (val.setting_key === 'show_camps') {
                        this.show_camps = val.setting_value === 'Y';
                    }
                    if (val.setting_key === 'show_tournament_menu') {
                        this.show_tournament_menu = val.setting_value === 'Y';
                    }
                    if (val.setting_key === 'show_tournament_form') {
                        this.show_tournament_form = val.setting_value === 'Y';
                    }
                });
            }
        })
    }

    getSelectList() {
        const config = {
            "table": "selection_home",
            "where": {},
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.selectList = resp.data;
            }
        })
    }

    getCampsDetail() {
        // let config = {
        //     "table": 'development',
        //     "where": {
        //         "development_type": 'camps-detail'
        //     },
        //     "return": "row_array"
        // };
        // this.dataService.getCommonData(config).then(resp => {
        //     this.campTitle = resp.data.development_link
        //     // this.editModel = resp.datalist;
        // })



        const config = {
            "table": "setting",
            // "select":"player_name",
            "where": {},
            "perPage": "10000"
        };

   this.dataService.getCommonData(config).then(resp => {

            if (resp.code === 200) {
                resp.data.forEach((val) => {
                    if (val.setting_key === 'camps_menu_title') {
                        this.campTitle = val.setting_value;
                    }

                });
            }
  })


    }

    mobileAngleDown(event) {
        const path = event.path || (event.composedPath && event.composedPath()) || '';
        const str = path[2].classList.toString();
        if (str.indexOf("open") != -1) {
            path[2].classList.remove('open');
        } else {
            path[2].classList.add('open');
        }
    }
}
