import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

export const EMAILPATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';


@Component({
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    contactForm: FormGroup;
    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
        this.contactForm = new FormGroup({
            // first_name: new FormControl('', [Validators.required]),
            // last_name: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.pattern(EMAILPATTERN)]),
            content: new FormControl('', [Validators.required]),
        });
    }

    ngOnInit() {
        this.getData()
    }

    getData() {
        const config = {
            about_type: 'contact-us'
        };
        this.dataService.getBaseData('about', config).then(resp => {
            if (resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }

    uploadData() {
        const config = this.contactForm.value;
        this.dataService.postBaseData('Contactus/Contactussend', config).then(resp => {
            if (resp.code === 200) {
                alert('Send Success');
                this.contactForm.reset();
            }
        })
    }
}
