import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();

    }
    getData(){
        this.loading = false;
    }
}
