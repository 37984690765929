import { Component, OnInit } from '@angular/core';

import {DataService} from "../../../@core/data/data.service";
import {GlobalsService} from "../../../@core/data/globals.service";

@Component({
  selector: 'app-partners-box',
  templateUrl: './partners-box.component.html',
  styleUrls: ['./partners-box.component.scss']
})
export class PartnersBoxComponent implements OnInit {


	dataSponsors: any;

    constructor(public dataService: DataService,
                public globals: GlobalsService,
        ) {
    }

	ngOnInit() {	

	  	this.getData();
	}


    getData(){
        const config={
            "table":"sponsor",
            // "select":"player_name",
            "where":{
            },
            "page":"1",
            "perPage":"10000"
        };
        this.dataService.getCommonData(config).then(resp => {


            if(resp.code === 200) {
                  
                resp.data.forEach((val, key) => {
                   
                    resp.data[key].sponsor_logo = this.globals.apiUrl+val.sponsor_logo;
                })

                this.dataSponsors = resp.data;
            }
        })
    }

}
