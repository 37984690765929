import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
    selector: 'app-training-and-development',
    templateUrl: './training-and-development.component.html',
    styleUrls: ['./training-and-development.component.scss']
})
export class TrainingAndDevelopmentComponent implements OnInit {

    loading: boolean = true;
    datalist: any;
    type: any='';
    headerTitle:string='';
    elite_edgework_detail ='Training and Development';


    constructor(public dataService: DataService, private activatedRoute: ActivatedRoute, private router: Router) {
        //获取参数值
        this.type = this.activatedRoute.snapshot.params['id'];
        this.formatName();

        //订阅路由参数，路由传递id改变之后执行
        this.activatedRoute.params.subscribe((params:Params)=>{this.type=params["id"];this.formatName();this.getData();});
    }

    ngOnInit() {
        this.getData();
    }

    formatName() {

    }

    getData(){
        //console.log('type',this.type);
        const config={
            "table":"development",
            // "select":"player_name",
            "where":{
                "development_type": this.type
            },
            "return":"row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if(resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
                if(this.type=='typhoons-elite-edgework-training' && resp.data.development_link){
                    this.elite_edgework_detail = resp.data.development_link;
                }
                else{
                    this.elite_edgework_detail = 'Training and Development';
                }
            }
            //console.log('elite_edgework_detail',this.elite_edgework_detail);

        })
    }

}
