import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Headers, Http} from "@angular/http";

import {Subscription} from 'rxjs';
import {IMqttMessage, MqttService} from 'ngx-mqtt';


@Component({
    selector: 'app-city-league',
    templateUrl: './technical-statistics.component.html',
    styleUrls: ['./technical-statistics.component.scss']
})
export class TechnicalStatisticsComponent implements OnInit {

   loading: boolean = true;
   datalist: any = [];
   //  //     {
   //  //         team1Title: 'Hk Tyhhoons',
   //  //         team1Image: 'assets/images/logo_sm1.png',
   //  //         team1Num: '0',
   //  //         team2Num: '0',
   //  //         team2Title: 'Warriors',
   //  //         team2Image: 'assets/images/other_logo_2.png',
   //  //         date: 'Dec 26, 2019 | 5：00 pm',
   //  //         address: 'Singapore-Ice Dragons Classic Tournament',
   //  //         time: '13:00',
   //  //         status: '1',
   //  //     },
   //  // ];

   leagueInfo: Array<any> = [];
   subscription: any;
   limit = 5;

   constructor(private modalService: BsModalService,
                public dataService: DataService,
                private formBuilder: FormBuilder,
                private httpClient: HttpClient,
                private _mqttService: MqttService,
                private http: Http) {
    }


    ngOnInit() {
        this.loading = false;
        this.getLeague();


    }

     /**
     * thisIsFunction
     * 获取今年联赛资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    getLeague() {

        this.loading = true;
        const config = {
            "table": "league",
            "where": {
                "league_is_disabled":"N",
            },
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {

            if (resp.code === 200) {
                this.loading = false;
                resp.data.forEach((val) => {
                    const starttime=val.league_starttime.slice(0,10);
                    const year = new Date(starttime).getFullYear();
                    const thisYear = new Date().getFullYear();

                    if (year == thisYear) {
                        this.leagueInfo = val;
   
                    }
                });
                this.getData();
            }
        })
    }


    getData(){
        if(this.leagueInfo){
            const config = {
                "league_id": this.leagueInfo['league_id'],
                "limit":this.limit,
            };
            this.dataService.getBaseData('Game/league_schedule',config).then(resp => {
                if(resp.code == 200){

                    resp.data.forEach((val,key) =>{

                      this.mqttSubscription(val.game_home_team_id,val.game_visiting_team_id,val.game_id);

                    })


                    this.datalist = resp.data;
                    this.loading  = false;
                }
                
            })
        }   
    }

    mqttSubscription(homeTeamId,visitTeamId,gameId){

      let topic = homeTeamId+'-'+visitTeamId+'-'+gameId;

      this.subscription = this._mqttService.observe(topic).subscribe((message: IMqttMessage) => {
            let messageString = message.payload.toString();
            let massageData = JSON.parse(messageString);

            this.datalist.forEach((val,key) =>{

                  if(val.game_home_team_id == massageData.homeTeam.team_id 
                     && val.game_visiting_team_id == massageData.visitingTeam.team_id 
                     && val.game_id == massageData.gameId
                  ){

                     this.datalist[key].homeVisitingInfo.team_score = massageData.visitingTeam.score;
                     this.datalist[key].homeTeamInfo.team_score = massageData.homeTeam.score;


                  }


             })
      });
    }


    loadMore(){

      let num = '5';

      this.limit = this.limit +parseInt(num);
      this._mqttService.disconnect();
      this.getData();

    }


    ngOnDestroy(){

      this._mqttService.disconnect();
    }

}
