import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
    selector: 'app-coaches',
    templateUrl: './coaches.component.html',
    styleUrls: ['./coaches.component.scss']
})
export class CoachesComponent implements OnInit {
    loading:boolean=true;
    datalist:any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData()
    }
    getData(){
        const config={
            "table":"coaches",
            "page":"1",
            "perPage":"10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            if(resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
        // this.dataService.getBaseData('coaches').then(resp => {
        //     this.datalist=resp.data;
        //     this.loading = false;
        // })
    }

}
