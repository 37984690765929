import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {GlobalsService} from "../../../@core/data/globals.service";

@Component({
  selector: 'app-sponsorship',
  templateUrl: './sponsorship.component.html',
  styleUrls: ['./sponsorship.component.scss']
})
export class SponsorshipComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService,
                public globals: GlobalsService
        ) {
    }

    ngOnInit() {
        this.getData()
        this.globals.apiUrl;

    }

    getData(){
        const config={
            "table":"sponsor",
            // "select":"player_name",
            "where":{
            },
            "page":"1",
            "perPage":"10000"
        };
        this.dataService.getCommonData(config).then(resp => {


            if(resp.code === 200) {
                  
                resp.data.forEach((val, key) => {
                   
                    resp.data[key].sponsor_logo = this.globals.apiUrl+val.sponsor_logo;
                })

                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }
}
