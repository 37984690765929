import {Component, OnInit} from '@angular/core';
import {NgwWowService} from 'ngx-wow';
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';
import {DataService} from "../../../@core/data/data.service";
import {Router, ActivatedRoute} from "@angular/router";


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    subscribeForm: FormGroup;
    emailvalue: any;

    constructor(public dataService: DataService, private router: Router, private wowService: NgwWowService) {
    }

    ngOnInit() {
        this.wowService.init();
        this.subscribeForm = new FormGroup({
            subscribe_email: new FormControl('', [Validators.required]),
        });
    }


    subscribeEmail() {
        let config = this.subscribeForm.value;
        this.dataService.postBaseData('code/subscribe', config).then(resp => {
            if (resp.code == 200) {
                alert('Subscribe Success');
            }
            // this.subscribeForm.value.subscribe_email = '';
            this.emailvalue = null;
        })
    }

    unsubscribeEmail() {
        let config = this.subscribeForm.value;
        this.dataService.postBaseData('code/unsubscribe',config).then(resp => {
            if (resp.code == 200) {
                alert(resp.message);
            }
            // this.subscribeForm.value.subscribe_email = '';
            this.emailvalue = null;
        })
    }
}
