import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
  selector: 'app-league-policies',
  templateUrl: './league-policies.component.html',
  styleUrls: ['./league-policies.component.scss']
})
export class LeaguePoliciesComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();
    }
    getData(){
        const config={
            about_type:'league-policies'
        };
        this.dataService.getBaseData('about',config).then(resp => {
            if(resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }
}
