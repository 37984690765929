import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { DataService } from "../../../@core/data/data.service";
import { HttpProvider } from "../../../@core/utils/http.provider";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-forgetPassword',
    templateUrl: './forgetPassword.component.html',
    styleUrls: ['./forgetPassword.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
    setForm: FormGroup;
    player_logintype = 'phone';
    phoneAreaList = [];
    player_phone_area = '';
    player_facebook = '';
    position = [];
    shoot = [];
    jerseysize = [];
    studyingGrade = [];
    nationality = [];
    showCode: boolean = false;
    paracontEmail = 'Code';
    paracontPhone = 'Code';
    disabledEmail = false;
    disabledPhone = false;

constructor(public dataService: DataService,
    public httpProvider: HttpProvider,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService){

    this.player_facebook = this.activatedRoute.snapshot.params['id'];
}

ngOnInit() {
    this.setForm = new FormGroup({
        player_email: new FormControl('', []),
        player_phone: new FormControl('', []),
        code_verification_code: new FormControl('', [Validators.required]),
        player_password: new FormControl('', [Validators.required]),
        player_comfirmpassword: new FormControl('', [Validators.required]),
        player_phone_area: new FormControl('852', [Validators.required]),

    });
    this.getPhoneArea();
}

/**
 * thisIsFunction
 * 获取手机号码区号列表
 *
 * @author    man.wang@dreamover-studio.com
 * @return    array
 * @date      2019-08-09
 */
getPhoneArea() {
    const config = {
        setting_key: 'phone_area'
    };
    this.dataService.getBaseData('setting', config).then(resp => {
        this.phoneAreaList = resp.data;

    })

}

/**
 * thisIsFunction
 * 注册
 *
 * @author    man.wang@dreamover-studio.com
 * @return    array
 * @date      2019-08-09
 */
postRegister() {
    let config = this.setForm.value;
   
    config['player_logintype'] = this.player_logintype;
    config['type'] = this.player_logintype;
    if (this.player_logintype == 'email') {
        delete config.player_phone;
        delete config.player_phone_area;

        if(!config.player_email){
            alert('Please fill in the email');
            return;
        }

    } else {
        delete config.player_email;

        if(!config.player_phone){
            alert('Please fill in the phone');
            return;
        }
         if(!config.player_phone_area){
            alert('Please fill in the phone_area');
            return;
        }
    }
    
    this.dataService.postBaseData('login/forgetPassword', config).then(resp => {

        if(resp.code == 200){

            alert('Change Password Success');
             this.router.navigate(['/login'])

        }

        
    })
}

/**
 * thisIsFunction
 * 发送注册邮箱code
 *
 * @author    man.wang@dreamover-studio.com
 * @return    array
 * @date      2019-08-09
 */
postMailCode() {

    this.disabledEmail = true;

    let config = {
        player_email: this.setForm.value.player_email,
        type: 'forgetPassword'
    };
    this.dataService.postBaseData('code/sentMail', config).then(resp => {
        // alert('成功');
        if (resp && resp.code == 200) {
            this.paracont('paracontEmail', 'disabledEmail')
            this.toastrService.success('Success', resp.message);
        } else {
            this.disabledEmail = false;
        }
    })
}


postPhoneCode(){


    let config = {
        player_phone: this.setForm.value.player_phone,
        player_phone_area: this.setForm.value.player_phone_area,
        type: 'forgetPassword'
    };

    this.dataService.postBaseData('code/sentPhone', config).then(resp => {
        // alert('成功');
         if (resp && resp.code == 200) {
            this.paracont('paracontPhone', 'disabledPhone')
            this.toastrService.success('Success', resp.message);
        } else {
            this.disabledPhone = false;
        }
    })

}



    /**
     * thisIsFunction
     * 60s倒计时
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-03-12
     */
    paracont(paracont, disabledClick) {
        const numbers = interval(1000);
        const takeFourNumbers = numbers.pipe(take(60));
        takeFourNumbers.subscribe(
            x => {
                this[paracont] = (60 - x) + "s";
                // this[disabledClick] = true;
            },
            error => { },
            () => {
                this[paracont] = "Code";
                this[disabledClick] = false;
            });
    }

    func_disabledEmail() {
        let dis = true;
        if (!this.disabledEmail && this.setForm.value.player_email) {
            dis = false;
        }
        return dis;
    }
    func_disabledPhone() {
        let dis = true;
        if (!this.disabledPhone && this.setForm.value.player_phone) {
            dis = false;
        }
        return dis;
    }


}
