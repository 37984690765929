import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-header-banner',
    templateUrl: './header-banner.component.html',
    styleUrls: ['./header-banner.component.scss']
})
export class HeaderBannerComponent implements OnInit {
    @Input() imgUrl = 'assets/images/banner2.jpg';
    @Input() title = '  ';
    @Input() customPath = [];
    @Input() activeTitle = '';

    constructor() {
    }

    ngOnInit() {
    }

    formatName(name) {
        let title = name.replace('-', ' ');
        title = title.replace('-', ' ');
        return title
    }

}
