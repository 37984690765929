import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params} from "@angular/router";
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-tournament-type1',
    templateUrl: './tournament-type1.component.html'
})
export class TournamentType1Component implements OnInit {

    loading: boolean = false;
    datalist: any;
    campsId: any = '';
    // setForm: FormGroup;
    people = [1];

    constructor(public dataService: DataService, private activatedRoute: ActivatedRoute,) {
        // //获取参数值
        // this.campsId = this.activatedRoute.snapshot.params['id'];
        // //订阅路由参数，路由传递id改变之后执行
        // this.activatedRoute.params.subscribe((params:Params)=>{this.campsId=params["id"];this.getData();});
    }

    ngOnInit() {
        // this.getData()
    }

    getData() {
        // this.dataService.getData('assets/data/data.json').subscribe(resp => {
        //     if (resp.code === 200) {
        //         this.loading = false;
        //         this.datalist = resp.datalist.camps.activityList[this.campsId];
        //     } else {
        //
        //     }
        // })
    }

    addPeople(){
        this.people.push(1)
    }
    delPeople(num){
        this.people.splice(num,1)
    }
}
