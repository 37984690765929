import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
    selector: 'app-camps-new',
    templateUrl: './camps-new.component.html',
    styleUrls: ['./camps-new.component.scss']
})
export class CampsNewComponent implements OnInit {

    loading: boolean = true;
    datalist: any;
    datacampslist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        const config = {
            "table": "development",
            "where": {
                "development_type": 'camps'
            },
            "return": "row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }
}
