import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params} from "@angular/router";
import { FormControl,ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-camps-detail',
  templateUrl: './camps-detail.component.html',
  styleUrls: ['./camps-detail.component.scss']
})
export class CampsDetailComponent implements OnInit {

    loading: boolean = true;
    datalist: any;
    campsId:any='';
    // setForm: FormGroup;

    constructor(public dataService: DataService,private activatedRoute: ActivatedRoute,) {
        //获取参数值
        this.campsId = this.activatedRoute.snapshot.params['id'];
        //订阅路由参数，路由传递id改变之后执行
        this.activatedRoute.params.subscribe((params:Params)=>{this.campsId=params["id"];this.getData();});
    }

    ngOnInit() {
        this.getData()
    }

    getData(){
        this.dataService.getData('assets/data/data.json').subscribe(resp => {
            if (resp.code === 200) {
                this.loading = false;
                this.datalist = resp.datalist.camps.activityList[this.campsId];
            } else {

            }
        })
    }
}
