import { of as observableOf, Observable } from 'rxjs';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { HttpProvider } from '../utils/http.provider';


import { map } from 'rxjs/operators';
// router
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";

import { ToastrService } from 'ngx-toastr';

/**
 *  Must go to app.module config the provider
 **/
@Injectable({
    providedIn: 'root'
})
export class DataService {
    tip: object = {
        "e200": "Success",
        "e400": "Lack of necessary parameters",
        "e403": "Login is invalid, please log in again",
        "e500": "Server error",
        "e600": "No such mailbox",
        "e601": "Wrong account or password",
        "e602": "Mail Delivery Failed ",
        "e603": "The mailbox already exists",
        "e604": "Invalid verification code",
        "e605": "Two password inconsistencies",
        "e606": "The account already exists ",
        "e607": "Verification code expired",
        "e608": "No such account",
        "e609": "The original password is incorrect",
        "e610": "Please register your account first.",
        "e611": "No such phone",
        "e612": "The phone already exists",
        "e613": "SMS sending failed",
        "e614": "Incorrect mobile number ",
        "e615": "Can’t modify mailbox with this link",
        "e616": "Can’t modify phone with this link",
        "e700": "No privileges.",
        "e701": "The game has started, the team cannot be modified.",
        "e702": "The game is over.",
        "e703": "The game has not started yet.",
        "e704": "invalid operation.",
        "e800": "The paid amount may be modified, please refresh and submit again.",
        "e801": "The League group may have been deleted or the enrollment may have ended.",
        "e802": "The amount is wrong, please refresh.",
        "e803": "Payment method has not been opened in the system, please contact the administrator.",
        "e804": "Order number not found.",
        "e805": "Subscription is not exist.",
    };

    constructor(
        public http: HttpClient,
        private activatedRoute: ActivatedRoute,
        public httpProvider: HttpProvider,
        private router: Router,
        private toastrService: ToastrService) {
    }

    getData(api, config?: any) {
        return this.http.get(api, { params: config }).pipe(
            map((data: any) => {
                const response = {
                    code: 200,
                    message: 'SUCCESS',
                    datalist: data
                };
                return response;
            })
        );
    }

    getCommonData(config?: any) {
        return this.httpProvider.post("common/select", { data: config }).then(resp => {
            if (resp.code === 200) {
                return resp;
            } else {
                if (resp.code === 403) {
                    localStorage.setItem('token', null);
                    // localStorage.setItem('user', null);
                    localStorage.removeItem('user');
                    localStorage.removeItem('payInfo');
                    localStorage.removeItem('league');
                    localStorage.removeItem('choiceId');
                    localStorage.removeItem('otherData');
                    
                    this.httpProvider.publishSessionId('');
                    this.router.navigate(['/login'])
                } else {
                    if(this.tip['e' + resp.code]){
                        this.toastrService.error('Error', this.tip['e' + resp.code]);
                    }else{
                        this.toastrService.error('Error', resp.message);
                    }
                }
                return false;
            }
        }, error => {
            // this.toastrService.error('Error', 'Server error');
            return false;
            // alert('Server error')
        });
    }

    getBaseData(url, params?) {
        return this.httpProvider.get(url, { params: params }).then(resp => {
            if (resp.code === 200) {
                return resp;
            } else {
                if (resp.code === 403) {
                    localStorage.setItem('token', null);
                    // localStorage.setItem('user', null);
                    localStorage.removeItem('user');
                    localStorage.removeItem('payInfo');
                    localStorage.removeItem('league');
                    localStorage.removeItem('choiceId');
                    localStorage.removeItem('otherData');

                    this.httpProvider.publishSessionId('');
                    this.router.navigate(['/login']);
                } else {
                    if(this.tip['e' + resp.code]){
                        this.toastrService.error('Error', this.tip['e' + resp.code]);
                    }else{
                        this.toastrService.error('Error', resp.message);
                    }
                }
            }
        }, error => {
            // this.toastrService.error('Error', 'Server error');
            return false;
        });
    }

    postBaseData(url, config) {
        return this.httpProvider.post(url, { data: config }).then(resp => {
            if (resp.code === 200) {
                return resp
            } else {
                if (resp.code === 403) {
                    localStorage.setItem('token', null);
                    // localStorage.setItem('user', null);
                    localStorage.removeItem('user');
                    localStorage.removeItem('payInfo');
                    localStorage.removeItem('league');
                    localStorage.removeItem('choiceId');
                    localStorage.removeItem('otherData');
                    this.httpProvider.publishSessionId('');
                    this.router.navigate(['/login'])
                } else {
                    if(this.tip['e' + resp.code]){
                        this.toastrService.error('Error', this.tip['e' + resp.code]);
                    }else{
                        this.toastrService.error('Error', resp.message);
                    }
                }
            }
        }, error => {
            this.toastrService.error('Error', 'Server error');
        });
    }

    putBaseData(url, config) {
        return this.httpProvider.put(url, { data: config }).then(resp => {
            if (resp.code === 200) {
                return resp
            } else {
                if (resp.code === 403) {
                    localStorage.setItem('token', null);
                    // localStorage.setItem('user', null);
                    localStorage.removeItem('user');
                    localStorage.removeItem('payInfo');
                    localStorage.removeItem('league');
                    localStorage.removeItem('choiceId');
                    localStorage.removeItem('otherData');
                    this.httpProvider.publishSessionId('');
                    this.router.navigate(['/login'])
                } else {
                    if(this.tip['e' + resp.code]){
                        this.toastrService.error('Error', this.tip['e' + resp.code]);
                    }else{
                        this.toastrService.error('Error', resp.message);
                    }
                }
            }
        }, error => {
            this.toastrService.error('Error', 'Server error');
        });
    }

    deteleData(config) {
        return this.httpProvider.delete("common/delete", {data: config}).then(resp => {
            if (resp.code === 200) {
                return resp
            } else{
                if (resp.code === 403) {
                    localStorage.setItem('token', null);
                    // localStorage.setItem('user', null);
                    localStorage.removeItem('user');
                    localStorage.removeItem('payInfo');
                    localStorage.removeItem('league');
                    localStorage.removeItem('choiceId');
                    localStorage.removeItem('otherData');
                    this.httpProvider.publishSessionId('');
                    this.router.navigate(['/login']);
                } else {
                    if(this.tip['e' + resp.code]){
                        this.toastrService.error('Error', this.tip['e' + resp.code]);
                    }else{
                        this.toastrService.error('Error', resp.message);
                    }
                }
            }

        }, error => {
            alert('Server error');
        });
    }
    // 登出
    logout() {
        return this.httpProvider.delete('login').then(resp => {
            if (resp.code === 200) {
                return resp
            } else {
                if (resp.code === 403) {
                    localStorage.setItem('token', null);
                    // localStorage.setItem('user', null);
                    localStorage.removeItem('user');
                    localStorage.removeItem('payInfo');
                    localStorage.removeItem('league');
                    localStorage.removeItem('choiceId');
                    localStorage.removeItem('otherData');
                    this.httpProvider.publishSessionId('');
                    this.router.navigate(['/login']);
                } else {
                    if(this.tip['e' + resp.code]){
                        this.toastrService.error('Error', this.tip['e' + resp.code]);
                    }else{
                        this.toastrService.error('Error', resp.message);
                    }
                }
            }
        }, error => {
            this.toastrService.error('Error', 'Server error');
        });
    }

}

