import {Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Headers, Http} from "@angular/http";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {GlobalsService} from "../../../@core/data/globals.service";

import {Subscription} from 'rxjs';
import {IMqttMessage, MqttService} from 'ngx-mqtt';
import {ClickableComponent} from "./clickable.component";
import {GridTableComponent} from "../../components/grid-table/grid-table.component";


@Component({
    selector: 'app-city-league',
    templateUrl: './game-schedule.component.html',
    styleUrls: ['./game-schedule.component.scss']
})
export class GameScheduleComponent implements OnInit {

    loading: boolean = true;
    showMore: boolean = true;

    datalist: any = [];
    leagueInfo: Array<any> = [];
    subscription: any;
    limit = 5;
    perPage = 1;
    Id = '0';

    defaultColDef = {
        flex: 1,
        flexShrink: 0,
        minWidth: 300,
        resizable: true,
        sortable: true,
        width: 300,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
    };
    columnDefs = [
        {
            headerName: 'Start time',
            minWidth: 150,
            width: 150,
            pinned: 'left',
            lockPinned: true,
            field: 'game_starttime',
        },
        {
            headerName: 'Home team',
            field: 'home_team_name',
            minWidth: 200
        },
        {
            headerName: 'Visiting team',
            field: 'visiting_team_name',
            minWidth: 200
        },
        {
            headerName: 'Location',
            field: 'game_location',
            minWidth: 400
        },
        {
            headerName: 'Result',
            field: 'points',
            minWidth: 100
        },

        {
            headerName: 'Action',
            field: 'game_id',
            minWidth: 150,
            cellRendererFramework: ClickableComponent,
        },
    ];
    comingList = [];
    pastList = [];
    @ViewChild('pastTable', {static: false}) pastTableView: GridTableComponent;
    @ViewChild('comingTable', {static: false}) comingTableView: GridTableComponent;


    constructor(private modalService: BsModalService,
                public dataService: DataService,
                private formBuilder: FormBuilder,
                private httpClient: HttpClient,
                private _mqttService: MqttService,
                private activatedRoute: ActivatedRoute,
                private globalsService: GlobalsService,
                private http: Http) {
        this.Id = this.activatedRoute.snapshot.params['id'];

        this.activatedRoute.params.subscribe((params: Params) => {
            this.Id = params['id'];
            this.showMore = true;
            this.getData();
            this.datalist = [];
        });

    }


    ngOnInit() {
        // this.getData();
    }

    /**
     * thisIsFunction
     * 获取今年联赛资料
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    // getLeague() {

    //     this.loading = true;
    //     const config = {
    //         "table": "league",
    //         "where": {
    //             "league_is_disabled":"N",
    //         },
    //         "page": "1",
    //         "perPage": "10000"
    //     };
    //     this.dataService.getCommonData(config).then(resp => {

    //         if (resp.code === 200) {
    //             this.loading = false;
    //             resp.data.forEach((val) => {
    //                 const starttime=val.league_starttime.slice(0,10);
    //                 const year = new Date(starttime).getFullYear();
    //                 const thisYear = new Date().getFullYear();

    //                 if (year == thisYear) {
    //                     this.leagueInfo = val;

    //                 }
    //             });
    //             this.getData();
    //         }
    //     })
    // }


    getData() {
        this.loading = true;
        let ids = this.Id.split("-");
        this.comingList = [];
        this.pastList = [];
        this.datalist = [];
        const config = {
            "league_id": ids[0],
            "selection_id": ids[1],
        };
        this.dataService.getBaseData('Game/league_schedule', config).then(resp => {
            if (resp.code == 200) {
                if (resp.data.length > 0) {
                    resp.data.forEach((val, key) => {
                        this.mqttSubscription(val.game_home_team_id, val.game_visiting_team_id, val.game_id);
                        val['home_team_name'] = val.homeTeamInfo.team_name;
                        val['visiting_team_name'] = val.homeVisitingInfo.team_name;
                        val['points'] = val.homeTeamInfo.team_score + ' - ' + val.homeVisitingInfo.team_score;
                        val['starttime'] = new Date(val['game_starttime']).getTime();
                        if (new Date(val['game_starttime']).getTime() > new Date().getTime()) {
                            this.comingList.push(val)
                        } else {
                            this.pastList.push(val)
                        }
                    });
                    // this.pastList = resp.data.sort(this.globalsService.sort("starttime"));
                    if (this.pastList.length) {
                        const numIndex = this.pastList.length - 1;
                        this.datalist.push(this.pastList[numIndex]);
                    } else {
                        this.datalist = [];
                    }
                    this.loading = false;
                } else {
                    this.showMore = false;
                    this.loading = false;
                }
            }
        })
    }

    mqttSubscription(homeTeamId, visitTeamId, gameId) {
        let topic = homeTeamId + '-' + visitTeamId + '-' + gameId;
        this.subscription = this._mqttService.observe(topic).subscribe((message: IMqttMessage) => {
            let messageString = message.payload.toString();
            let massageData = JSON.parse(messageString);
            this.datalist.forEach((val, key) => {
                if (val.game_home_team_id == massageData.homeTeam.team_id
                    && val.game_visiting_team_id == massageData.visitingTeam.team_id
                    && val.game_id == massageData.gameId
                ) {
                    this.datalist[key].homeVisitingInfo.team_score = massageData.visitingTeam.score;
                    this.datalist[key].homeTeamInfo.team_score = massageData.homeTeam.score;
                }
            });
            this.comingList.forEach((val, key) => {
                if (val.game_home_team_id == massageData.homeTeam.team_id
                    && val.game_visiting_team_id == massageData.visitingTeam.team_id
                    && val.game_id == massageData.gameId
                ) {
                    val.points = massageData.homeTeam.score + ' - ' + massageData.visitingTeam.score;
                    this.comingTableView.refreshCells();
                }
            });
            this.pastList.forEach((val, key) => {
                if (val.game_home_team_id == massageData.homeTeam.team_id
                    && val.game_visiting_team_id == massageData.visitingTeam.team_id
                    && val.game_id == massageData.gameId
                ) {
                    val.points = massageData.homeTeam.score + ' - ' + massageData.visitingTeam.score;
                    this.pastTableView.refreshCells();
                }
            });
        });
    }

    loadMore() {
        let num = '1';
        this.perPage = this.perPage + parseInt(num);
        // this._mqttService.disconnect();
        this.getData();
    }

    ngOnDestroy() {
        // this._mqttService.disconnect();
    }


}
