/*
* @copyright   Dreamover-Studio
* @author      man.wang@dreamover-studio.com
* @version     1.0.1
* @date        2020-06-09 16:33:04
*/

import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {ImageCroppedEvent} from "ngx-image-cropper";
import {DataService} from "../../../@core/data/data.service";
import {GlobalsService} from "../../../@core/data/globals.service";
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-updata-portrait',
    templateUrl: './updata-portrait.component.html',
    styleUrls: ['./updata-portrait.component.scss']
})
export class UpdataPortraitComponent implements OnInit {
    @Input() defaultImage: string = 'assets/images/personal.png';
    @Input() userId: string = 'assets/images/personal.png';
    @Output() changeFps = new EventEmitter();
    @Input() isFps: boolean = true;

    base64String: any;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    showCropper: boolean = false;
    defaultValue: boolean = true;
    is_uploadImage: boolean = false;
    showModal: boolean = false;

    constructor(public dataService: DataService,
                public globals: GlobalsService,
                private toastrService: ToastrService) {
    }

    ngOnInit() {
        if (!this.defaultImage) this.defaultImage = 'assets/images/personal.png'

    }

    /**
     * thisIsFunction
     * input上传文件获取信息
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-06-09
     */
    fileChangeEvent(event: any): void {
        this.defaultValue = false;
        this.showModal = true;
        this.imageChangedEvent = event;
    }

    /**
     * thisIsFunction
     * 裁剪图片
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-06-09
     */
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.is_uploadImage = false;
    }

    /**
     * thisIsFunction
     * 上传图片到服务器，返回图片url
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-06-09
     */
    uploadImage() {
        this.showModal = false;
        let formData = {
            type: 'img',
            img: this.croppedImage
        };
        this.dataService.postBaseData('CmsCommon/file', formData).then(resp => {
            this.defaultImage = this.globals.apiUrl+resp.data.url;
            this.toastrService.success('Success');
            this.globals.globalVar.next([{img: resp.data.img}]);
            this.upData(resp.data.url)
        })
    }

    /**
     * thisIsFunction
     * 根据id修改个人信息中的图片
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2020-06-09
     */
    upData(url) {
        // if (this.isFps) {
        //     this.changeFps.emit(url)
        // } else {
            let config: any = {
                "photo_url": url
            };
            this.dataService.postBaseData('player/playerPhoto', config).then(resp => {
                if (resp) {
                    this.toastrService.success('Success');
                    const user = JSON.parse(localStorage.getItem("user"));
                    user['player_photo'] = this.defaultImage;
                    localStorage.setItem("user", JSON.stringify(user))
                }
            })
        // }

    }

    imageLoaded() {
        this.showCropper = true;
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

}
