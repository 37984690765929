import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {Code404Component} from './page/code404/code404.component';
import {HomeComponent} from './page/home-list/home/home.component';
import {HistoryComponent} from './page/about-list/history/history.component';
import {AboutComponent} from './page/about-list/about/about.component';
import {CoachesComponent} from "./page/about-list/coaches/coaches.component";
import {PaymentsComponent} from "./page/about-list/payments/payments.component";
import {LoginComponent} from "./page/maintenance/login/login.component";
import {RegistrationComponent} from "./page/maintenance/registration/registration.component";
import {LeaguePoliciesComponent} from './page/about-list/league-policies/league-policies.component';
import {SevereWeatherComponent} from './page/about-list/severe-weather/severe-weather.component';
import {SponsorshipComponent} from './page/about-list/sponsorship/sponsorship.component';
import {ContactComponent} from './page/about-list/contact/contact.component';
import {NewsComponent} from './page/about-list/news/news.component';
import {NewsTwoComponent} from './page/about-list/news/news2.component';
import {NewsDetailComponent} from './page/about-list/news/news-detail.component';
import {PhotoComponent} from './page/about-list/photo/photo.component';
import {PhotoDetailComponent} from './page/about-list/photo/photo-detail.component';
import {SelectComponent} from './page/selects-list/select/select.component';
import {TrainingAndDevelopmentComponent} from './page/training-and-development/training-and-development/training-and-development.component';
import {SyntheticIceComponent} from './page/training-and-development/synthetic-ice/synthetic-ice.component';
import {SemiprivateLessonComponent} from './page/training-and-development/special-training/special-training.component';
import {FitnessComponent} from './page/training-and-development/off-ice/off-ice.component';
import {CampsNewComponent} from './page/training-and-development/camps/camps-new.component';
import {CampsDetailNewComponent} from './page/training-and-development/camps/camps-detail-new.component';
import {CampsDetailComponent} from './page/training-and-development/camps/camps-detail.component';
import {CityLeagueComponent} from './page/city-league-list/city-league/city-league.component';
import {TeamsComponent} from './page/city-league-list/teams/teams.component';
import {PersonalComponent} from './page/maintenance/personal/personal.component';
import {RegisteredLeagueComponent} from './page/city-league-list/registered-league/registered-league.component';
import {TournamentComponent} from './page/training-and-development/tournament/tournament.component';
import {TournamentType1Component} from './page/training-and-development/tournament/tournament-type1.component';
import {TournamentType2Component} from './page/training-and-development/tournament/tournament-type2.component';
import {BanquetDetailComponent} from './page/training-and-development/banquet/banquet-detail.component';
import {BanquetComponent} from './page/training-and-development/banquet/banquet.component';
import {PrivacyPolicyComponent} from './page/about-list/privacy-policy/privacy-policy.component';
import {LeagueDetailComponent} from './page/city-league-list/league-detail/league-detail.component';
import {GameScheduleComponent} from './page/city-league-list/game-schedule/game-schedule.component';
import {TechnicalStatisticsComponent} from './page/city-league-list/technical-statistics/technical-statistics.component';
import {ForgetPasswordComponent} from './page/maintenance/forgetPassword/forgetPassword.component';

import {SeasonStandingsComponent} from './page/city-league-list/season-standings/season-standings.component';
import {SeasonStatisticsComponent} from './page/city-league-list/season-statistics/season-statistics.component';
import {ResultsComponent} from './page/city-league-list/results/results.component';
import {LeagueInfoComponent} from './page/city-league-list/league-info/league-info.component';
import {LeagueInfoTeamComponent} from './page/city-league-list/league-info/league-info-team.component';
import {LeagueGroupComponent} from './page/city-league-list/league-group/league-group.component';


const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'home', component: HomeComponent},
    {path: 'history', component: HistoryComponent},
    {path: 'about', component: AboutComponent},
    {path: 'coaches', component: CoachesComponent},
    {path: 'payments', component: PaymentsComponent},
    {path: 'login', component: LoginComponent},
    {path: 'login/:id', component: LoginComponent},
    {path: 'registration/:id', component: RegistrationComponent},
    {path: 'registration/:type:id', component: RegistrationComponent},
    {path: 'registration', component: RegistrationComponent},
    {path: 'forgetPassword', component: ForgetPasswordComponent},
    {path: 'league-policies', component: LeaguePoliciesComponent},
    {path: 'severe-weather', component: SevereWeatherComponent},
    {path: 'sponsorship', component: SponsorshipComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'results', component: ResultsComponent},
    {path: 'news', component: NewsComponent},
    {path: 'news-archive', component: NewsTwoComponent},
    {path: 'news-detail/:id', component: NewsDetailComponent},
    {path: 'photo', component: PhotoComponent},
    {path: 'photo-detail/:id', component: PhotoDetailComponent},
    {path: 'select', component: SelectComponent},
    {path: 'select/:id', component: SelectComponent},
    {path: 'training-and-development', component: TrainingAndDevelopmentComponent},
    {path: 'training-and-development/:id', component: TrainingAndDevelopmentComponent},
    {path: 'synthetic-ice', component: SyntheticIceComponent},
    {path: 'special-training', component: SemiprivateLessonComponent},
    {path: 'off-ice', component: FitnessComponent},
    {path: 'camps', component: CampsNewComponent},
    {path: 'camps-detail', component: CampsDetailNewComponent},
    {path: 'camps-detail2', component: CampsDetailComponent},
    {path: 'city-league', component: CityLeagueComponent},
    {path: 'technical-statistics', component: TechnicalStatisticsComponent},
    {path: 'league-detail', component: LeagueDetailComponent},
    {path: 'game-schedule/:id', component: GameScheduleComponent},
    {path: 'league-detail/:id', component: LeagueDetailComponent},
    {path: 'season-standings/:id', component: SeasonStandingsComponent},
    {path: 'season-statistics/:id', component: SeasonStatisticsComponent},
    {path: 'teams', component: TeamsComponent},
    {path: 'teams/:id', component: TeamsComponent},
    {path: 'personal', component: PersonalComponent},
    {path: 'personal/:id', component: PersonalComponent},
    {path: 'registered-league', component: RegisteredLeagueComponent},
    {path: 'tournament', component: TournamentComponent},
    {path: 'tournament-type1', component: TournamentType1Component},
    {path: 'tournament-type2', component: TournamentType2Component},
    {path: 'banquet', component: BanquetDetailComponent},
    {path: 'banquet-detail', component: BanquetComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'league-info', component: LeagueInfoComponent},
    {path: 'league-info-team', component: LeagueInfoTeamComponent},
    {path: 'league-group/:id', component: LeagueGroupComponent},
    {path: '**', component: Code404Component}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
