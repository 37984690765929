import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgwWowService} from 'ngx-wow';
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';
import {DataService} from "../../../@core/data/data.service";
import {Router, ActivatedRoute} from "@angular/router";
import {GridOptions, Module, AllCommunityModules} from "@ag-grid-community/all-modules";


@Component({
    selector: 'app-grid-table',
    templateUrl: './grid-table.component.html',
    styleUrls: ['./grid-table.component.scss']
})
export class GridTableComponent implements OnInit {

    @Input() columnDefs: any = {};
    @Input() rows: any = [];
    @Input() hasExport: boolean = true;
    @Input() frameworkComponents: any = {};
    @Input() defaultColDef: any = {
        flex: 1,
        flexShrink: 0,
        minWidth: 98,
        resizable: true,
        sortable: true,
        width: 98,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
    };
    private gridApi;
    private gridColumnApi;
    numToPage: number = 1;
    @Output() exportAsXLSXSingle = new EventEmitter();
    public modules: Module[] = AllCommunityModules;
    public gridOptions: GridOptions;


    constructor(public dataService: DataService) {

    }

    ngOnInit() {

    }

    /**
     * thisIsFunction
     * 跳转表格页数
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-09-29
     */
    changePager(num, type?) {
        if (type) {
            let keycode = window.event ? num.keyCode : num.which;
            if (keycode == 13) {//回车键
                const num = Number(this.numToPage) - 1;
                this.gridApi.paginationGoToPage(Number(num));
            }
        } else {
            if (Number(num)) {
                const num = Number(this.numToPage) - 1;
                this.gridApi.paginationGoToPage(Number(num));
            }
        }
    }

    export(){
        this.exportAsXLSXSingle.emit()
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.paginationSetPageSize(20);
    }

    refreshCells(){
        this.gridApi.refreshCells({ force: true });
    }

    onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(Number(event.target.value));
        this.numToPage=1
    }
}
