import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
  selector: 'app-banquet',
  templateUrl: './banquet.component.html',
  styleUrls: ['./banquet.component.scss']
})
export class BanquetComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();

    }
    getData(){
        this.dataService.getData('assets/data/data.json').subscribe(resp => {
            if (resp.code === 200) {
                this.loading = false;
                this.datalist = resp.datalist.camps;
            } else {

            }
        })
    }
}
