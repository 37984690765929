import {Component, OnInit, ComponentFactoryResolver, ViewChild, ElementRef, ɵConsole,Renderer2} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormControl, ReactiveFormsModule, FormGroup, Validators} from '@angular/forms';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

export const EMAILPATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';

@Component({
    selector: 'app-camps-new-detail',
    templateUrl: './camps-detail-new.component.html',
    styleUrls: ['./camps-detail-new.component.scss']
})
export class CampsDetailNewComponent implements OnInit {
    hasUser = null;
    loading: boolean = true;
    datalist: any;
    showFrom: boolean = false;
    campsForm: FormGroup;
    playerInfo: any = {};
    remarks: any = '';
    camps_order_position_played_id = [];
    contentDetail = [];
    member_data = ['HK Selects & City League','City League Only','None'];
    member = [];
    subcontent = [];
    campBus = [];
    campHotel = [];
    campsSelect = [];
    objSummber = {};
    count = 0;
    showQR: boolean = false;
    campsData = [];
    safeSrc: SafeResourceUrl;
    camps_order_player_id = '';
    camps_order_id = '';
    camps_order_sn = '';
    camps_order_status = '';
    modalRef: BsModalRef;
    campsOderIds = [];
    campsOderitem = [];
    campsOdervalue: boolean = false;
    alipaylink: any = '';
    Interval: any = '';
    SummerListLength: any = '';
    campBusValue = [];
    campExtraHotelValue = [];
    campRoomHotelValue = [];
    camps_order_hotel_night_number = 0;
    camps_order_hotel_room_number = 0;
    IscampBusValue : boolean = false;
    IscampRoomValue : boolean = false;
    IscampExtraValue : boolean = false;
    campsTypeArray = [];
    campsTypeData = [];
    camps_order_check_in_date = '';
    camps_order_check_out_date = '';
    camps_order_hotel_room_tpye = '';
    camps_order_hotel_room_special_request = '';
    camps_order_hotel_check_in_name = '';

    camps_order_member_name = '';
     camps_title: string = '';
    camps_order_hotel_id = '';
    
    //是否显示 check info
    camps_show_check_info = false;

    player_last_name = '';
    player_first_name = '';
    player_birth = '';
    player_position_id ='';
    player_member_id ='';
    player_phone = '';
    mother_email = '';
    father_email = '';

    @ViewChild('template', {static: true}) templateView: ElementRef;
    @ViewChild('template_wechat', {static: true}) template_wechat: ElementRef;

    constructor(
                private renderer2: Renderer2,
                public dataService: DataService,
                private activatedRoute: ActivatedRoute,
                private el:ElementRef,
                private router: Router,
                private modalService: BsModalService,
                private sanitizer: DomSanitizer) {
        this.hasUser = localStorage.getItem("user") || null

    }


    ngOnInit() {
        // 网站camps页面去掉需要登录才能访问的判断 by wm 20200728
        this.getData();



        const config = {
            "table": "setting",
            // "select":"player_name",
            "where": {},
            "perPage": "10000"
        };

   this.dataService.getCommonData(config).then(resp => {

            if (resp.code === 200) {
                resp.data.forEach((val) => {
                    if (val.setting_key === 'camps_title') {
                        this.camps_title = val.setting_value;
                    }

                });
            }
  })

        if (this.hasUser) {
            this.getSummerList();
            this.campsOrder();
           
            this.getPlayerDataSelect('position_played');
            this.getPlayerDataSelect('member');
            let userData = localStorage.getItem("user");
            this.playerInfo = JSON.parse(userData) || [];
            console.log('playerInfo',this.playerInfo);
            //console.log('father_email',this.playerInfo['father_email']);

            this.getCampsData('type');
        }else{

            this.getCampsBusData();
            this.getCampsHotelData();
            this.initCampData();
        }

        //this.getCampsBusData();
        //this.getCampsHotelData();
        console.log('order_id2',this.camps_order_id);
       
        /*
        if(this.camps_order_id){
            var player_last_name = this.campsTypeData.camps_order_player_last_name;
            var player_first_name = this.campsTypeData.camps_order_player_first_name;
            var player_birth = this.campsTypeData.camps_order_player_birthdate;
            var player_player_member_id =this.campsTypeData.camps_order_member_id;
            var position_id =this.campsTypeData.camps_order_position_played_id;
            var player_phone = this.campsTypeData.camps_order_phone;
            var mother_email = this.campsTypeData.camps_order_mother_email;
            var father_email =this.campsTypeData.camps_order_father_email;
        }else{
            */
            //新订单使用playerInfo
            console.log('this.playerInfo',this.playerInfo)
            this.player_last_name = this.playerInfo['player_last_name'];
            this.player_first_name = this.playerInfo['player_first_name'];
            this.player_birth = this.playerInfo['player_birth'];
            this.player_position_id =this.playerInfo['player_position_id'];
            this.player_member_id =this.playerInfo['player_member_id'];
            this.player_phone = this.playerInfo['player_phone'];
            this.mother_email = this.playerInfo['mother_email'];
            this.father_email = this.playerInfo['father_email'];

        //}
    
        this.campsForm = new FormGroup({
            camps_order_player_id: new FormControl(this.playerInfo['player_id'], [Validators.required]),
            camps_order_player_last_name: new FormControl(this.player_last_name, [Validators.required]),
            camps_order_player_first_name: new FormControl(this.player_first_name, [Validators.required]),
            camps_order_player_birthdate: new FormControl(this.player_birth, [Validators.required]),
            camps_order_position_played_id: new FormControl(this.player_position_id, [Validators.required]),
            camps_order_member_id: new FormControl(this.player_member_id, [Validators.required]),
            camps_order_phone: new FormControl(this.player_phone, [Validators.required]),
            camps_order_father_email: new FormControl(this.mother_email, [Validators.required, Validators.pattern(EMAILPATTERN)]),
            camps_order_mother_email: new FormControl(this.father_email, [Validators.required, Validators.pattern(EMAILPATTERN)]),
            camps_order_remarks: new FormControl(this.remarks),
            camps_order_cost: new FormControl(this.count, [Validators.required]),
            camps: new FormControl(this.campsSelect, [Validators.required]),

            //tom test
            camps_order_check_in_date:new FormControl(this.camps_order_check_in_date),
            camps_order_check_out_date:new FormControl(this.camps_order_check_out_date),
            camps_order_hotel_night_number:new FormControl(this.camps_order_hotel_night_number),
            camps_order_hotel_room_number:new FormControl(this.camps_order_hotel_room_number),
            camps_order_hotel_room_tpye:new FormControl(this.camps_order_hotel_room_tpye),
            camps_order_hotel_room_special_request:new FormControl(this.camps_order_hotel_room_special_request),
            camps_order_hotel_check_in_name:new FormControl(this.camps_order_hotel_check_in_name),
            camps_order_hotel_room_set_day:new FormControl(),
            camp_bus_value:new FormControl(this.campBusValue),
            camp_extra_hotel_value:new FormControl(this.campExtraHotelValue),
            camp_room_hotel_value:new FormControl(this.campRoomHotelValue),
        });
        
    }
    

    initCampData(data?){
        if(this.camps_order_id){
            /*
            this.player_last_name = data.camps_order_player_last_name;
            this.player_first_name = data.camps_order_player_first_name;
            this.player_birth = data.camps_order_player_birthdate;
            this.player_member_id =data.camps_order_member_id;
            this.player_position_id =data.camps_order_position_played_id;
            this.player_phone = data.camps_order_phone;
            this.mother_email = data.camps_order_mother_email;
            this.father_email =data.camps_order_father_email;
            console.log('mother_email',data,this.mother_email);
            */
            //this.player_id = data.camps_order_player_id;
            // console.log("sssssssssssssssssssssssssssss");
            this.campsForm.patchValue({
                //camps_order_mother_email: mother_email,
               
                camps_order_player_id: this.playerInfo['player_id'],
                camps_order_player_last_name: this.player_last_name,
                camps_order_player_first_name: this.player_first_name,
                camps_order_player_birthdate: this.player_birth,
                camps_order_position_played_id: this.player_position_id,
                camps_order_member_id: this.player_member_id,
                camps_order_phone: this.player_phone,
                camps_order_father_email: this.father_email,
                camps_order_mother_email: this.mother_email,
                camps_order_remarks:this.remarks,
                camps_order_cost: this.count,
                camps: this.campsSelect,

                // tom 修改，以下寫法貌似有問題，會令提交數據變成以下格式，修改後提交正常
                // camp_bus_value: {validator: null, asyncValidator: null, pristine: true, touched: false, _onDisabledChange: [],…}
                // 修改前
                // camps_order_check_in_date:new FormControl(this.camps_order_check_in_date),
                // camps_order_check_out_date:new FormControl(this.camps_order_check_out_date),
                // camps_order_hotel_night_number:new FormControl(this.camps_order_hotel_night_number),
                // camps_order_hotel_room_number:new FormControl(this.camps_order_hotel_room_number),
                // camps_order_hotel_room_tpye:new FormControl(this.camps_order_hotel_room_tpye),
                // camps_order_hotel_room_special_request:new FormControl(this.camps_order_hotel_room_special_request),
                // camps_order_hotel_check_in_name:new FormControl(this.camps_order_hotel_check_in_name),
                // camps_order_hotel_room_set_day:new FormControl(),
                // camp_bus_value:new FormControl(this.campBusValue),
                // camp_extra_hotel_value:new FormControl(this.campExtraHotelValue),
                // camp_room_hotel_value:new FormControl(this.campRoomHotelValue),
                // 修改後
                camps_order_check_in_date:this.camps_order_check_in_date,
                camps_order_check_out_date:this.camps_order_check_out_date,
                camps_order_hotel_night_number:this.camps_order_hotel_night_number,
                camps_order_hotel_room_number:this.camps_order_hotel_room_number,
                camps_order_hotel_room_tpye:this.camps_order_hotel_room_tpye,
                camps_order_hotel_room_special_request:this.camps_order_hotel_room_special_request,
                camps_order_hotel_check_in_name:this.camps_order_hotel_check_in_name,
                camps_order_hotel_room_set_day:"",//tom 此字段貌似無用過，暫時賦初始值
                camp_bus_value:this.campBusValue,
                camp_extra_hotel_value:this.campExtraHotelValue,
                camp_room_hotel_value:this.campRoomHotelValue,
                
                
            });
        }
    }

    getData() {
        this.loading = true;
        const config = {
            "table": "development",
            // "select":"player_name",
            "where": {
                "development_type": 'camps-detail'
            },
            "return": "row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }

    onSubmit() {
        this.campsSelect = [];
        Object.keys(this.objSummber).forEach(i => {
            if (this.objSummber[i]) {
                this.campsSelect.push({
                    camp_select_id: i,
                    camp_summer_id: this.objSummber[i]
                });
            }
        });


        
        this.campsForm.patchValue({
            "camps_order_cost": this.count,
            "camps": this.campsSelect,
            // "camp_bus_value": this.campBusValue,
            // "camp_extra_hotel_value": this.campExtraHotelValue,
            // "camp_room_hotel_value": this.campRoomHotelValue,
        })

        if (this.campBusValue.length>0) {
            this.campsForm.patchValue({ "camp_bus_value": this.campBusValue });
        }else{
            this.campBusValue = [];
            this.campsForm.patchValue({ "camp_bus_value": this.campBusValue });
        }
        
        if (this.campExtraHotelValue.length>0) {
            this.campsForm.patchValue({ "camp_extra_hotel_value": this.campExtraHotelValue });
        }else{
            this.campExtraHotelValue = [];
            this.campsForm.patchValue({ "camp_extra_hotel_value": this.campExtraHotelValue });
        }
        
        if (this.campRoomHotelValue.length>0) {
            this.campsForm.patchValue({ "camp_room_hotel_value": this.campRoomHotelValue });
        }else{
            this.campRoomHotelValue = [];
            this.campsForm.patchValue({ "camp_room_hotel_value": this.campRoomHotelValue });
        }
        


        if (!this.campsForm.valid) {
            alert('Please complete the information');
            this.openDirtyControl();
            return;
        } else {

            this.uploadData();
        }
    }

    openDirtyControl() {
        for (const i in this.campsForm.controls) {
            this.campsForm.controls[i].markAsDirty();
            this.campsForm.controls[i].updateValueAndValidity();
        }
    }

    getPlayerDataSelect(tableName) {
        const config = {
            "table": tableName,
            "where": {},
            "page": "1",
            "perPage": "10000"
        };


        this.dataService.getCommonData(config).then(resp => {
            let returnData = [];
            if (resp.code === 200) {
                resp.data.forEach((val, key) => {
                    returnData.push({'name': val[tableName + '_name'], 'id': val[tableName + '_id']});
                });
                this[tableName] = returnData;
            }
        })
    }

    uploadData() {

        const config = JSON.stringify(this.campsForm.value);
         console.log(config);
        // return;
        this.dataService.postBaseData('camps_order/camps', config).then(resp => {
            if (resp.code === 200) {
                //暂时去掉，不然back时信息会空白
                //this.campsForm.reset();
                this.showQR = true
                this.getCampsData()
                this.campsOrder()
            }
        })
    }

    getSummerList() {
        this.dataService.getBaseData('CampSummer/getSummerList').then(resp => {
            if (resp.code === 200) {
                this.contentDetail = resp.data
                this.SummerListLength = resp.data.length
                this.subcontent = []
                resp.data.map(item => {
                    this.subcontent.push(item.subcontent)
                })
            }

        })

    }

    CampCount(){

        let idlength = this.contentDetail.length;
        this.count = 0;

        let bus=0;
        let room=0;
        let extra=0;
        let summer=0;

        Object.keys(this.objSummber).forEach(i => {
            if (this.objSummber[i]) {
                for (var m = 0; m < idlength; m++) {
                    if (i === this.contentDetail[m].course_camp_content_id) {
                        for (var n = 0; n < this.contentDetail[m].subcontent.length; n++) {
                            if (this.objSummber[i] === this.contentDetail[m].subcontent[n].course_camp_summer_id) {
                                this.count = (this.count + Number(this.contentDetail[m].subcontent[n].course_camp_summer_price))
                                summer+=Number(this.contentDetail[m].subcontent[n].course_camp_summer_price);
                            }
                        }
                    }
                }

            }
        })
    
         console.log('summer',summer);
        //bus 统计
        console.log('campBusValue:',this.campBusValue);
        if(this.campBusValue.length > 0){

            this.campBusValue.forEach((val, key) => {
                console.log(val.price+"==="+val.number);

                this.count = this.count + Number(val.price*val.number);
                bus+=Number(val.price*val.number);
            })
        }
        console.log('bus',bus);
        //hotel 房间类型,数量,天数
        if(this.campRoomHotelValue.length > 0){

            //console.log(this.campRoomHotelValue);

            this.count = this.count + Number(this.campRoomHotelValue[0].price*this.campRoomHotelValue[0].night*this.campRoomHotelValue[0].number);
            room+=Number(this.campRoomHotelValue[0].price*this.campRoomHotelValue[0].night*this.campRoomHotelValue[0].number);

        }
        console.log('room',room);

        if(this.campExtraHotelValue.length > 0){

            this.campExtraHotelValue.forEach((val, key) => {

                //this.count = this.count + Number(val.price*val.number);

                this.count = this.count + Number(val.price*val.number*this.campRoomHotelValue[0].night*this.campRoomHotelValue[0].number);

                extra += Number(val.price*val.number*this.campRoomHotelValue[0].night*this.campRoomHotelValue[0].number);
               // extra += Number(val.price*val.number);
            })
        }
       //console.log('campExtraHotelValue',this.campExtraHotelValue);
        console.log('extra',extra);
        console.log('count',this.count);
        console.log('=======');
    }



    CampChange(value, type) {

        this.objSummber[type] = value;

        console.log('objSummber',this.objSummber)
        
        this.CampCount();

    }

    getCampsBusData() {
        const config = {
            "table": "course_camp_bus",
            "return": "result_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.campBus = resp.data;
            }
        })
    }

    getCampsHotelData() {
        
    
        this.dataService.getBaseData('CampSummer/getHotelList').then(resp => {
            if (resp.code === 200) {
                // console.log(resp.data);
                this.campHotel = resp.data;
            }
        })
    }
    



    getCampsData(type?) {
        
         
        this.dataService.getBaseData('camps_order/camps_data').then(resp => {
            if (resp != undefined) {
                if (resp.code === 200) {
                    
                    this.campsOderitem = []

                    this.campsData = resp.data.camps
                     //console.log(this.campsData);
                    if(resp.data.camps != null){
                        if (resp.data.camps.campsCourse.length > 0) {
                            this.campsOdervalue = true
                            
                            this.showQR = true;


                        window.scroll({ 
           top: 100, 
           left: 0, 
           behavior: 'smooth' 
    });
                            
                            this.campsOderIds = resp.data.camps.campsCourse
                            // console.log('campsOderIds',this.campsOderIds)
                            // console.log('contentDetail',this.contentDetail)
                            if (this.contentDetail.length==0) {
                                this.getSummerList();
                            }
                            for (let j = 0; j < this.campsOderIds.length; j++) {
                                if (resp.data.camps.campsCourse[j]) {

                                    for (let i = 0; i < this.SummerListLength; i++) {
                                        if (this.campsOderIds[j].z_camps_course_camps_select_id === this.contentDetail[i].course_camp_content_id) {

                                            for (let n = 0; n < this.contentDetail[i].subcontent.length; n++) {
                                                if (this.campsOderIds[j].z_camps_course_course_camp_summer_id === this.contentDetail[i].subcontent[n].course_camp_summer_id) {
                                                    this.campsOderitem.push((this.contentDetail[i].subcontent[n].course_camp_summer_name) + '...' + (this.contentDetail[i].subcontent[n].course_camp_summer_price))

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // console.log("this.campsOderitem",this.campsOderitem);
                        if(resp.data.camps.campsTypeData.length > 0){
                           this.campsTypeArray = [];
                           let campsTypeData = resp.data.camps.campsTypeData;
                           let campsBusArray = [];
                           let campsExtraArray = [];
                           let campsRoomArray = [];
                           

                            for (let k = 0; k < campsTypeData.length; k++) {

                                if(campsTypeData[k].z_camps_course_type_type == 'bus'){
                                    this.IscampBusValue = true;
                                    campsBusArray.push((campsTypeData[k].z_camps_course_type_type_name) + '...' +(campsTypeData[k].z_camps_course_type_type_price));
                                }
                                if(campsTypeData[k].z_camps_course_type_type == 'extra'){
                                    this.IscampExtraValue = true;

                                    campsExtraArray.push((campsTypeData[k].z_camps_course_type_type_name) + '...' +(campsTypeData[k].z_camps_course_type_type_price));
                                }
                                if(campsTypeData[k].z_camps_course_type_type == 'room'){
                                    this.IscampRoomValue = true;

                                    campsRoomArray.push((campsTypeData[k].z_camps_course_type_type_name) + '...' +(campsTypeData[k].z_camps_course_type_type_price));
                                }
                            }
                            this.campsTypeArray.push({"bus":campsBusArray});
                            this.campsTypeArray.push({"extra":campsExtraArray});
                            this.campsTypeArray.push({"room":campsRoomArray});
                            
                            // console.log(campsTypeArray);
                        }
                        // console.log(resp.data.camps);
                        this.campsTypeData = resp.data.camps.campsTypeData;
                        this.camps_order_player_id = resp.data.camps.camps_order_player_id;
                        this.camps_order_id = resp.data.camps.camps_order_id;
                        this.camps_order_sn = resp.data.camps.camps_order_sn;
                        this.camps_order_status = resp.data.camps.camps_order_status;
                        this.camps_order_hotel_night_number = resp.data.camps.camps_order_hotel_night_number;
                        this.camps_order_hotel_room_number = resp.data.camps.camps_order_hotel_room_number;
                        this.camps_order_check_in_date = resp.data.camps.camps_order_check_in_date;
                        this.camps_order_check_out_date = resp.data.camps.camps_order_check_out_date;
                        this.camps_order_hotel_check_in_name = resp.data.camps.camps_order_hotel_check_in_name;
                        this.camps_order_hotel_room_special_request = resp.data.camps.camps_order_hotel_room_special_request;
                        this.camps_order_hotel_room_tpye = resp.data.camps.camps_order_hotel_room_tpye;

                        this.camps_order_member_name = resp.data.camps.camps_order_member_name;
                        // console.log(this.playerInfo['player_id']);
                        // console.log(resp.data.camps.camps_order_member_id);

                        this.player_last_name = resp.data.camps.camps_order_player_last_name;
                        this.player_first_name = resp.data.camps.camps_order_player_first_name;
                        this.player_birth = resp.data.camps.camps_order_player_birthdate;
                        this.player_member_id =resp.data.camps.camps_order_member_id;
                        this.player_position_id =resp.data.camps.camps_order_position_played_id;
                        this.player_phone = resp.data.camps.camps_order_phone;
                        this.mother_email = resp.data.camps.camps_order_mother_email;
                        this.father_email =resp.data.camps.camps_order_father_email;
                        console.log('player_birth',this.player_birth);
                        this.remarks=resp.data.camps.camps_order_remarks;



this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("/paypal/?pay=camp&order_sn=" + this.camps_order_sn);


                    }
                    console.log('order_id',this.camps_order_id);
                   
                    if(type){

                        this.getCampsBusData();
                        this.getCampsHotelData();
                        this.initCampData(resp.data.camps);
                        console.log('type');
                    }
                   console.log('getCampsData')

                }
            }
        })
        
    }

    back() {
        this.showQR = false
        this.count = 0

         if (this.Interval) {
            clearInterval(this.Interval);
        }
       
        //console.log(this.campsOderIds);
        this.campBusValue = [];
        this.campExtraHotelValue = [];
        this.campRoomHotelValue = [];
        //重新對原本的值賦值
        for (let j = 0; j < this.subcontent.length; j++) {
           let subcontentArray = this.subcontent[j];
            for (let i = 0; i < subcontentArray.length; i++) {
             
                for (let s = 0; s < this.campsOderIds.length; s++) {
                    
                    if(this.campsOderIds[s].z_camps_course_course_camp_summer_id == subcontentArray[i].course_camp_summer_id){

                        // this.objSummber[type] = value;
                        this.CampChange(this.campsOderIds[s].z_camps_course_course_camp_summer_id,this.campsOderIds[s].z_camps_course_camps_select_id);
                        this.subcontent[j][i].course_camp_is_selected = true;
                    }
                }
            }
        }


        if(this.campsTypeData.length > 0){


            if(this.campBus.length > 0){

                for (let j = 0; j < this.campBus.length; j++) {

                    this.campBus[j].course_camp_bus_is_checked = "";

                    for (let i = 0; i < this.campsTypeData.length; i++) {

                        if(this.campsTypeData[i].z_camps_course_type_type == "bus" && (this.campsTypeData[i].z_camps_course_type_type_id == this.campBus[j].course_camp_bus_id)){


                            let busArray = { "bus_id":this.campBus[j].course_camp_bus_id,
                                             "price":this.campBus[j].course_camp_bus_price,
                                             "number":this.campsTypeData[i].z_camps_course_type_type_number};
            
                            this.campBusValue.push(busArray);

                            this.campBus[j]['course_camp_bus_is_checked'] = true;
                            this.campBus[j].course_camp_bus_number = this.campsTypeData[i].z_camps_course_type_type_number;
                        }
                    }
                }

            }
            console.log('campBusValue',this.campBusValue);
            
            if(this.campHotel.length > 0){
                this.campHotel.forEach((hotelData)=>{
                    hotelData['course_camp_hotel_selected']=false;
                    //console.log('campHotel',hotelData);
                    if(hotelData.hotel_type.length){
                        hotelData.hotel_type.forEach(typeData=>{
                            typeData['course_camp_hotel_is_checked'] = "";
                            typeData['course_camp_hotel_number'] = 0;
                            //console.log("I AM FOR =====");
                            if(this.campsTypeData.length){
                                this.campsTypeData.forEach(campsTypeData=>{

                                    //extra
                                    if(campsTypeData.z_camps_course_type_type == "extra"){
                                        if(campsTypeData.z_camps_course_type_type_id == typeData.course_camp_hotel_type_id){
                                            let hotelArray = { "hotel_type_id":typeData.course_camp_hotel_type_id,
                                                            "price":typeData.course_camp_hotel_type_price,
                                                            "number":campsTypeData.z_camps_course_type_type_number};

                                            this.campExtraHotelValue.push(hotelArray);
                                            typeData['course_camp_hotel_is_checked'] = "checked";

                                            //this.campHotel[j].course_camp_hotel_selected = true;
                                            //console.log('yes',campsTypeData.z_camps_course_type_type_id+"=="+ typeData.course_camp_hotel_type_id);
                                            
                                            typeData['course_camp_hotel_number'] = campsTypeData.z_camps_course_type_type_number;
                                            return true;
                                        }else{

                                            //typeData['course_camp_hotel_number'] = 0;

                                            //console.log('no',campsTypeData.z_camps_course_type_type_id+"=="+ typeData.course_camp_hotel_type_id);
                                        }
                                        
                                    }

                                    //room
                                    if(campsTypeData.z_camps_course_type_type == "room" ){


                                        if(campsTypeData.z_camps_course_type_type_id == typeData.course_camp_hotel_type_id){
                                            this.campRoomHotelValue.push({"hotel_type_room_id":typeData.course_camp_hotel_type_id,
                                                                    "price":typeData.course_camp_hotel_type_price,
                                                                    "night":this.camps_order_hotel_night_number,
                                                                    "number":this.camps_order_hotel_room_number});


                                            //this.campHotel[j].hotel_type[s].course_camp_hotel_is_checked = "checked";
                                            typeData['course_camp_hotel_is_checked'] = "checked";
                                            //console.log('yes');
                                           
                                            hotelData['course_camp_hotel_selected']=true;

                                            this.camps_order_hotel_id = hotelData.course_camp_hotel_id;
                                           
                                            typeData.course_camp_hotel_number = campsTypeData.z_camps_course_type_type_number;


                                            this.camps_show_check_info = true;
                                        }else{

                                            //typeData['course_camp_hotel_number'] = 0;


                                            //console.log('no');
                                        }
                                        
                                    }




                                })

                            }


                        })
                    }
                })
            }

            //console.log("campHotel2",this.campHotel);return;
            
            //console.log("campsTypeData",this.campsTypeData);
            /*
            for (let j = 0; j < this.campHotel.length; j++) {

                let campHotelArray = this.campHotel[j].hotel_type;
                for (let s = 0; s < campHotelArray.length; s++) {

                    for (let i = 0; i < this.campsTypeData.length; i++) {

                        //this.campHotel[j].course_camp_hotel_selected = false;

                        //if(this.campsTypeData[i].z_camps_course_type_type == "extra" && (this.campsTypeData[i].z_camps_course_type_type_id == campHotelArray[s].course_camp_hotel_type_id)){

                        if(this.campsTypeData[i].z_camps_course_type_type == "extra"){

                            if(this.campsTypeData[i].z_camps_course_type_type_id == campHotelArray[s].course_camp_hotel_type_id){
                                let hotelArray = { "hotel_type_id":campHotelArray[s].course_camp_hotel_type_id,
                                                "price":campHotelArray[s].course_camp_hotel_type_price,
                                                "number":this.campsTypeData[i].z_camps_course_type_type_number};
                                this.campExtraHotelValue.push(hotelArray);
                                this.campHotel[j].hotel_type[s].course_camp_hotel_is_checked = "checked";
                               
                                //this.campHotel[j].course_camp_hotel_selected = true;
                                console.log('yes');
                                
                                this.campHotel[j].hotel_type[s].course_camp_hotel_number = this.campsTypeData[i].z_camps_course_type_type_number;
                            }else{
                                //this.campHotel[j].course_camp_hotel_selected = false;
                                this.campHotel[j].hotel_type[s].course_camp_hotel_is_checked = "";
                                this.campHotel[j].hotel_type[s].course_camp_hotel_number = 0;

                                console.log('no');
                            }
                            
                            
                        }

                        //if(this.campsTypeData[i].z_camps_course_type_type == "room" && (this.campsTypeData[i].z_camps_course_type_type_id == campHotelArray[s].course_camp_hotel_type_id)){
                        console.log(this.campsTypeData[i].z_camps_course_type_type+"==="+this.campsTypeData[i].z_camps_course_type_type_id+"==="+campHotelArray[s].course_camp_hotel_type_id);


                        if(this.campsTypeData[i].z_camps_course_type_type == "room" ){


                            if(this.campsTypeData[i].z_camps_course_type_type_id == campHotelArray[s].course_camp_hotel_type_id){
                                this.campRoomHotelValue.push({"hotel_type_room_id":campHotelArray[s].course_camp_hotel_type_id,
                                                        "price":campHotelArray[s].course_camp_hotel_type_price,
                                                        "night":this.camps_order_hotel_night_number,
                                                        "number":this.camps_order_hotel_room_number});


                                //this.campHotel[j].hotel_type[s].course_camp_hotel_is_checked = "checked";
                                this.campHotel[j].hotel_type[s].course_camp_hotel_is_checked = "checked";
                                //console.log('yes');
                               
                                this.campHotel[j]['course_camp_hotel_selected'] = true;
                               
                                this.campHotel[j].hotel_type[s].course_camp_hotel_number = this.campsTypeData[i].z_camps_course_type_type_number;
                            }else{
                                this.campHotel[j].hotel_type[s].course_camp_hotel_is_checked = "";
                                //this.campHotel[j].course_camp_hotel_selected = false;
                                this.campHotel[j].hotel_type[s].course_camp_hotel_number = 0;


                                //console.log('no');
                            }
                            
                        }
                    }
                }
            }
            */

            //}
            console.log('camps_order_hotel_room_number',this.camps_order_hotel_room_number);
            console.log('player_first_name',this.player_first_name);

        }

        // console.log(this.campExtraHotelValue);
        // console.log(this.campRoomHotelValue);
        // console.log(this.campBusValue);
             

        this.CampCount();
        console.log("campHotel",this.campHotel);





    }

    campsOrder() {
        //this.getCampsData();
        // this.Interval = setInterval((val) => {
        //     this.getCampsData();
        // }, 10000)
        //1000
    }

    ngOnDestroy() {
        if (this.Interval) {
            clearInterval(this.Interval);
        }
    }

    openModal(template) {
        this.modalRef = this.modalService.show(template);
    }

    isSmartDevice() {
        var userAgentInfo = navigator.userAgent;
        var mobileAgents = ["Android", "iPhone", "iPad", "iPod", "Silk", "BlackBerry", "Opera Mini", "IEMobile"];
        var flag = false;
        //根据userAgent判断是否是手机
        for (var v = 0; v < mobileAgents.length; v++) {
            if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                flag = true;
                break;
            }
        }
        return flag;
    }


    campsPayment(status) {


        let is_mobile = this.isSmartDevice();

        const config = JSON.stringify({
            player_id: this.camps_order_player_id,
            order_id: this.camps_order_id,
            order_sn: this.camps_order_sn,
            status: status,
            is_mobile: is_mobile,
        });

        this.dataService.postBaseData('pay/camps_payment_before', config).then(resp => {

            if (resp != undefined) {
                if (resp.code === 200) {
                    if (resp.data.pay_url) {
                        this.camps_order_sn = resp.data.order_sn;
                        clearInterval();

                        if (status == 'alipay') {
                            if (!is_mobile) {
                                this.alipaylink = this.sanitizer.bypassSecurityTrustResourceUrl(resp.data.pay_url);
                                this.openModal(this.templateView);
                                return;
                            }
                        }
                        if (status == 'wechat') {
                            if (!is_mobile) {
                                this.alipaylink = this.sanitizer.bypassSecurityTrustResourceUrl(resp.data.pay_url);
                                this.openModal(this.template_wechat);
                                return;
                            }
                        }
                        window.open(resp.data.pay_url, '_self');
                    }
                }
            }
        })

    }

    //bus 多选框
    checkBus(event,bus_id,price){
        
        var busCheck = event.target;
        var busOne = busCheck.parentNode.parentNode.querySelector(".course_camp_checkbox_number");

        console.log("this.campBusValue",this.campBusValue);
        if(busCheck.checked){
            var busArray = { "bus_id":bus_id,"price":price,"number":1};
            
            this.campBusValue.push(busArray);
            busOne.value = 1;

        }else{

            this.campBusValue.forEach((val, key) => {

                if(val.bus_id == bus_id){
                  this.campBusValue.splice(key,1);
                }
             })
            busOne.value = 0;
        }

        this.CampCount();

    }

     //bus 填寫數值
    campBusPassenger(event,bus_id,price){

        var bus_passenger = event.target.value;
      
        var busOne = event.target.parentNode.parentNode.querySelector(".course_camp_checkbox");
        if(!busOne.checked){

            busOne.checked = true;
        }
        if(bus_passenger <= 0 || bus_passenger == ""){
            bus_passenger = 0;
            busOne.checked = false;
        }

        this.campBusValue.forEach((val, key) => {

            if(val.bus_id == bus_id){
              this.campBusValue[key].number = bus_passenger;
            }
        })
        this.CampCount();
    }


    checkExtraHotel(event,hotel_type_id,price,rowData){
        var hotelCheck = event.target;
        //console.log(rowData,event)
        rowData['course_camp_hotel_is_checked']=event.target.checked?'checked':'';
        var hotelOne = hotelCheck.parentNode.parentNode.querySelector(".course_camp_checkbox_hotel_number_"+hotel_type_id);
        // console.log(hotelOne);
        if(hotelCheck.checked){
            var hotelArray = { "hotel_type_id":hotel_type_id,"price":price,"number":1};

            this.campExtraHotelValue.push(hotelArray);
            hotelOne.value = 1;
        }else{
            this.campExtraHotelValue.forEach((val, key) => {

                if(val.hotel_type_id == hotel_type_id){
                  this.campExtraHotelValue.splice(key,1);
                }
             })   
            hotelOne.value = 0;

        }
        this.CampCount();
        console.log('checkExtraHotel',this.campExtraHotelValue)
    }

    changeHotelExtraday(event,hotel_type_id,price){

        var hotel_extraday = event.target.value;

        var hotelOne = event.target.parentNode.parentNode.querySelector(".course_camp_checkbox_hotel_id_"+hotel_type_id);
        if(!hotelOne.checked){

            hotelOne.checked = true;
        }
        if(hotel_extraday <= 0 || hotel_extraday == ""){
            hotel_extraday = 0;
            hotelOne.checked = false;
        }

        this.campExtraHotelValue.forEach((val, key) => {

            if(val.hotel_type_id == hotel_type_id){
              this.campExtraHotelValue[key].number = hotel_extraday;
            }
        })
        this.CampCount();


    }

    campHotelNumber(event,type){

        var HotelNumber = event.target.value;
        
        //add by alice
        if(HotelNumber<=0){
            event.target.value = 1;
            HotelNumber =1;
        }
        console.log('HotelNumber',HotelNumber);
        if(this.campRoomHotelValue.length > 0){

            if(type == "night"){
                this.campRoomHotelValue[0].night = HotelNumber;
            }else{
                this.campRoomHotelValue[0].number = HotelNumber;

            }
        }
        this.CampCount();
    }



    checkRoomHotel(hotel_type_id,price,event,type_index,hotel_id){
        //console.log('hotel_id',hotel_id,type_index);
        //console.log('sindex',sindex);

        //console.log('type_index',this.campHotel[type_index].hotel_type,this.campExtraHotelValue)


        /*
        this.campHotel[type_index].hotel_type.forEach((type,num)=>{
            //this.campExtraHotelValue.forEach((value,index)=>{
               //if(type.course_camp_hotel_type_id)
            //})
            for(let index =this.campExtraHotelValue.length;index>=0,index--){
                if(type.course_camp_hotel_type_id===this.campExtraHotelValue[index]['hotel_type_id']){
                    var hotelArray = { "hotel_type_id":hotel_type_id,"price":price,"number":1};

                    this.campExtraHotelValue.push(hotelArray);
                }else{
                    this.campExtraHotelValue.splice(key,1);
                }
            }
        })
        */

        var room_type = event.target.checked;

        var all_room  = event.target.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".course_camp_hotel_type_id");

        var old_hotel_id = this.camps_order_hotel_id;

        var new_hotel_id = hotel_id;



        
        //取消所有的room type checkbox
        all_room.forEach((obj, key) => {

            obj.checked = false;
           
        })

        event.target.checked = room_type;

        if(room_type){

            //this.campHotel[type_index].course_camp_hotel_selected = true;
            this.camps_show_check_info = true;
            //如果之前有选其他room type，则需要移除他们下的extra
            this.hideOtherExtra(type_index);

            this.camps_order_hotel_id= new_hotel_id;
            //重新计算 extra的值

            //this.recountExtraCount(type_index,1);
            //console.log('true:'+this.camps_show_check_info);
        }else{

            //this.campHotel[type_index].course_camp_hotel_selected = false;
            this.camps_show_check_info = false;
            this.clearCheckInfo();
            //移除所有的extra
            this.hideOtherExtra(-1);

            this.camps_order_hotel_id='';
            //清空 extra的值
            //this.recountExtraCount(type_index,0);
            //console.log('false:'+this.camps_show_check_info);
        }
        this.clearAllExtra(old_hotel_id,new_hotel_id);

        console.log('type_index',this.campExtraHotelValue)


        this.campRoomHotelValue = [];

        this.camps_order_hotel_night_number = Number(this.camps_order_hotel_night_number);
        this.camps_order_hotel_room_number = Number(this.camps_order_hotel_room_number);

        if(this.camps_order_hotel_night_number == 0  || this.camps_order_hotel_night_number == null){
            this.camps_order_hotel_night_number = 1;
        }

        if(this.camps_order_hotel_room_number == 0  || this.camps_order_hotel_room_number == null){
            this.camps_order_hotel_room_number = 1;
        }

        this.campRoomHotelValue.push({"hotel_type_room_id":hotel_type_id,"price":price,"night":this.camps_order_hotel_night_number,"number":this.camps_order_hotel_room_number});

        this.CampCount();
        
 
    }
    

    //清空check 信息
    clearCheckInfo(){
        this.camps_order_hotel_check_in_name = "";
        this.camps_order_check_in_date = "";
        this.camps_order_check_out_date = "";
        this.camps_order_hotel_night_number = 0;
        this.camps_order_hotel_room_number = 0;
        this.camps_order_hotel_room_special_request = "";
    }

    //隐藏其他的extra
    hideOtherExtra(sindex){
        console.log('hideother');
        if(this.campHotel.length > 0){
            this.campHotel.forEach((hotelData, key)=>{
                if(sindex == key && sindex>=0){
                    hotelData['course_camp_hotel_selected'] = true;
                }else{
                    hotelData['course_camp_hotel_selected'] = false;
                }
            })
        }
    }


    clearAllExtra(old_hotel_id,new_hotel_id){

        if(old_hotel_id !== new_hotel_id){
             
            if(this.campHotel.length){
                this.campHotel.forEach((hotelData,num)=>{

                    if(hotelData.hotel_type.length){
                        hotelData.hotel_type.forEach((typeData,num)=>{
                            typeData['course_camp_hotel_is_checked'] = "";
                            typeData['course_camp_hotel_number'] = 0;
                        })
                    }
                })
            }

            this.campExtraHotelValue = [];
        }
        


    }
    
    

    //重新计算 extra 的count 值
    /*
    recountExtraCount(type_index,type){
        console.log('recount');
        //this.campExtraHotelValue = [];

        if(type==0){
            //移除所有extra
            this.campExtraHotelValue = [];
            
        }else{

            if(this.campExtraHotelValue.length){

                this.campHotel[type_index].hotel_type.forEach((type,num)=>{

                    for(let index =this.campExtraHotelValue.length;index>=0;index--){
                        if(type.course_camp_hotel_type_id===this.campExtraHotelValue[index]['hotel_type_id']){

                            if(type.course_camp_hotel_is_checked=='checked'){
                                var hotelArray = { "hotel_type_id":hotel_type_id,"price":price,"number":1};

                                this.campExtraHotelValue.push(hotelArray);
                            }else{

                                this.campExtraHotelValue.splice(index,1);

                            }

                            
                        }else{
                            this.campExtraHotelValue.splice(index,1);
                        }
                    }

                })

            }

        }

       // console.log('recountExtraCount',this.campExtraHotelValue);

    }
    */


    onPhoneup(event) {
        let input = event.target;
        console.log(input.value);
        let value = input.value;
        let re = /^[1-9]\d*$/;
        if (!re.test(value)) {
            //return false;
             input.value = '';
        }

        //let value = input.value.replace(/^[1-9]\d*$/, '');
       
        //var arr = value.split('');
        //input.value = arr.join('');
        // 输完11位之后
        //let phone = event.target.value.replace(/\s+/g, '');
    }

    /*
    onPhonepress(event) {

        // 判断是否为数字
        let inputStr = String.fromCharCode(event.keyCode);
        console.log('inputStr',inputStr);
        let re = /[1-9]/; //判断字符串是否为数字
        if (!re.test(inputStr)) {
            return false;
        }
    }
    */




}
