import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
  selector: 'app-fitness',
  templateUrl: './off-ice.component.html',
  styleUrls: ['./off-ice.component.scss']
})
export class FitnessComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();

    }
    getData(){
        const config={
            "table":"development",
            // "select":"player_name",
            "where":{
                "development_type": 'fitness-training'
            },
            "return":"row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if(resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }
}
