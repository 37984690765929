import {Component, OnInit, OnDestroy} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

import {ActivatedRoute, Params, Router} from "@angular/router";

import {Subscription} from 'rxjs';
import {IMqttMessage, MqttService} from 'ngx-mqtt';


@Component({
    selector: 'app-city-league',
    templateUrl: './season-statistics.component.html',
    styleUrls: ['./season-statistics.component.scss']
})
export class SeasonStatisticsComponent implements OnInit, OnDestroy {

    loading: boolean = true;
    tab = 'results';
    gameId = '0';
    dataList: any;
    LeagueType: any;
    subscription: any;
    Id = '0';

    constructor(
        public dataService: DataService,
        private activatedRoute: ActivatedRoute,
        private _mqttService: MqttService,
        private router: Router) {
        this.Id = this.activatedRoute.snapshot.params['id'];

        this.activatedRoute.params.subscribe((params: Params) => {
            this.Id = params['id'];
            this.getData();
        });

    }

    ngOnInit() {

        this.getData();
        this.getLeagueType();
        // this.loading = false;

    }


    getLeagueType() {
        const config = {
            "table": "league_type",
            "page": "1",
            "perPage": "10000"
        };
        this.dataService.getCommonData(config).then(resp => {
            var cityLeagueData = [];
            if (resp.code === 200) {
                this.LeagueType = resp.data;
            }
        })


    }


    getData() {
        this.loading = true;
        let ids = this.Id.split("-");
        const config = {
            "league_id": ids[0],
            "selection_id": ids[1],
        };
        this.dataList = [];
        this.dataService.postBaseData('Game/player_selection_ranking', config).then(resp => {
            if (resp.code == 200) {
                this.dataList = resp.data;
                this.loading = false;
            }
        })
    }


    ngOnDestroy() {

    }
}
