import {Component, OnInit,OnDestroy} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

import {ActivatedRoute, Params, Router} from "@angular/router";

import {Subscription} from 'rxjs';

@Component({
    selector: 'app-city-league',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit,OnDestroy {

    loading: boolean = true;
    tab = 'results';
    gameId = '0';
    dataList: any = [];
    subscription: any;


    constructor(
            public dataService: DataService,
            private activatedRoute: ActivatedRoute,
            private router: Router) {
       this.gameId = this.activatedRoute.snapshot.params['id'];
        //另一种方式参数订阅
       this.activatedRoute.params.subscribe((params: Params) => this.gameId = params['id']);

    }

    ngOnInit() {
        this.loading = false;
    
    }


    ngOnDestroy(){

    }
}
