import {Component, OnInit, AfterViewInit, AfterContentInit} from '@angular/core';
import {NgwWowService} from 'ngx-wow';
import {ActivatedRoute, Params} from "@angular/router";
import {DataService} from "../../../@core/data/data.service";
import {GlobalsService} from "../../../@core/data/globals.service";

import {CarouselConfig} from 'ngx-bootstrap/carousel';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [
        {provide: CarouselConfig, useValue: {interval: 5000, noPause: true, showIndicators: true}}
    ]
})
export class HomeComponent implements OnInit, AfterViewInit, AfterContentInit {
    // private wowSubscription: Subscription;
    //
    loading: boolean = true;
    dataNewList: any;
    dataCityList: any;
    activeSlideIndex: any = 0;
    photoList: any = [];


    // thisSwiper: Swiper;
    slides = [];
    gameId = '';

    constructor(private wowService: NgwWowService, public dataService: DataService, private activatedRoute: ActivatedRoute, public globals: GlobalsService) {
    }

    ngOnInit() {
        this.wowService.init();
        // const _ = this;
        Promise.all([
                this.getNewData(),
                this.getPhotoData(),
                this.gethomeBannerData(),
                this.getCityLeagueOneData(),
            ]).then(
            (res) => {
                setTimeout(()=>{
                    this.loading = false;
                },2000)
            },  // 成功
            (err) => {
                this.loading = false;
            } // 失败
        )
    }


    ngAfterViewInit() {
    }

    ngAfterContentInit() {
    }

    getNewData() {
        let config = {
            'table': 'news',
            'where': {'news_type_like': 'news'},
            'perPage': '3',
            'order': 'news_id',
            'ascend': 'desc',
            'return': 'result_array',
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.dataNewList = resp.data;

            }
        })
    }


    getPhotoData() {
        let config = {
            'table': 'home_photo'
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                const json = [];
                resp.data.forEach(function (val) {
                    json.push({
                        imgUrl: val.home_photo_url
                    });
                });
                this.photoList = json
            }
        })
    }

    gethomeBannerData() {
        let config = {
            'table': 'banner_photo'
        };
        this.dataService.getCommonData(config).then(resp => {
            if (resp.code === 200) {
                this.slides = resp.data.sort(this.globals.sort("banner_photo_sort"));
            }
        })
    }

    getCityLeagueOneData() {
        let today = new Date();
        today.setTime(today.getTime());
        let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        let config = {
            'table': 'game',
            'where': {'game_starttime_lgt': date},
            'return': 'row_array',
        };
        this.dataService.postBaseData('game/get_one_game', config).then(resp => {
            if (resp.code === 200) {
                this.dataCityList = resp.data;
                this.gameId = resp.data.game_id;
            }
        })
    }

}
