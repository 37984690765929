import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
  selector: 'app-synthetic-ice',
  templateUrl: './special-training.component.html',
  
  styleUrls: ['./special-training.component.scss']
})
export class SemiprivateLessonComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();

    }
    getData(){
        const config={
            "table":"development",
            // "select":"player_name",
            "where":{
                "development_type": 'synthetic-ice-training'
            },
            "return":"row_array"
        };
        this.dataService.getCommonData(config).then(resp => {
            if(resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }
}
