import {Component, OnInit, AfterContentInit, ViewChild, ElementRef} from '@angular/core';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DataService} from "../../../@core/data/data.service";
import {HttpProvider} from "../../../@core/utils/http.provider";
import {FacebookService, LoginResponse} from 'ngx-facebook';
import {ToastrService} from 'ngx-toastr';
import {GlobalsService} from "../../../@core/data/globals.service";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterContentInit {
    phoneForm: FormGroup;
    passwordForm: FormGroup;
    player_logintype = 'email';
    emailForm: FormGroup;
    forgetpassword: boolean = false;
    subscript: any;
    pass: any;
    phoneAreaList = [];
    showbutton: boolean = true;
    Interval: any = '';
    loading: boolean = true;

    @ViewChild('googleButton', {static: true}) buttonView: ElementRef;


    constructor(private router: Router,
                public dataService: DataService,
                public httpProvider: HttpProvider,
                private fb: FacebookService,
                private activatedRoute: ActivatedRoute,
                private toastr: ToastrService,
                public globals: GlobalsService) {

        fb.init({
            appId: '590397975038586',
            version: 'v2.9'
        });

    }

    ngOnInit() {
        // man.wang@dreamover-studio.com
        this.phoneForm = new FormGroup({
            player_phone_area: new FormControl('852', [Validators.required]),
            player_loginname: new FormControl('', [Validators.required]),
            player_password: new FormControl('', [Validators.required]),
        });
        this.emailForm = new FormGroup({
            player_loginname: new FormControl('', [Validators.required]),
            player_password: new FormControl('', [Validators.required]),
        });
        this.loadScripts();
        this.getPhoneArea();
        // location.reload();
    }


    ngAfterContentInit() {
        this.loading = false;
    }


    /**
     * thisIsFunction
     * 登入账号
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    uploadData() {
        let config: any;
        if (this.player_logintype === 'phone') {
            config = {
                player_loginname: this.phoneForm.value.player_phone_area + this.phoneForm.value.player_loginname,
                player_password: this.phoneForm.value.player_password,
            };
        } else {
            config = this.emailForm.value;
        }
        config.player_logintype = this.player_logintype;
        this.dataService.postBaseData('login', config).then(resp => {
            if (resp != undefined) {
                if (resp.code == 200) {
                    localStorage.setItem('token', resp.token);
                    this.httpProvider.publishSessionId(resp.token);
                    this.router.navigate(['/home']);
                    this.globals.globalVar.next([{getInfo: true}]);
                }
            }
        });
    }

    /**
     * thisIsFunction
     * Facebook登入之调用 ngx-Facebook api ，拿到账号id 、token
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    loginFacebook() {
        this.fb.login()
            .then((res: LoginResponse) => {
                if (res.authResponse) {
                    this.fbLogin(res.authResponse)
                }
            })
            .catch((e: any) => console.error(e));
    }

    /**
     * thisIsFunction
     * Facebook登入账号之调用我们服务器接口
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    fbLogin(data) {
        const config = {
            player_facebook: data.userID,
            accessToken: data.accessToken
        };
        this.dataService.postBaseData('login/facebook', config).then(resp => {
            if (resp.code == 200 && resp.data) {
                //this.router.navigate(['/registration', resp.data.player_facebook])
                this.router.navigate(['/registration'], {
                    queryParams: {
                        'id': resp.data.player_facebook,
                        "type": "facebook"
                    }
                });
            } else {
                localStorage.setItem('token', resp.token);
                this.httpProvider.publishSessionId(resp.token);
                this.router.navigate(['/home']);
                this.globals.globalVar.next([{getInfo: true}]);
            }
        });
    }

    /**
     * thisIsFunction
     * Facebook登入之调用 ngx-Facebook api ，拿到账号id 、token
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-03-05
     */
    loginGoogle() {
        localStorage.removeItem('googleID');
        this.Interval = setInterval((val) => {
            this.loginGoogleInv()
        }, 3600)
    }


    ngOnDestroy() {
        localStorage.removeItem('googleReload');
        window.clearInterval(this.Interval);
    }

    loginGoogleInv() {
        let googleID = localStorage.getItem('googleID');
        if (googleID) {
            const config = {
                player_google: googleID,
            }
            this.dataService.postBaseData('login/google', config).then(resp => {

                if (resp.code == 200 && resp.data) {
                    // this.router.navigate(['/registration',resp.data.player_google])
                    this.router.navigate(['/registration'], {
                        queryParams: {
                            'id': resp.data.player_google,
                            "type": "google"
                        }
                    });
                } else {
                    localStorage.setItem('token', resp.token);
                    this.httpProvider.publishSessionId(resp.token);
                    this.router.navigate(['/home']);
                    this.globals.globalVar.next([{getInfo: true}]);
                }
                window.clearInterval(this.Interval);
            });

        }

    }

    private async loadScripts() {
        // Add youtube iframe script
        const doc = (<any>window).document;
        const playerApiScript = await doc.createElement('script');
        playerApiScript.type = 'text/javascript';
        playerApiScript.src = 'https://apis.google.com/js/platform.js';
        await doc.body.appendChild(playerApiScript);
    }

    /**
     * thisIsFunction
     * 获取手机号码区号列表
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    getPhoneArea() {
        const config = {
            setting_key: 'phone_area'
        };
        this.dataService.getBaseData('setting', config).then(resp => {
            this.phoneAreaList = resp.data;
        })

    }
}
