import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    loading: boolean = true;
    datalist: any = [];
    datalistTwo: any = [];

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.dataService.getBaseData('news').then(resp => {
            if (resp.code === 200) {
                if (resp.data.length > 0) {
                    let index = 0;
                    resp.data.forEach((val, key) => {
                        if (val.news_type && val.news_type !== 'game') {
                            const content = val.news_content.replace(/<[^<>]+>/g, '').replace(/&nbsp;/ig, '');
                            val.news_content = content;
                            if (index < 3) {
                                this.datalist.push(val);
                            } else {
                                this.datalistTwo.push(val);
                            }
                            index++
                        }
                    });
                }
                this.loading = false;
            }
        })
    }
}
