import { Component, OnInit } from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'app-photo-detail',
  templateUrl: './photo-detail.component.html',
  styleUrls: ['./photo-detail.component.scss']
})
export class PhotoDetailComponent implements OnInit {

    loading: boolean = true;
    datalist: any;
    photoId:any='';

    constructor(public dataService: DataService,private activatedRoute: ActivatedRoute,) {
        //获取参数值
        this.photoId = this.activatedRoute.snapshot.params['id'];
        //订阅路由参数，路由传递id改变之后执行
        this.activatedRoute.params.subscribe((params:Params)=>{this.photoId=params["id"];this.getData();});
    }

    ngOnInit() {
        this.getData()
    }

    getData(){
        const config={
            id:this.photoId
        };
        this.dataService.getBaseData('photo',config).then(resp => {
            if(resp.code === 200) {
                this.datalist = resp.data;
                this.loading = false;
            }
        })
    }
}
