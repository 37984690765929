import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";
import {HttpProvider} from "../../../@core/utils/http.provider";

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

    loading: boolean = true;
    datalist: any;

    constructor(public dataService: DataService) {
    }

    ngOnInit() {
        this.getData()
    }
    getData(){
        const config={
            about_type:'history'
        };
        this.dataService.getBaseData('about',config).then(resp => {
            if(resp.code === 200){
                this.datalist=resp.data;
                this.loading = false;
            }
        })

    }
}
