import {Component, OnInit,OnDestroy} from '@angular/core';
import {DataService} from "../../../@core/data/data.service";

import {ActivatedRoute, Params, Router} from "@angular/router";

import {Subscription} from 'rxjs';
import {IMqttMessage, MqttService} from 'ngx-mqtt';


@Component({
    selector: 'app-city-league',
    templateUrl: './league-detail.component.html',
    styleUrls: ['./league-detail.component.scss']
})





export class LeagueDetailComponent implements OnInit,OnDestroy {

    loading: boolean = true;
    tab = 'results';
    gameId = '0';
    dataList: any = [];
    subscription: any;

    constructor(
            public dataService: DataService,
            private activatedRoute: ActivatedRoute,
             private _mqttService: MqttService,
            private router: Router) {
       this.gameId = this.activatedRoute.snapshot.params['id'];
        //另一种方式参数订阅
       this.activatedRoute.params.subscribe((params: Params) => this.gameId = params['id']);

    }

    ngOnInit() {
        this.loading = false;
        this.getData();
    }

    getData() {

         this.loading = true;

        const config={

                game_id:this.gameId
                
            };
            this.dataService.getBaseData('Game/detail',config).then(resp => {
                if(resp.code === 200) {
                    this.dataList = resp.data;
                    this.mqttSubscription(resp.data.teamInfo.homeTeamInfo.team_id,resp.data.teamInfo.visitingTeamInfo.team_id,resp.data.gameInfo.game_id);  
                    this.loading = false;
                }

            })
    }

    mqttSubscription(homeTeamId,visitTeamId,gameId){

      let topic = homeTeamId+'-'+visitTeamId+'-'+gameId+'-app';


      // this._mqttService.connect(MQTT_SERVICE_OPTIONS);
      // this._mqttService.disconnect();

      this.subscription = this._mqttService.observe(topic).subscribe((message: IMqttMessage) => {
          let messageString = message.payload.toString();
          let massageData = JSON.parse(messageString);      

          this.dataList = massageData;

          return;

          //入球
          if(massageData.type == 'score'){

            if(this.dataList.teamInfo.homeTeamInfo.team_id == massageData.homeTeam.team_id && 
              this.dataList.teamInfo.visitingTeamInfo.team_id == massageData.visitingTeam.team_id && 
              this.dataList.gameInfo.game_id == massageData.gameId
              ){
               //result
               if(massageData.result.team == 'homeTeam'){
                 this.dataList.resultInfo[0][massageData.result.key] = massageData.result.value;
               }else {
                 this.dataList.resultInfo[1][massageData.result.key] =  massageData.result.value;
               }
               //Takeaways
               this.dataList.gameStats.forEach((valkeyStats,keyStats) => {

                 if(valkeyStats.name == 'Takeaways'){
                   this.dataList.gameStats[keyStats].data = massageData.Takeaways;
                   return false;

                 }
               })
                //总比分
               this.dataList.gameInfo.visitingTeamScore = massageData.visitingTeam.score;
               this.dataList.gameInfo.homeTeamScore = massageData.homeTeam.score;
               let tips = 0;
               let scrkeys = 0;
               //SCORING SUMMARY
               if(this.dataList.scoringInfo.length == 0){
                 let firstData = {"data":[],"quarter_name":massageData.scoring.scoring_quarter};
                 firstData.data.push(massageData.scoring);
                 this.dataList.scoringInfo[0] = firstData;
               } else {
                  this.dataList.scoringInfo.forEach((val,key) => {
                     if(val.quarter_name == massageData.scoring.scoring_quarter){
                       this.dataList.scoringInfo[key].data.push(massageData.scoring);
                       tips = key+1;
                       return false;
                     }
                     scrkeys = key;
                 })
                 //排序
                 if(tips != 0){
                     tips = tips-1;
                     this.sorting(this.dataList.scoringInfo[tips].data,'time',tips,massageData.homeTeam.team_id);
                  } else {
                     let arrayData = {"data":[],"quarter_name":massageData.scoring.scoring_quarter};
                     arrayData.data.push(massageData.scoring);
                     scrkeys = scrkeys+1;
                     this.dataList.scoringInfo[scrkeys] = arrayData;
                  }
               }
             }
          }else if(massageData.type == 'foul'){
             //Takeaways
             this.dataList.gameStats.forEach((valkeyStats,keyStats) => {
               if(valkeyStats.name == 'PIM'){
                 this.dataList.gameStats[keyStats].data = massageData.PIM;
                 return false;
               }
             })
             let scrkeys = 0;
             let tips = 0;
             if(this.dataList.foulInfo.length == 0){
                let firstData = {"data":[],"quarter_name":massageData.foul.foul_quarter};
                firstData.data.push(massageData.foul);
                this.dataList.foulInfo[0] = firstData;
             } else {
                this.dataList.foulInfo.forEach((val,key) => {
                   if(val.quarter_name == massageData.foul.foul_quarter){
                     this.dataList.foulInfo[key].data.push(massageData.foul);
                     tips = key+1;
                     return false;
                   }
                   scrkeys = key;
               })
                if(tips != 0){
                   tips = tips-1;
                   let returnData = this.sorting(this.dataList.foulInfo[tips].data,'foul_foul_time',0,0);  
                   this.dataList.foulInfo[tips].data = returnData;
                  } else {
                     let arrayData = {"data":[],"quarter_name":massageData.foul.foul_quarter};
                     arrayData.data.push(massageData.foul);
                     scrkeys = scrkeys+1;
                     this.dataList.foulInfo[scrkeys] = arrayData;
                  }
             }
          }else if(massageData.type == 'against'){
             //Takeaways
             this.dataList.gameStats.forEach((valkeyStats,keyStats) => {
               if(valkeyStats.name == 'Shot On Goal'){
                 this.dataList.gameStats[keyStats].data = massageData.ShotOnGoal;
                 return false;
               }
             })
             if(this.dataList.sogPerPeriod.length == 0){
               let arrayData = {"data":massageData.against,"quarter_name":massageData.quarter_name};
                 this.dataList.sogPerPeriod[0] = arrayData;
             } else {
               let is_existence = false;
               let tips = 0;
               this.dataList.sogPerPeriod.forEach((val,key) => {
                  tips = key;

                 if(val.quarter_name == massageData.quarter_name){
                   this.dataList.sogPerPeriod[key].data = massageData.against;
                   is_existence = true;
                 }
               })
               if(!is_existence){
                 let arrayData = {"data":massageData.against,"quarter_name":massageData.quarter_name};
                 tips = tips+1;
                 this.dataList.sogPerPeriod[tips] = arrayData;
               }
             }
          }else if(massageData.type == 'del_foul'){
              this.dataList.foulInfo.forEach((val,key) => {
                  val.data.forEach((vals,keys) => {
                      if(vals.foul_id == massageData.id){
                         this.dataList.foulInfo[key].data.splice(keys,1);
                      }
                  })
                  if(this.dataList.foulInfo[key].data.length == 0){
                     this.dataList.foulInfo.splice(key,1);
                  }
              })

              this.dataList.gameStats.forEach((valkeyStats,keyStats) => {
               if(valkeyStats.name == 'PIM'){
                 this.dataList.gameStats[keyStats].data = massageData.PIM;
                 return false;
               }
             })
          }else if(massageData.type == 'del_scoring' || massageData.type == 'del_shootout'|| massageData.type == 'del_penalty_shot'){
              let tips = 0;
              this.dataList.scoringInfo.forEach((val,key) => {
                  val.data.forEach((vals,keys) => {

                      if(vals.table == 'scoring'){
                          if(vals.scoring_id == massageData.id){
                           this.dataList.scoringInfo[key].data.splice(keys,1);
                        }
                      }else if(vals.table == 'shootout'){
                        if(vals.shootout_id == massageData.id){
                           this.dataList.scoringInfo[key].data.splice(keys,1);
                        }

                      }else if(vals.table == 'penalty_shot'){
                        if(vals.penalty_shot_id == massageData.id){
                           this.dataList.scoringInfo[key].data.splice(keys,1);
                        }

                      }
                     
                  })
                  if(this.dataList.scoringInfo[key].data.length == 0){
                     this.dataList.scoringInfo.splice(key,1);
                  }

                  if(val.quarter_name == massageData.quarter_name){
                    tips = key;
                  }
              })

              if(this.dataList.scoringInfo[tips]){

                this.sorting(this.dataList.scoringInfo[tips].data,'time',tips,massageData.homeTeam.team_id);

              }



              if(massageData.result.team == 'homeTeam'){
                 this.dataList.resultInfo[0][massageData.result.key] = massageData.result.value;
              }else {
                 this.dataList.resultInfo[1][massageData.result.key] =  massageData.result.value;
              }
              this.dataList.gameInfo.visitingTeamScore = massageData.visitingTeam.score;
              this.dataList.gameInfo.homeTeamScore = massageData.homeTeam.score;
          }else if(massageData.type == 'update_foul'){
            let tips = 0;
            this.dataList.foulInfo.forEach((val,key) => {
                val.data.forEach((vals,keys) => {
                    if(vals.foul_id == massageData.id){
                       this.dataList.foulInfo[key].data.splice(keys,1);
                       this.dataList.foulInfo[key].data.push(massageData.foul);
                       tips = key;
                       return false;

                    }
                })
            })

            this.dataList.gameStats.forEach((valkeyStats,keyStats) => {
               if(valkeyStats.name == 'PIM'){
                 this.dataList.gameStats[keyStats].data = massageData.PIM;
                 return false;
               }
             })

            let returnData = this.sorting(this.dataList.foulInfo[tips].data,'foul_foul_time',0,0);  
            this.dataList.foulInfo[tips].data = returnData;
          }else if(massageData.type == 'update_scoring' || massageData.type == 'update_shootout'|| massageData.type == 'update_penalty_shot'){

              let tips = 0;
              this.dataList.scoringInfo.forEach((val,key) => {
                  val.data.forEach((vals,keys) => {

                      if(vals.table == 'scoring'){
                          if(vals.scoring_id == massageData.id){
                           this.dataList.scoringInfo[key].data.splice(keys,1);
                           this.dataList.scoringInfo[key].data.push(massageData.scoring);

                        }
                      }else if(vals.table == 'shootout'){
                        if(vals.shootout_id == massageData.id){
                           this.dataList.scoringInfo[key].data.splice(keys,1);
                           this.dataList.scoringInfo[key].data.push(massageData.scoring);

                        }

                      }else if(vals.table == 'penalty_shot'){
                        if(vals.penalty_shot_id == massageData.id){
                           this.dataList.scoringInfo[key].data.splice(keys,1);
                           this.dataList.scoringInfo[key].data.push(massageData.scoring);

                        }

                      }
                     
                  })
                  if(this.dataList.scoringInfo[key].data.length == 0){
                     this.dataList.scoringInfo.splice(key,1);
                  }

                  if(val.quarter_name == massageData.quarter_name){
                    tips = key;
                  }
              })

              if(this.dataList.scoringInfo[tips]){

                this.sorting(this.dataList.scoringInfo[tips].data,'time',tips,massageData.homeTeam.team_id);
                
                
              }

          }

      });

    }

    sorting(data,val,tips,team_id){
      data.sort(function(a,b){return a[val].localeCompare(b[val])})

      if(team_id == 0){
        return data;
      }

    
      
      this.dataList.scoringInfo[tips].data = data;
                    
      let homeTeamScoringNum = 0;
      let visitingTeamScoringNum = 0;
      this.dataList.scoringInfo.forEach((vals,keys) => {

          vals.data.forEach((valdata,keydata)=>{
                if(valdata.team_id == team_id){
                  homeTeamScoringNum++;
                } else {
                  visitingTeamScoringNum++;
                }
                this.dataList.scoringInfo[keys].data[keydata].homeTeamScore = homeTeamScoringNum;
                this.dataList.scoringInfo[keys].data[keydata].visitingTeamScore = visitingTeamScoringNum;
          })
      })
    }


    ngOnDestroy(){

    }
}
