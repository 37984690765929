import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { TabsetConfig } from 'ngx-bootstrap';
import { HttpClient } from "@angular/common/http";
import { DataService } from "../../../@core/data/data.service";
import { Router } from "@angular/router";
import { HttpProvider } from "../../../@core/utils/http.provider";

@Component({
    selector: 'app-modifyLoginAccount',
    templateUrl: './modifyLoginAccount.component.html',
    styleUrls: ['./modifyLoginAccount.component.scss'],
    providers: [TabsetConfig]
})
export class ModifyLoginAccountComponent implements OnInit {
    player_logintype = 'phone';
    emailForm: FormGroup;
    phoneForm: FormGroup;

    constructor(private router: Router,
                public dataService: DataService,
                public httpProvider: HttpProvider) {
    }

    ngOnInit() {
        this.emailForm = new FormGroup({
            player_email: new FormControl('man.wang@dreamover-studio.com', [Validators.required]),
            code_verification_code: new FormControl('', [Validators.required]),
            player_password: new FormControl('', [Validators.required]),
            player_comfirmpassword: new FormControl('', [Validators.required]),
        });
        this.phoneForm = new FormGroup({
            player_loginname: new FormControl('man.wang@dreamover-studio.com', [Validators.required]),
            player_password: new FormControl('123456', [Validators.required]),
        });
    }

    /**
     * thisIsFunction
     * 登入账号
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2020-02-24
     */
    uploadData() {
        let config: any;
        if (this.player_logintype === 'phone') {
            config = this.phoneForm.value
        } else {
            config = this.emailForm.value
        }
        // this.dataService.postBaseData('login', config).then(resp => {
        //     if (resp.code == 200) {
        //         localStorage.setItem('token', resp.token);
        //         this.httpProvider.publishSessionId(resp.token);
        //         this.router.navigate(['/home'])
        //     }
        // });
    }

    /**
   * thisIsFunction
   * 发送短信code
   *
   * @author    man.wang@dreamover-studio.com
   * @return    array
   * @date      2019-08-09
   */
    postPhoneCode() {
      let config = {
          player_phone: this.phoneForm.value.player_phone,
          player_phone_area:this.phoneForm.value.player_phone_area,
          type: 'changePhone'
      };
      this.dataService.postBaseData('code/sentMail', config).then(resp => {
          // alert('成功');
      })
    }

    /**
     * thisIsFunction
     * 发送注册邮箱code
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-08-09
     */
    postMailCode(){
        let config={
            player_email:this.emailForm.value.player_email,
            type:'forgetPassword'
        };
        this.dataService.postBaseData('code/sentMail',config).then(resp => {
            // alert('成功');
        })
    }

    /**
       * thisIsFunction
       * 获取手机号码区号列表
       *
       * @author    man.wang@dreamover-studio.com
       * @return    array
       * @date      2019-08-09
       */
    getPhoneArea() {
        const config = {
            setting_key: 'phone_area'
        };
        this.dataService.getBaseData('setting', config).then(resp => {
        })

    }

}
